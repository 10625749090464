import { styled } from '@mui/material/styles'

const Wrapper = styled('div')(({ theme }) => ({
  borderRadius: '20px',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: '100%',
    display: 'flex',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderRadius: '0',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
}))

const ContentWrapper = styled('section')(({ theme }) => ({
  position: 'relative',
  width: '826.69px !important',
  height: '524.94px !important',
  backgroundColor: '#FFFFFF',
  border: '3px solid',
  borderImage:
    'linear-gradient(360deg, #FFE5EC 6.14%, rgba(251, 122, 154, 0.817053) 41.75%, #FFF2F6 100%) 1',
  [theme.breakpoints.down('md')]: {
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const InputWrapper = styled('section')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
  margin: '30px auto',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
    margin: '0',
    border: '3px solid',
    borderImage:
      'linear-gradient(360deg, #FFE5EC 6.14%, rgba(251, 122, 154, 0.817053) 41.75%, #FFF2F6 100%) 1',
    padding: '20px',
    height: '561.95px',
  },
}))

const FirstInput = styled('section')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '298px',
  height: '253.02px',
  borderRadius: '10px',
  backgroundImage:
    'linear-gradient(0.17deg, #FFE5EC -9.1%, rgba(251, 111, 146, 0.8) 45.45%, rgba(251, 122, 154, 0.817053) 52.15%, #FFF2F6 112.47%)',
  [theme.breakpoints.down('md')]: {
    order: 3,
    width: '100%',
  },
}))

const SecondInput = styled('section')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  width: '298px',
  height: '253.02px',
  borderRadius: '10px',
  border: '0.5px dashed rgba(190, 231, 248, 1)',
  position: 'relative',
  overflowY: 'auto',
  '& .text-editor': {
    minHeight: '',
    margin: '0',
    border: 'none',
    padding: '0',
  },
  [theme.breakpoints.down('md')]: {
    order: 1,
    width: '100%',
  },
}))
const TextArea = styled('textarea')(({ theme }) => ({
  width: '100%',
  height: '100%',
  border: 'none',
  resize: 'none',
  outline: 'none',
  overflowWrap: 'break-word',
  whiteSpace: 'pre-wrap',
  textAlign: 'left',

  backgroundColor: 'rgba(245, 252, 255, 1)',
  fontSize: '12px',
  lineHeight: '20px',
  borderRadius: '5px',
  padding: '10px',
  '&::placeholder': {
    textAlign: 'left',
    color: 'rgba(158, 158, 158, 1)',

    fontSize: '12px',
    backgroundColor: 'rgba(245, 252, 255, 1)',
    height: '100%',
    borderRadius: '5px',
  },
}))

export { ContentWrapper, Wrapper, InputWrapper, FirstInput, SecondInput, TextArea }
