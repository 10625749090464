import React, { useState } from 'react'
import { IconButton } from '@mui/material'
import iconsMessage from 'app/assets/images/icons_message.png'
import ChatPopUp from './ChatPopUp'

const ChatIconButton = () => {
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = () => {
    setIsOpen(true)
    // console.log('Chat opened')
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '90px',
        left: '8px',
        zIndex: 1000,
      }}
    >
      <IconButton onClick={handleClick} color="primary" sx={{ padding: 0 }}>
        {/* <ChatIcon fontSize="large" /> */}
        <img src={iconsMessage} alt="help" />
      </IconButton>
      {isOpen && <ChatPopUp open={isOpen} onClose={handleClose} />}
    </div>
  )
}

export default ChatIconButton
