import React from 'react'
import DashboardLayout from 'app/components/layouts/dashboard'
import { useTheme } from '@mui/material/styles'
import { Grid } from '@mui/material'
import { WrapperProfile } from 'app/components/dashboard/allProfile/styles/style'
import ProfilesNavbar from 'app/components/profile/ProfilesNavbar/ProfilesNavbar'
import CalendarStandard from 'app/components/profileSchedule/CalendarStandard'

const ProfileSchedule = () => {
  const theme = useTheme()
  const customStyles = {
    position: 'fixed',
    right: 0,
    top: '64px',
    width: 'calc(100% - 80px)',
    padding: '0',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      top: '75px',
    },
  }
  return (
    <>
      <DashboardLayout customStyles={customStyles}>
        <Grid container spacing={2}>
          <WrapperProfile height={'88vh'} width margin>
            <Grid xs={12}>
              <ProfilesNavbar holdVisible navVisible smVisible />
            </Grid>
            <CalendarStandard />
          </WrapperProfile>
        </Grid>
      </DashboardLayout>
    </>
  )
}

export default ProfileSchedule
