import { styled } from '@mui/material/styles'

const StyledForm = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '40%',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
}))

const InputMain = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.3rem',
  // width: '40%',
  width: '40%',
  position: 'relative',
  '& textarea': {
    width: '100%',
    border: '1px solid #DDDDDD',
    borderRadius: '8px',
    resize: 'none',
    padding: '8px',
    height: '68px',
    outline: 'none',
  },
  '&.has__error': {
    input: {
      border: '1px solid #ff4d4f',
    },
  },
  [theme.breakpoints.down('md')]: {
    width: '90%',
  },

  '& label': {
    color: '#49759C',
    fontSize: '14px',
    fontWeight: 500,

    span: {
      color: '#DF0000',
    },
  },
  '& input': {
    // maxWidth: '238px',
    maxWidth: '238px',
    width: '100%',
    height: '34px',
    borderRadius: '4.8px',
    padding: '0 13px',
    border: '1px solid rgba(206, 212, 218, 1)',
    marginBottom: '0',

    '&::placeholder': {
      color: '#6C757D80',
      fontStyle: 'italic',
      fontSize: '12px',
      fontWeight: 300,

      whiteSpace: 'pre-wrap',
      overflowWrap: 'break-word',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  '& input[type="date"]': {
    textTransform: 'uppercase',
  },
  '& .error': {
    color: '#ff4d4f',
    fontWeight: 400,
    fontSize: '12px',
    position: 'absolute',
    top: '100%',
    left: 0,
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

const MainRadio = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  '& label': {
    color: '#49759C',
    fontSize: '12px',
    fontWeight: 500,
  },
}))

const InputWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '1rem',
  '& label': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    fontSize: '12px',
    fontWeight: 400,
    color: '#212529',
  },
  '& input': {
    width: '16px',
    height: '16px',
  },
  '& input[type="radio"]:checked': {},
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
}))
export { InputMain, MainRadio, InputWrapper, StyledForm }
