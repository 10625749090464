// sidebar.js
import React, { useState } from 'react'
import { Box, Drawer } from '@mui/material'
import MenuIcon from 'app/assets/images/NavIcons/moreMenu.svg'
import logo from 'app/assets/images/logo/logoMini.svg'
import { SidebarWrapperMobile } from './styles'
import { Link, useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'app/store/hook'
import { clearStore } from 'app/store/slices/auth/login'

const Sidebar = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(false)

  const handleToggle = () => {
    setOpen(!open)
  }

  const handleLogout = () => {
    localStorage.clear()
    dispatch(clearStore())
    navigate('/login')
  }

  return (
    <SidebarWrapperMobile>
      {/* <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleToggle}
        sx={{ p: 0 }}
      > */}
      <img src={MenuIcon} alt="MenuIcon" onClick={handleToggle} />
      {/* </IconButton> */}

      <Drawer
        variant="temporary"
        open={open}
        onClose={handleToggle}
        sx={{
          '& .MuiDrawer-paper': {
            width: '70vw',
            background: '#F1FAFE',
            backgroundColor: '#F1FAFE',
            height: '100%',
            padding: '10px 20px',
          },
        }}
      >
        <Box>
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '13px',

              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '12.25px',
              textAlign: 'left',
              a: {
                color: '#9E9E9E',
              },
              button: {
                color: '#9E9E9E',
                backgroundColor: 'transparent',
                width: 'fit-content',

                fontSize: '14px',
                fontWeight: 700,
              },
            }}
          >
            <Link to="/">Home</Link>
            <Link to="/profiles">Profiles</Link>
            <Link to="/matches">Matches</Link>
            <Link to="/agencies">Agencies</Link>
            <Link to="/leads">Leads</Link>
            <button onClick={handleLogout}>Logout</button>
          </Box>
        </Box>
      </Drawer>
    </SidebarWrapperMobile>
  )
}

export default Sidebar
