import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Breadcrumbs as MUIBreadcrumbs } from '@mui/material'
import { styled } from '@mui/material/styles'

const CustomLink = styled('div')(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '17.15px',
  color: '#49759C',
  '&.add__weight': {
    fontWeight: '700',
  },
}))
const StyledBreadcrumbs = styled(MUIBreadcrumbs)({
  '& .MuiBreadcrumbs-separator': {
    color: '#49759C',
    fontSize: '14px',
    fontWeight: '400',
    marginTop: '5px',
  },
})

const Breadcrumbs = () => {
  const location = useLocation()
  const pathnames = location.pathname.split('/').filter((x) => x)

  if (pathnames.length === 0) {
    return null
  }

  const firstSegment = pathnames[0]

  return (
    <StyledBreadcrumbs aria-label="breadcrumb" separator=">">
      <CustomLink to="/">Home</CustomLink>
      <CustomLink className="add__weight">
        {firstSegment.charAt(0).toUpperCase() + firstSegment.slice(1)}
      </CustomLink>
    </StyledBreadcrumbs>
  )
}

export default Breadcrumbs
