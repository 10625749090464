import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { styled } from '@mui/material/styles'
import { useParams } from 'react-router-dom'

const CustomTextAreaContainer = styled('div')(({ theme }) => ({
  width: '100%',
  minHeight: '250px',
  marginTop: '0.5rem',
  outline: 'none',
  resize: 'none',
  scrollbarWidth: 'none',
  borderRadius: '10px',
  padding: '8px 13px',
  overflowY: 'auto',
  border: '1px solid rgba(206, 212, 218, 1)',
  fontSize: '12px',
  fontWeight: '400',
  color: 'rgba(0, 0, 0, 1)',
  '::placeholder': {
    color: '#9E9E9E80',
    fontStyle: 'italic',
    fontSize: '12px',
    fontWeight: '300',
    lineHeight: '18px',
  },
  '& .ql-container': {
    border: 'none !important',
  },
  '& .ql-editor': {
    minHeight: '200px',
    fontSize: '12px',
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 1)',
  },
}))

const toolbarOptions = [
  [{ font: [] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic', 'underline', 'strike'],
  [{ color: [] }, { background: [] }],
  [{ script: 'sub' }, { script: 'super' }],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }],
  [{ direction: 'rtl' }],
  [{ align: [] }],
  ['link', 'image', 'video'],
  ['clean'],
]

const TextEditor = ({ value, onChange, disabled = false, placeholder, ...props }) => {
  const { id } = useParams()
  const [editorValue, setEditorValue] = useState(value || '')

  const handleChange = (content) => {
    setEditorValue(content)
    onChange(content)
  }
  useEffect(() => {
    if (value !== undefined && value !== null) {
      // setEditorValue(value?.replace(/\\n/g, '<br/>'))
      setEditorValue(value)
    }
  }, [id, value])
  return (
    <CustomTextAreaContainer className="text-editor">
      <ReactQuill
        value={editorValue}
        onChange={handleChange}
        placeholder={placeholder}
        theme="snow"
        readOnly={disabled}
        modules={{ toolbar: false }}
        {...props}
      />
    </CustomTextAreaContainer>
  )
}

export default TextEditor
