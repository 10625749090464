/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Loading from 'app/components/shared/Loading'
import { PublicRoute, ProtectedRoute } from './ProtectedRoute'
import ProfileSchedule from 'app/containers/dashboard/profileSchedule'

// pages
const Login = lazy(() => import('app/containers/auth/Login'))
const Register = lazy(() => import('app/containers/auth/Register'))
const Otp = lazy(() => import('app/containers/auth/Otp'))
const HomeNew = lazy(() => import('app/containers/dashboard/home/HomeNew'))
const Profiles = lazy(() => import('app/containers/dashboard/allProfiles'))
const OnBoarding = lazy(() => import('app/containers/dashboard/onboarding'))
const ContactSuggest = lazy(() => import('app/containers/dashboard/contactSuggest'))
const Agencies = lazy(() => import('app/containers/dashboard/agencies'))
const MatchsProfiles = lazy(() => import('app/containers/dashboard/matchProfiles'))
const OnbMatchMaker = lazy(() => import('app/containers/dashboard/onbMatchmaker'))
const Matchmaker = lazy(() => import('app/containers/dashboard/matchmaker'))
const Comapare = lazy(() => import('app/containers/dashboard/comapare'))
const EditProfile = lazy(() => import('app/containers/dashboard/onboarding/EditProfile'))
const Profile = lazy(() => import('app/containers/dashboard/profile'))
const Leads = lazy(() => import('app/containers/dashboard/leads'))
const ProfileTimeline = lazy(() => import('app/containers/dashboard/profileTimeline'))

const index = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/login"
            element={
              <Suspense fallback={<Loading />}>
                <PublicRoute>
                  <Login />
                </PublicRoute>
              </Suspense>
            }
          />
          <Route
            path="/register"
            element={
              <Suspense fallback={<Loading />}>
                <PublicRoute>
                  <Register />
                </PublicRoute>
              </Suspense>
            }
          />
          <Route
            path="/register/otp"
            element={
              <Suspense fallback={<Loading />}>
                <PublicRoute>
                  <Otp />
                </PublicRoute>
              </Suspense>
            }
          />
          <Route
            path="/login/otp"
            element={
              <Suspense fallback={<Loading />}>
                <PublicRoute>
                  <Otp />
                </PublicRoute>
              </Suspense>
            }
          />
          <Route
            path="/"
            element={
              <Suspense fallback={<Loading />}>
                <ProtectedRoute>
                  <HomeNew />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/profiles"
            element={
              <Suspense fallback={<Loading />}>
                <ProtectedRoute>
                  <Profiles />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/profile/:id"
            element={
              <Suspense fallback={<Loading />}>
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/profile-timeline"
            element={
              <Suspense fallback={<Loading />}>
                <ProtectedRoute>
                  <ProfileTimeline />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/profile-schedule/:id"
            element={
              <Suspense fallback={<Loading />}>
                <ProtectedRoute>
                  <ProfileSchedule />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/onboarding"
            element={
              <Suspense fallback={<Loading />}>
                <ProtectedRoute>
                  <OnBoarding />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/edit-profile/:id"
            element={
              <Suspense fallback={<Loading />}>
                <ProtectedRoute>
                  <EditProfile />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/compare/:matchId/:profileId"
            element={
              <Suspense fallback={<Loading />}>
                <ProtectedRoute>
                  <Comapare />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/matches"
            element={
              <Suspense fallback={<Loading />}>
                <ProtectedRoute>
                  <ContactSuggest />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/agencies"
            element={
              <Suspense fallback={<Loading />}>
                <ProtectedRoute>
                  <Agencies />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/match-profiles/:id"
            element={
              <Suspense fallback={<Loading />}>
                <ProtectedRoute>
                  <MatchsProfiles />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/onboarding-matchmaker/:commingFrom?"
            element={
              <Suspense fallback={<Loading />}>
                <ProtectedRoute>
                  <OnbMatchMaker />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/matchmaker/:id/:followUP-id?"
            element={
              <Suspense fallback={<Loading />}>
                <ProtectedRoute>
                  <Matchmaker />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/leads/:comming-from?"
            element={
              <Suspense fallback={<Loading />}>
                <ProtectedRoute>
                  <Leads />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="*"
            element={
              <>
                <h1>Page Not Found!</h1>
              </>
            }
          />
        </Routes>
      </Router>
    </>
  )
}

export default index
