import { Avatar, Dialog } from '@mui/material'
import { styled } from '@mui/material/styles'

const SelectMenu = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '10px',
  width: '100%',
  justifyContent: 'flex-start',
  marginBottom: '20px',
  [theme.breakpoints.down('md')]: {
    marginBottom: '10px',
  },
}))

const TasksContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  width: '100%',
  height: 'calc(100vh - 210px)',
  overflowY: 'auto',
  overflowX: 'hidden',
  [theme.breakpoints.down('md')]: {
    height: 'calc(63vh - 100px)',
  },
}))

const CardTask = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  // height: '54px',
  padding: '8px',
  marginBottom: '5px',
  borderRadius: '8px',
  backgroundColor: '#FFFAF1',
  '&.task_expire': {
    backgroundColor: 'rgb(255, 225, 225)',
    '&:hover': {
      backgroundColor: 'rgb(255, 225, 225)',
    },
  },
  '&:hover': {
    backgroundColor: '#FFFAF7',
  },
}))

const UserMenu = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  width: '95%',
  // height: '90%',
  gap: '5px',
  '& .notifications': {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '14.7px',
    textAlign: 'left',
    color: '#1A1A1A',
    overflow: 'hidden',
    // height: '90%',
    paddingRight: '5px',
    wordBreak: 'break-word',
  },
  '& .line_through': {
    textDecoration: 'line-through',
    textDecorationColor: 'green',
    textDecorationThickness: '2px',
  },
  '& .red_line': {
    textDecoration: 'line-through',
    textDecorationColor: 'red',
    textDecorationThickness: '2px',
  },
}))

const AvatarSection = styled('div')(({ theme }) => ({
  minWidth: '40px',
  height: '100%',
  gap: '5px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& p': {
    fontSize: '10px',
    fontWeight: '300',
    lineHeight: '12px',
    textAlign: 'center',
    color: '#5D5D5D',
  },
  '& > .due__div': {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const CustomAvatar = styled(Avatar)(({ theme }) => ({
  width: '24px',
  height: '24px',
  fontSize: '10px',
  color: '#49759C',
  fontWeight: 500,
}))
const TastMain = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  p: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    color: '#49759C',
    marginBottom: '15px',
    padding: '0',
  },
  button: {
    border: '1px solid #87CEEB',

    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.10000000149011612px',
    textAlign: 'center',
    color: '#87CEEB',
    borderRadius: '5px',
    backgroundColor: '#fff',
    padding: '3px 5px',
    '&:hover': {
      backgroundColor: '#fff',
    },
    '& span': {
      marginRight: '4px',
    },
  },
}))

const CustomPaper = styled(Dialog)(({ theme }) => ({
  '&.show_event_popup .MuiDialog-paper': {
    backgroundColor: '#FFF2F5',
  },
  '& .MuiDialog-paper': {
    borderRadius: '16px',
    padding: '20px',
    width: '100%',
    // height: '100%',
    // maxHeight: '320px',
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'visible',
    [theme.breakpoints.down('md')]: {
      // maxHeight: '500px',
    },
  },
  '& .title__bar': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    '& h2': {
      fontSize: '20px',
      fontWeight: '400',
      lineHeight: '20px',
      textAlign: 'left',
      color: '#49759C',
    },
    '& img': {
      cursor: 'pointer',
    },
    '& :after': {
      content: '""',
      position: 'absolute',
      width: 'calc(100% + 40px)',
      height: '1px',
      backgroundColor: '#DDDDDD',
      bottom: '-10px',
      left: '-20px',
    },
  },
}))

export {
  SelectMenu,
  TasksContent,
  CardTask,
  UserMenu,
  AvatarSection,
  CustomAvatar,
  TastMain,
  CustomPaper,
}
