import React from 'react'

import { DebounceInput } from 'react-debounce-input'
import { StyledInputBase } from './styles'

const DebouncedSearchInput = ({ onSearch }) => {
  return (
    <DebounceInput
      minLength={3}
      element={StyledInputBase}
      debounceTimeout={300}
      placeholder={'Search by user profile'}
      inputProps={{ 'aria-label': 'search' }}
      onChange={(e) => onSearch(e.target.value)}
    />
  )
}

export default DebouncedSearchInput
