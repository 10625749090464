import { useQuery } from 'react-query'
import httpRequest from 'app/axios/index'

const fetch = async (startDate, endDate) => {
  let URL = `/profileSchedule?startDate=${startDate}&endDate=${endDate}`
  const { data } = await httpRequest.get(URL)
  return data
}

const useGetAllEvents = (startDate, endDate) => {
  return useQuery(
    ['all-profile-schedule-events::', startDate, endDate],
    () => fetch(startDate, endDate),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: false,
      enabled: !!startDate && !!endDate,
    },
  )
}

export default useGetAllEvents
