import React from 'react'
import { Navigate } from 'react-router-dom'

export const PublicRoute = ({ children }) => {
  const isAuthenticated = JSON.parse(localStorage.getItem('authToken'))
  return !isAuthenticated ? children : <Navigate to={'/'} replace={true} />
}

export const ProtectedRoute = ({ children }) => {
  const isAuthenticated = JSON.parse(localStorage.getItem('authToken'))
  return isAuthenticated ? children : <Navigate to={'/login'} replace={true} />
}
