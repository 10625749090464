import { styled } from '@mui/material'

const EventContainer = styled('div')(({ theme }) => ({
  '& .main_select, .datePicker__fix': {
    marginBottom: '15px',
  },
  '& .onboard_select__control': {
    minHeight: '40px',
  },

  '& .datePicker__fix input , .Input_Main input': {
    height: '40px',
  },

  '& .show_event_container .show_item_main': {
    marginBottom: '15px',
  },
  '& .show_event_container .show_item_main h3': {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
    textAlign: 'left',
    color: '#5D5D5D',
    marginBottom: '2px',
  },
  '& .show_event_container .show_item_main p': {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
    textAlign: 'left',
    color: '#1A1A1A',
  },
}))

export { EventContainer }
