import { useRef, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
// @mui
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton } from '@mui/material'
// components
import MenuPopover from './MenuPopover'
// mocks_
import account from './accounts'
import { useAppDispatch, useAppSelector } from 'app/store/hook'
import { clearStore } from 'app/store/slices/auth/login'

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
    linkTo: '/',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    linkTo: '#',
  },
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
    linkTo: '#',
  },
]

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const userInfo = useAppSelector((state) => state.auth.userInfo)
  const anchorRef = useRef(null)

  const [open, setOpen] = useState(null)

  const handleOpen = (event) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }
  const handleLouout = () => {
    setOpen(null)
    dispatch(clearStore())
    localStorage.clear()
    navigate('/login')
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => (theme.palette.background.default, 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt={account.displayName} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5, color: '#9E9E9E' }}>
          <Typography variant="subtitle2" noWrap>
            {userInfo?.matchmakerName}
          </Typography>
          {/* <Typography variant="body2" sx={{ color: '#9E9E9E' }} noWrap>
            {account.email}
          </Typography> */}
        </Box>

        <Divider />

        <Stack sx={{ p: 1, color: '#9E9E9E' }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider />

        <MenuItem onClick={handleLouout} sx={{ m: 1, color: '#9E9E9E' }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  )
}
