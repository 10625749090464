import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  filter: {},
  search: null,
}

const profilesSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setMatchFilter: (state, action) => {
      state.filter = action.payload
    },
    setSearchValue: (state, action) => {
      state.search = action.payload
    },
    resetSearchValue: (state) => {
      state.search = null
    },
    resetMatchFilter: (state) => {
      state.filter = {}
    },
  },
})

export const { setMatchFilter, resetMatchFilter, resetSearchValue, setSearchValue } =
  profilesSlice.actions

export default profilesSlice.reducer
