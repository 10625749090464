function formatDate(dateString) {
  const date = new Date(dateString)

  const day = date.getUTCDate()
  const month = date.toLocaleString('en-US', { month: 'short' })
  const year = date.getUTCFullYear()

  // Function to determine the correct suffix for the day
  function getDaySuffix(day) {
    if (day > 3 && day < 21) return 'th'
    switch (day % 10) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
    }
  }

  return `${day}${getDaySuffix(day)} ${month} ${year}`
}

export default formatDate