import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  Button,
} from '@mui/material'
import { styled } from '@mui/material/styles'

const CustomContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: '0 0 20px 20px',
  width: '100%',
  overflowX: 'hidden',
}))

const TableBodyCustom = styled(TableBody)(({ theme }) => ({
  '&  td:not(:first-child) ': {
    background: 'transparent',
  },
  overflow: 'auto',
}))

const CustomTableComponent = styled(Table)(({ theme }) => ({
  '& th:not(:first-child)': {
    border: 'none',
  },
  '& .brackets': {
    fontSize: '10px',
    fontWeight: '400',
    lineHeight: '12px',
    textAlign: 'left',
    color: '#1A1A1A',
    [theme.breakpoints.down('md')]: {
      fontSize: '10px',
    },
  },
  '& .red': {
    color: '#FC9CB4',
  },
}))

const TableHeadCustom = styled(TableHead)(({ theme }) => ({
  borderRight: 'none !important',
  textAlign: 'left',
  textWrap: 'nowrap',
}))

const CustomCellHead = styled(TableCell)(({ theme }) => ({
  borderBottom: '1px solid #DDDDDD !important',
  backgroundColor: '#F5F5F5',
  padding: '10px 10px',

  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '19.6px',
  textAlign: 'left',
  color: '#1A1A1A',
  [theme.breakpoints.down('md')]: {
    padding: '5px 5px',
    fontSize: '12px',
  },
}))

const TableCellCustom = styled(TableCell)(({ theme }) => ({
  backgroundColor: 'transparent !important',
  paddingTop: '5px',
  paddingBottom: '5px',
}))
const TableCellCustomCards = styled(TableCell)(({ theme, padding }) => ({
  backgroundColor: 'transparent !important',
  overflowX: 'auto',
  overflowY: 'hidden',
  display: 'flex',
  padding: padding ? padding : '16px',
  // width: 'auto',  // This is causing the issue with the table width being too wide and not fitting the screen width
  scrollbarWidth: 'none',
  // height: '100px',
  flex: '1',
}))

const UserNameMain = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  a: {
    fontSize: '12px',
    fontWeight: 450,
    lineHeight: '14px',
    textAlign: 'left',
    color: '#87CEEB',
    border: '1px solid #87CEEB',
    padding: '3px',
    borderRadius: '4px',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))

const UserName = styled('div')(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 'bold',
  lineHeight: '19.6px',
  textAlign: 'left',
  color: '#1A1A1A',
  '&.is_profile_name': {
    a: {
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '19.6px',
      textAlign: 'left',
      color: '#1A1A1A',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      padding: 0,
      border: 'none',
    },
  },

  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  p: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14.7px',
    textAlign: 'left',
    color: '#2DAF32',
    marginTop: '2px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
  },
}))

const AgencyName = styled('p')(({ theme }) => {
  const colors = ['#1A1A1A', '#2DAF32', '#49759C', '#FF8067', '#E82424']

  const randomColor = colors[Math.floor(Math.random() * colors.length)] // Pick a random color

  return {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '14.7px',
    textAlign: 'left',
    width: 'fit-content',
    cursor: 'pointer',
    color: randomColor,
    textWrap: 'nowrap',
    a: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '14.7px',
      textAlign: 'left',
      width: 'fit-content',
      cursor: 'pointer',
      color: randomColor,
      textWrap: 'nowrap',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '10px',
    },
  }
})

const CardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  // width: '100%',
  // height: '100%',
  // padding: '3px',
  // gap: '5px',
  alignItems: 'center',
  '& .whatsapp_icon': { cursor: 'pointer' },
}))

const CardWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '100%',
  borderRadius: '8px',
  // padding: '2px',
  backgroundColor: '#F9F9F9',
  boxShadow: 'none',
  // gap: '7px',
  '&.active_item': {
    backgroundColor: 'rgb(180 226 255)',
  },
  '&.confirms, &.accepted': {
    backgroundColor: 'rgba(0, 186, 52, 0.1)',
  },
  '&.denies, &.rejected': {
    backgroundColor: 'rgba(255, 59, 59, 0.1)',
  },
  '&.deActive': {
    backgroundColor: '#FFD3D3',
  },
  '&.CLOSED': {
    backgroundColor: 'rgb(215 215 215)',
  },
  '& img': {
    cursor: 'pointer',
  },
}))

const CardCompWrapper = styled('div')(({ theme, days }) => ({
  display: 'flex',
  flexDirection: 'column',
  textWrap: 'nowrap',
  gap: '5px',
  padding: '5px',
  justifyContent: 'space-between',
  minWidth: '120px',
  // backgroundColor: days === '' ? '#F9F9F9' : days > 4 ? '#ECF7FE' : days < 5 ? '#FFD6D6' : '',
}))

const NavCard = styled('div')(({ theme, days }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& .Count': {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '17.1px',
    textAlign: 'left',
    color: '#1A1A1A',
  },
  '& .days': {
    fontSize: '10px',
    fontWeight: '400',
    lineHeight: '12.25px',
    textAlign: 'right',
    color: days < 2 ? '#1A1A1A' : '#1A1A1A',
  },

  '& .isNegativeAndActive': {
    fontSize: '10px',
    fontWeight: '400',
    lineHeight: '12.25px',
    textAlign: 'right',
    color: '#1A1A1A',
  },
}))

const Details = styled('p')(({ theme }) => ({
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '14.7px',
  textAlign: 'left',
  color: '#1A1A1A',
  textDecoration: 'underline',
  cursor: 'pointer',
}))
const CustomPaper = styled(Dialog)(({ theme, autoFit, maxHeight, isWhatsapp }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '16px',
    padding: '20px',
    width: '100%',
    maxWidth: autoFit ? 'auto' : '860px',
    height: 'fit-content',
    maxHeight: maxHeight ? '600px' : '',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      padding: '15px',
      overflowY: 'auto',
      overflowX: 'hidden',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '-ms-overflow-style': 'none',
      scrollbarWidth: 'none',
    },
  },
  '& .header_main': {
    display: 'flex',
    alignItems: 'center',
    marginTop: '15px',
    gap: '10px',
    '& .image_group': {
      a: {
        color: '#49759C',
      },
    },
  },
  '& .profile__title__whatsapp': {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'left',
    color: '#49759C',
    a: {
      color: '#49759C',
    },
  },
  '& .title__bar': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    '& h2': {
      fontSize: '20px',
      fontWeight: '400',
      lineHeight: '20px',
      textAlign: 'left',
      color: '#49759C',
    },
    '& img': {
      cursor: 'pointer',
    },
    '& :after': {
      content: '""',
      position: 'absolute',
      width: 'calc(100% + 40px)',
      height: '1px',
      backgroundColor: '#DDDDDD',
      bottom: '-10px',
      left: '-20px',
    },
  },
  '& .adjust__height': {
    maxHeight: 'fit-content !important',
    '& .text-editor': {
      padding: '0 8px !important',
      margin: '0 !important',
      border: 'none !important',
      height: '315px !important',
    },
  },
  '& .content': {
    maxHeight: '300px',
    height: isWhatsapp ? '100%' : '',
    overflowY: isWhatsapp ? 'hidden' : 'auto',
    marginBottom: '15px',
    '& .text-editor': {
      overflowY: isWhatsapp ? 'auto' : '',
      height: '290px',
      '& h2': {
        fontSize: '1rem',
      },
      '& .ql-editor': {
        padding: '0',
      },
    },

    '& h3': {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '21px',
      textAlign: 'left',
      color: '#1A1A1A',
    },
    '& .profile__info__wrapper': {
      display: 'flex',
      justifyContent: 'space-between',
      height: '327px',
      maxWidth: '812px',
      width: '100%',
      border: '1px solid #DDDDDD',
      borderRadius: '8px',
      padding: '5px 10px',
      '& .border': {
        borderRight: '1px solid #DDDDDD',
        height: '93%',
        margin: '20px 0 auto 0',
      },

      '& .profile__info__section': {
        display: 'flex',
        flexDirection: 'column',
        width: '49%',
        height: '100%',

        '& .profile__info__section__title': {
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 5px',
          '& .MuiFormControlLabel-root': {
            '& span': {
              fontSize: '12px',
              fontWeight: 'bold',
              lineHeight: '16px',
              textAlign: 'left',
              color: '#5D5D5D',
              userSelect: 'none',
              '&.checked-label': {
                color: '#49759C !important',
                textDecoration: 'underline',
              },
            },
            '& .MuiCheckbox-root': {
              color: '#5D5D5D',
              padding: '0',
              '&.Mui-checked': {
                color: '#49759C',
              },
              '& .MuiSvgIcon-root': {
                fontSize: '20px',
                marginRight: '5px',
              },
            },
          },
        },
        '& .profile__info__section__value': {
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '16px',
          textAlign: 'left',
          color: '#5D5D5D',
          overflowY: 'auto',
          height: '100%',
        },
      },
    },
  },
}))

const ButtonSection = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  width: '100%',
  '&.edit_btn_main': {
    marginTop: '15px',

    '& .main_select': {
      width: 'fit-content',
    },
  },
}))

const CustomButton = styled(Button)(({ theme, blue, height, width }) => ({
  backgroundColor: blue ? '#87CEEB' : '#FFFFFF',
  borderRadius: '5px',
  width: width ? width : 'auto',
  height: height ? height : '40px',
  border: '1px solid #87CEEB',
  padding: '4px 16px',

  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '20px',
  color: blue ? '#FFFFFF' : '#87CEEB',
  '&:hover': {
    backgroundColor: blue ? '#87CEEB' : '#FFFFFF',
  },
}))

const COntentPopup = styled('div')(({ theme, isLeft }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: isLeft ? 'flex-start' : 'center',
  gap: '10px',
  padding: '10px 0',
  '& .content__popup__titleBar': {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > h1': {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '20px',
      color: '#1A1A1A',
    },
  },
}))

const UploadWrapper = styled('div')(({ theme }) => ({
  width: '544px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  margin: '10px 0',
  padding: '10px',
  backgroundColor: '#E2F1FE',
  border: '1px dotted #49759C',
  borderRadius: '16px',
  '& .helping__text': {
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '14.7px',
    color: '#5D5D5DDD',
    width: '100%',
    textAlign: 'center',
  },
  '& .content__section': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
  },
  '& .left__section, .right__section': {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    alignItems: 'center',
    '& .title': {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      justifyContent: 'center',
      '& > p': {
        fontSize: '12px',
        fontWeight: 'bold',
        lineHeight: '20px',
        color: '#FC9CB4',
      },
    },
    '& .supporting__text': {
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '14px',
      color: '#1A1A1A',
      textAlign: 'center',
    },
    '& .upload__text': {
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '14px',
      color: '#49759C',
      textAlign: 'center',
      cursor: 'pointer',
    },
  },
  '& .left__section': {
    borderRight: '1px solid #49759C',
  },
}))

const NavText = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '10px 0',
  gap: '10px',
  '& .nav__text': {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '20px',
    color: '#1A1A1A',
  },
  '& img': {
    cursor: 'pointer',
  },
}))

const InputArea = styled('div')(({ theme }) => ({
  width: '100%',
  '& .Input_Main': {
    width: '100%',
    '& input': {
      maxWidth: '320px',
      marginBottom: '10px',
    },

    '& .text-editor': {
      padding: '0px !important',
    },
    '& textarea': {
      // height: '128px',
      // marginBottom: '10px',
    },
  },
}))

const DeleteAndNameDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '10px',
  padding: '5px',
  backgroundColor: '#F9F9F9',
  width: '199px',
  height: '28px',
  borderRadius: '8px',
  '& .delete__icon': {
    cursor: 'pointer',
    height: '20px',
    width: '20px',
  },
  '& .name__text': {
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '20px',
    color: '#1A1A1A',
    width: '80%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'flex',
    '& span': {
      display: 'flex',
      gap: '5px',
      '& .more__div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px',
        borderRadius: '50%',
        backgroundColor: '#FC9CB4',
        color: '#FFFFFF',
        height: '20px',
        width: '20px',
      },
    },
  },
}))

const DetailsSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  '& p': {
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '16px',
    textAlign: 'left',
    color: '#1A1A1A',
  },
  '& .text-editor': {
    border: 'none !important',
  },
  '& ul': {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16px',
    textAlign: 'left',
    color: '#5D5D5D',
  },
  '& li': {
    display: 'flex',
    gap: '5px',
    marginLeft: '20px',
    whiteSpace: 'nowrap',
  },
}))

export {
  DetailsSection,
  DeleteAndNameDiv,
  InputArea,
  NavText,
  UploadWrapper,
  COntentPopup,
  CustomButton,
  ButtonSection,
  CustomPaper,
  CardWrapper,
  CustomContainer,
  CustomTableComponent,
  CustomCellHead,
  TableHeadCustom,
  TableCellCustom,
  UserName,
  AgencyName,
  TableBodyCustom,
  CardCompWrapper,
  CardContainer,
  NavCard,
  Details,
  TableCellCustomCards,
  UserNameMain,
}
