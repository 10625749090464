import { useQuery } from 'react-query'
import httpRequest from 'app/axios/index'

const fetchProfile = async (id) => {
  const { data } = await httpRequest.get(`/profileSchedule/${id}`)
  return data
}

const useGetSingleEvent = (id, open) => {
  return useQuery(['single-profile-schedule-event', id], () => fetchProfile(id), {
    enabled: !!id && open,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })
}

export default useGetSingleEvent
