import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './app/App'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import store from './app/store'
import { ThemeProvider } from '@mui/material/styles'
import theme from 'app/themes/theme'
import { QueryClientProvider, QueryClient } from 'react-query'



const root = ReactDOM.createRoot(document.getElementById('root'))
let persistor = persistStore(store)
const queryClient = new QueryClient()

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)
