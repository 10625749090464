function cmToFeetInches(cm) {
  if (typeof cm !== 'number') {
    return '-'
  }
  // Constants
  const CM_PER_INCH = 2.54
  const INCHES_PER_FOOT = 12

  // Convert centimeters to inches
  let inches = cm / CM_PER_INCH

  // Convert inches to feet and remaining inches
  let feet = Math.floor(inches / INCHES_PER_FOOT)
  inches = Math.round(inches % INCHES_PER_FOOT)

  return `${feet}'${inches} ft`
}

export const feetToCm = (feet) => {
  if (typeof feet !== 'number') {
    return
  }
  // Constants
  const CM_PER_FOOT = 30.48 // 1 foot is 30.48 cm

  // Convert feet to centimeters
  const cm = feet * CM_PER_FOOT

  return `${cm.toFixed(2)}`
}

export default cmToFeetInches
