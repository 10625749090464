import React, { memo, useEffect, useState } from 'react'
import { CustomPaper } from 'app/components/match/table/Style'
import Add from 'app/assets/images/popUp/add.svg'
import ChatPopupDummyData from './ChatPopupDUmmyData'

const capitalizeFirstLetter = (str) => {
  return str.replace(/([A-Z])/g, ' $1').replace(/\b\w/g, (char) => char.toUpperCase())
}

const formatKey = (key) => {
  return key
    .replace(/([A-Z])/g, ' $1')
    .toLowerCase()
    .replace(/\b\w/g, (char) => char.toUpperCase())
}

const ChatPopUp = ({ open = false, onClose = () => {} }) => {
  const [data, setData] = useState({})

  useEffect(() => {
    setData(ChatPopupDummyData)
  }, [])

  return (
    <CustomPaper maxHeight open={open}>
      <div className="title__bar">
        <h2>Help</h2>
        <img src={Add} onClick={onClose} alt="close" />
      </div>
      <div style={{ marginTop: '20px', height: '100%', overflowY: 'auto' }}>
        {Object.entries(data).map(([section, details]) => (
          <div key={section}>
            <h3 style={{ fontSize: '16px' }}>{capitalizeFirstLetter(section)}</h3>
            {Object.entries(details).map(([key, value]) => (
              <div key={key} style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ marginRight: '5px', fontSize: '14px' }}>{formatKey(key)}</p>
                <span style={{ fontSize: '14px' }}>:</span>
                <p style={{ marginLeft: '5px', fontSize: '14px' }}>{value}</p>
              </div>
            ))}
          </div>
        ))}
      </div>
    </CustomPaper>
  )
}

export default memo(ChatPopUp)
