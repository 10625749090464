import { combineReducers, createStore } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import loginSlice from './slices/auth/login'
import profilesSlice from './slices/profiles/profiles'
import leadsSlice from './slices/leads/leadsSlice'
import onboardingSlice from './slices/onboarding/onboardingSlice'

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['auth', 'profile', 'leads'],
}

const rootReducer = combineReducers({
  auth: loginSlice,
  profile: profilesSlice,
  leads: leadsSlice,
  onboarding: onboardingSlice,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(persistedReducer)

export default store
