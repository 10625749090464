import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#0B0B0B', // this a black color
      silver: '#F2F2F2',
    },
    error: {
      main: '#ff4d4f',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      white: '#FFFFFF',
      whiteHover: '#E2E2E2',
      main: '#FFFAFB',
      lightBlue: '#F1FAFE',
    },
    colors: {
      BlackBean: '#0B0B0B',
    },
  },
  typography: {
    fontSize: 16,
    // Poppins: "'Poppins', sans-serif",
  },
  authTitle: {
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '24px',
    lineHeight: '33px',
    color: '#0B0B0B',
  },
  authsubTit: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '25px',
    color: 'rgba(11, 11, 11, 0.6)',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
})

export default theme
