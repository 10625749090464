import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'

const LeadsFormContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '256px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  border: '1px solid #49759CDD',
  borderRadius: '16px',
  padding: '10px',
  margin: '20px 0',
  '& .form__main': {
    width: '100%',
  },
  '& .close__icon': {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& > h1': {
      fontSize: '16px',
      fontWeight: '400',
      color: '#49759C',
    },
    '& > img': {
      cursor: 'pointer',
    },
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const InputsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  alignItems: 'center',
  gap: '30px',
  width: '100%',
  input: {
    borderRadius: '8px',
    '::placeholder': {
      fontStyle: 'normal',

      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px',
      letterSpacing: '0.25px',
      textAlign: 'left',
      color: '#5D5D5D',
    },
  },
  '& .onboard_select__control': {
    borderRadius: '8px',
    minHeight: '34px',
    alignContent: 'center',
  },
  '& .community__select': {
    // maxWidth: 'calc(33% - 16px)',
    // commented to fix the width of the select in edit leads form
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: '10px',
  },
}))

const InputItem = styled('div')(({ theme, increase, increaseMax, isMultiFix }) => ({
  flexBasis: 'calc(25% - 16px)',
  flexGrow: 1,
  maxWidth: isMultiFix ? '256px' : '',
  [theme.breakpoints.down('md')]: {
    maxWidth: isMultiFix ? '100%' : '',
  },
  '& .onboard_select_container': {
    width: '100%',
    maxWidth: '100%',
  },
  [theme.breakpoints.down('md')]: {
    flexBasis: '100%',
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    flexBasis: '100%',
    marginBottom: '5px',
    gap: '0',
    '& .onboard_select_container': {
      maxWidth: '100%',
    },
  },
  ...(increase && {
    '&:last-child': {
      flexBasis: '50%',
    },
  }),
  ...(increaseMax && {
    '&:last-child': {
      flexBasis: '58%',
      maxWidth: '100%',
    },
  }),
}))

const InputMain = styled('div')(({ theme }) => ({
  marginBottom: '10px',
  '& label': {
    color: '#1A1A1A !important',
    fontWeight: '400 !important',
  },
  '& .Input_Main': {
    width: '100%',
    '& input': {
      width: '100%',
      maxWidth: '100%',
      height: '34px',
    },
  },
  '& .onboard_select__control': {
    backgroundColor: 'transparent',
    border: '1px solid rgba(206, 212, 218, 1)',
    borderRadius: '4.8px',
  },

  '& .main_select': {
    marginBottom: '0px',
    width: '100%',
    maxWidth: '100%',
  },
  '& .datePicker__fix': {
    '& .react-datepicker-wrapper': {
      maxWidth: '100%',
    },
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: '0px',
  },
}))

const BtnDiv = styled('div')(({ theme, isCenter }) => ({
  display: 'flex',
  justifyContent: isCenter ? 'center' : 'flex-end',
  width: '100%',
  gap: '10px',
  marginTop: isCenter ? '10px' : '0px',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    marginTop: '10px',
  },
}))

const CustomButton = styled(Button)(({ theme, width, isLead, cancel }) => ({
  backgroundColor: isLead ? 'transparent' : cancel ? 'transparent' : '#87CEEB',
  color: isLead ? '#49759CDD' : cancel ? '#87CEEB' : 'white',
  padding: isLead ? '8.5px 12px 10px 10px' : '4px 12px',
  borderRadius: isLead ? '16px' : '5px',
  border: isLead ? '1px solid #49759CDD' : cancel ? '1px solid #87CEEB' : 'none',
  width: width ? width : '',
  cursor: 'pointer',
  textTransform: 'none',
  margin: isLead ? '20px 0' : '0',
  justifyContent: isLead ? 'space-between' : 'center',
  '&:hover': {
    backgroundColor: isLead ? 'transparent' : cancel ? 'transparent' : '#87CEEB',
  },
  '&:disabled': {
    backgroundColor: '#87CEEB',
    color: '#ffffff',
    cursor: 'not-allowed',
    opacity: 0.5,
  },
  [theme.breakpoints.down('md')]: {
    display: isLead ? 'none' : '',
  },
}))

const ButtonDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '10px',
  margin: '0 auto',
  paddingTop: '30px',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    flexDirection: 'column',
  },
}))

export {
  LeadsFormContainer,
  InputsContainer,
  InputItem,
  InputMain,
  BtnDiv,
  CustomButton,
  ButtonDiv,
}
