import { CssBaseline } from '@mui/material'
import { ToastContainer } from 'react-toastify'
import './assets/styles/styles.css'
// import './assets/styles/fonts.css'
import 'app/assets/fonts/stylesheet.css'
import 'react-toastify/dist/ReactToastify.css'
import Router from './routes'
import ErrorBoundary from './components/error/ErrorBoundary'
import ChatIconButton from './components/shared/popUp/ChatButtonComponents/ChatIconButton'

function App() {
  return (
    <>
      <CssBaseline />
      <ErrorBoundary>
        <Router />
        <ChatIconButton />
      </ErrorBoundary>
      <ToastContainer style={{ zIndex: '9999999999' }} />
    </>
  )
}

export default App
