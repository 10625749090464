import store from 'app/store'
import { clearStore } from 'app/store/slices/auth/login'

export const requestHandler = (request) => {
  // const state = store.getState()
  // const token = state?.auth?.user?.token
  const token = JSON.parse(localStorage.getItem('authToken'))

  if (token) request.headers.Authorization = `Bearer ${token}`
  return request
}

export const successHandler = (response) => {
  return {
    ...response,
    data: response.data,
  }
}
export const errorHandler = (error) => {
  console.log('🚀 ~ errorHandler ~ error:', error)
  const { status, data } = error?.response

  // if (
  //   status === 401 ||
  //   data?.message === 'Unauthorized Access to an operation' ||
  //   data?.message === 'token is invalid'
  // ) {
  //   const { dispatch } = store
  //   dispatch(clearStore())
  // }
  return Promise.reject(error)
}
