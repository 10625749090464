import React from 'react'
import { InputMain } from './styles/styles'

const InputText = ({
  label,
  className = '',
  required = false,
  field,
  error,
  form,
  type = 'text',
  ...props
}) => {
  return (
    <InputMain className={`Input_Main ${className} ${error && 'has__error'}`}>
      {label && (
        <label>
          {label}
          {required && <span>*</span>}
        </label>
      )}

      {type === 'textarea' ? (
        <textarea {...field} {...props} />
      ) : (
        <input {...field} type={type} {...props} />
      )}

      {error && <span className="error">{error.message}</span>}
    </InputMain>
  )
}

export default InputText
