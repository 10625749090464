import React from 'react'
import { ContantMain, ContantWrapper, DashboardMain } from './style'
import SideBar from 'app/components/shared/sidebar'
import NavBar from 'app/components/shared/navbar'

const DashboardLayout = ({ children, heightVh = '', customStyles = {} }) => {
  return (
    <>
      <DashboardMain className="DashboardMain">
        <NavBar />
        <ContantWrapper>
          <SideBar />
          <ContantMain heightVh={heightVh} customStyles={customStyles}>
            {children}
          </ContantMain>
        </ContantWrapper>
      </DashboardMain>
    </>
  )
}

export default DashboardLayout
