import React, { useRef, useState } from 'react'
import { ContentWrapper, Wrapper, InputWrapper, FirstInput, SecondInput, TextArea } from './styles'
import { Box, Button, Dialog, IconButton, Typography, useTheme } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import uploadFile from 'app/assets/images/popUp/FileUpload.svg'
import { useNavigate } from 'react-router-dom'
import ClearIcon from 'app/assets/images/popUp/add.svg'
import { styled } from '@mui/material/styles'
import TextEditor from '../inputs/onBoarding/TextEditor'
import { toast } from 'react-toastify'
import DeleteIcon from '@mui/icons-material/Delete'
import { useAppDispatch } from 'app/store/hook'
import { setonboardingData } from 'app/store/slices/onboarding/onboardingSlice'

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    maxWidth: '100%',
    borderRadius: '18px',
  },
}))

export default function PopUp({ open, handleClose, anchorEl }) {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const navigate = useNavigate()
  const popperRef = useRef(null)
  const [file, setFile] = useState(null)
  const [text, setText] = useState('')
  const [isFirstInputDisabled, setIsFirstInputDisabled] = useState(false)
  const [isSecondInputDisabled, setIsSecondInputDisabled] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  const handleOnboarding = () => {
    handleClose()
    setTimeout(() => {
      navigate('/onboarding')
    }, 100)
  }

  const handleFileUpload = (e) => {
    e.preventDefault()
    let files
    if (e.type === 'drop') {
      files = e.dataTransfer.files
    } else {
      files = e.target.files
    }

    if (files && files.length > 0) {
      const file = files[0]
      if (file.size <= 3 * 1024 * 1024) {
        setFile(file)
        setIsSecondInputDisabled(true)
        setIsButtonDisabled(false)
      } else {
        toast.error('File size exceeds 3 MB')
        setIsSecondInputDisabled(false)
        setIsButtonDisabled(true)
      }
    }
  }

  const handleTextChange = (value) => {
    setText(value)
    if (value.trim() === '') {
      resetInputs()
    } else {
      setIsFirstInputDisabled(true)
      setIsButtonDisabled(false)
    }
  }

  const resetInputs = () => {
    setIsFirstInputDisabled(false)
    setIsSecondInputDisabled(false)
    setIsButtonDisabled(true)
  }

  const handleFileDelete = () => {
    setFile(null)
    resetInputs()
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const data = {
      file,
      text,
    }
    handleClose()
    if (file || text) {
      dispatch(setonboardingData(data))
      setTimeout(() => {
        navigate('/onboarding')
      }, 100)
    } else {
      toast.error('error', 'Please add the text or file')
    }
  }

  return (
    <CustomDialog open={open} anchorEl={anchorEl} placement="top" ref={popperRef}>
      <Wrapper
        sx={{
          zIndex: theme.zIndex.modal + 1,
          backgroundColor: '#fff',
          padding: '20px',
          boxShadow: theme.shadows[5],
          position: 'relative',
        }}
      >
        <img
          src={ClearIcon}
          alt="Close"
          onClick={handleClose}
          style={{
            position: 'absolute',
            zIndex: '11',
            top: '2.5px',
            right: '3px',
            width: '24px',
            cursor: 'pointer',
            height: '24px',
          }}
        />
        <ContentWrapper>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              padding: '20px 30px 10px',
              height: '100%',
              [theme.breakpoints.down('md')]: {
                width: '100%',
                height: '100%',
              },
            }}
          >
            <Box sx={{ textAlign: 'center', width: '100%' }}>
              <Typography
                sx={{
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  color: 'transparent',
                  backgroundImage: 'linear-gradient(270.19deg, #8698FE 7.97%, #6ECFFE 79.22%)',
                  fontWeight: '700',
                  fontSize: '28px',
                  letterSpacing: '0.02em',

                  fontStyle: 'italic',
                  [theme.breakpoints.down('md')]: {
                    width: '100%',
                    fontSize: '16px',
                  },
                }}
              >
                Onboard a profile in seconds
              </Typography>
              <Typography
                sx={{
                  color: 'rgba(158, 158, 158, 1)',
                  fontSize: '12px',

                  lineHeight: '20px',
                  letterSpacing: '0.02em',
                  marginTop: '5px',
                  [theme.breakpoints.down('md')]: {
                    width: '50%',
                    fontSize: '8px',
                    textAlign: 'center',
                    margin: '0 auto 10px',
                    lineHeight: '12px',
                  },
                }}
              >
                Please upload a file or simply copy & paste the biodata, we'll prefill as much info
                we can.
              </Typography>
              <form onSubmit={handleSubmit}>
                <InputWrapper>
                  <FirstInput>
                    <Box>
                      <img src={uploadFile} alt="upload" />
                    </Box>
                    <Box
                      sx={{
                        gap: '6px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        position: 'relative',
                      }}
                      onDragOver={(e) => e.preventDefault()}
                      onDrop={handleFileUpload}
                    >
                      <Typography
                        sx={{
                          fontWeight: '700',
                          fontSize: '14px',
                          color: 'rgba(255, 255, 183, 1)',
                          letterSpacing: '0.25px',
                        }}
                      >
                        Drag & Drop
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: '700',
                          fontSize: '10px',
                          color: 'rgba(255, 255, 183, 1)',
                          letterSpacing: '0.25px',
                        }}
                      >
                        OR
                      </Typography>
                      <div style={{ position: 'relative', display: 'inline-block' }}>
                        <Button
                          variant="outlined"
                          sx={{
                            border: '1px solid',
                            borderColor:
                              'linear-gradient(269.83deg, #8698FE 4.32%, #6ECFFE 90.27%)',
                            color: 'rgba(98, 183, 218, 1)',
                            fontSize: '12px',
                            fontWeight: '700',
                            padding: '4px 16px',
                            borderRadius: '5px',
                            background: 'rgba(255, 255, 255, 1)',
                            '&:hover': {
                              background: 'rgba(255, 255, 255, 1)',
                              border: '1px solid',
                              borderColor:
                                'linear-gradient(269.83deg, #8698FE 4.32%, #6ECFFE 90.27%)',
                            },
                          }}
                          onClick={() => document.getElementById('file-upload').click()}
                          disabled={isFirstInputDisabled}
                        >
                          Upload from local
                        </Button>
                        <input
                          type="file"
                          id="file-upload"
                          onChange={handleFileUpload}
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            opacity: 0,
                            cursor: 'pointer',
                          }}
                          disabled={isFirstInputDisabled}
                        />
                      </div>
                      <Typography sx={{ color: 'rgba(86, 86, 86, 1)', fontSize: '10px' }}>
                        Upload a file of size 3 MB
                      </Typography>
                      {file && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                          }}
                        >
                          <Typography sx={{ fontSize: '10px', color: 'rgba(86, 86, 86, 1)' }}>
                            {file.name.length > 20 ? `${file.name.substring(0, 23)}...` : file.name}
                          </Typography>
                          <IconButton aria-label="delete" size="small" onClick={handleFileDelete}>
                            <DeleteIcon fontSize="inherit" />
                          </IconButton>
                        </Box>
                      )}
                    </Box>
                  </FirstInput>
                  <Typography
                    sx={{
                      fontWeight: '700',
                      fontSize: '20px',
                      color: 'rgba(73, 117, 156, 1)',
                      [theme.breakpoints.down('md')]: {
                        order: 2,
                      },
                    }}
                  >
                    OR
                  </Typography>

                  <SecondInput>
                    <TextEditor
                      type="text"
                      onChange={handleTextChange}
                      placeholder="Copy & paste biodata here...."
                      disabled={isSecondInputDisabled}
                    ></TextEditor>
                  </SecondInput>
                </InputWrapper>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                  <Button
                    type="submit"
                    variant="outlined"
                    sx={{
                      border: '1px solid rgba(135, 206, 235, 1)',
                      color: 'rgba(255, 255, 255, 1)',
                      fontSize: '14px',
                      fontWeight: '750',

                      padding: '4px 16px',
                      borderRadius: '5px',
                      margin: '5px auto',
                      background: 'linear-gradient(270deg, #8698FE -1.68%, #6ECFFE 84.7%)',
                      '&:hover': {
                        background: 'linear-gradient(270deg, #8698FE -1.68%, #6ECFFE 84.7%)',
                        border: '1px solid rgba(135, 206, 235, 1)',
                      },
                      '&.Mui-disabled': {
                        background: 'rgba(200, 200, 200, 1)', // Lighter gray background
                        color: 'rgba(255, 255, 255, 0.5)', // Faded white text
                        border: '1px solid rgba(200, 200, 200, 1)', // Lighter border color
                      },
                    }}
                    disabled={isButtonDisabled}
                  >
                    Process profile
                  </Button>
                </Box>
              </form>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'end',
                  marginTop: '20px',
                  alignItems: 'center',
                  gap: '20px',
                  [theme.breakpoints.down('md')]: {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                }}
              >
                <Typography
                  sx={{
                    color: 'rgba(158, 158, 158, 1)',
                    fontSize: '12px',

                    fontStyle: 'italic',
                    fontWeight: 'bold',
                  }}
                >
                  Don't have a
                  <span
                    style={{
                      color: 'rgba(239, 45, 101, 1)',
                      fontSize: '12px',

                      fontStyle: 'italic',
                      fontWeight: 'bold',
                    }}
                  >
                    {' '}
                    biodata?
                  </span>
                </Typography>
                <Button
                  variant="outlined"
                  endIcon={
                    <ArrowForwardIcon borderColor="linear-gradient(269.83deg, #8698FE 4.32%, #6ECFFE 90.27%)" />
                  }
                  sx={{
                    border: '1px solid',
                    borderColor: 'linear-gradient(269.83deg, #8698FE 4.32%, #6ECFFE 90.27%)',
                    color: 'rgba(98, 183, 218, 1)',
                    fontSize: '12px',
                    fontWeight: 'bold',

                    padding: '4px 16px',
                    borderRadius: '5px',
                    background: 'rgba(255, 255, 255, 1)',
                    '&:hover': {
                      background: 'rgba(255, 255, 255, 1)',
                      border: '1px solid',
                      borderColor: 'linear-gradient(269.83deg, #8698FE 4.32%, #6ECFFE 90.27%)',
                    },
                  }}
                  onClick={handleOnboarding}
                >
                  Create Now
                </Button>
              </Box>
            </Box>
          </Box>
        </ContentWrapper>
      </Wrapper>
    </CustomDialog>
  )
}
