import React, { useEffect, useRef } from 'react'

import {
  MainNav,
  ContentWrapper,
  MainContent,
  TextArea,
  TextTitle,
  Count,
  ImgTag,
  MobileBellowBar,
} from './style'
import icon from 'app/assets/images/uploadData/nav/icon.svg'
import Notes from 'app/assets/images/uploadData/nav/Notes.svg'
import Match from 'app/assets/images/uploadData/nav/Match.svg'
import Schedule from 'app/assets/images/uploadData/nav/Schedule.svg'
import Payment from 'app/assets/images/uploadData/nav/Payment.svg'
import Timeline from 'app/assets/images/uploadData/nav/Timeline.svg'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { setMatchFilter } from 'app/store/slices/profiles/profiles'
import { useAppDispatch } from 'app/store/hook'
import formatDate from 'app/utils/profiles/FormatDate'
import { Box } from '@mui/material'
import cmToFeetInches from 'app/utils/profiles/Height'
import { educationOptions, hideFilterOptions } from 'app/utils/onboarding/staticValue'
import useSingleProfile from 'app/hooks/profiles/useSingleProfile'
import Loading from 'app/components/shared/Loading'

const ProfilesNavbar = ({
  matchID = '',
  holdVisible,
  smVisible,
  navVisible,
  data = [],
  totalMatches = '',
  ProfilematchProfileCount = '',
  type = '',
}) => {
  const location = useLocation()
  const { id } = useParams()
  const basePath = location.pathname.split('/').slice(0, 2).join('/')

  const { data: profileData, isLoading } = useSingleProfile(id)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { partnerPrefrencesId, overallPercentage, totalMatchesCount, profileStatus } =
    data?.data || []

  const { updatedAt, gender } = data?.data || []
  const rechangeGender = gender === 'female' ? 'male' : 'female'
  const updateProfileDate = formatDate(updatedAt)

  const {
    maritalStatus,
    occupation,
    lifestylePrefrences,
    educationLevel,

    religiousPrefrences,
    preferredCountriesforPartner,
    preferredStatesforPartner,
    preferredCitiesforPartner,
    ageRange,

    heightRange,
    preferredFamilyIncomeType,
    preferredPersonalIncome,
    preferredFamilyIncome,
    preferredPersonalIncomeType,
  } = partnerPrefrencesId || {}

  const mapping = {
    vegetarian: 'Vegetarian',
    nonVegetarian: 'Non-Vegetarian',
    smoker: 'Smoker',
    nonSmoker: 'Non-Smoker',
    drinker: 'Drinker',
    nonDrinker: 'Non-Drinker',
  }

  const lifestylePrefrencesResult = lifestylePrefrences
    ? lifestylePrefrences[0]?.split(',')?.map((item) => ({
        value: item,
        label: mapping[item],
      }))
    : []

  const mappedEducationOptions = educationLevel
    ?.map((value) => {
      const matchedOption = educationOptions.find((option) => option.value === value)
      return matchedOption ? matchedOption : null
    })
    .filter((item) => item !== null)

  const matchFilterData = {
    // agencies: { label: agencyId?.agencyName, value: agencyId?._id },
    agencies: { label: 'All', value: 'all' },
    maritalStatus:
      Array.isArray(maritalStatus) && maritalStatus.some((status) => status.trim() !== '')
        ? { label: maritalStatus, value: maritalStatus }
        : null,
    gender: gender ? { label: rechangeGender, value: rechangeGender } : null,
    diet: lifestylePrefrencesResult?.length > 0 ? lifestylePrefrencesResult : null,
    occupation: occupation ? { label: occupation, value: occupation } : null,
    ageFrom: ageRange?.from ?? null,
    ageTo: ageRange?.to ?? null,
    education: mappedEducationOptions?.length > 0 ? mappedEducationOptions : null,
    heightTo: heightRange?.to ? cmToFeetInches(heightRange?.to) : null,
    heightFrom: heightRange?.from ? cmToFeetInches(heightRange?.from) : null,
    personalincomeFrom: preferredPersonalIncome?.from ?? null,
    personalincomeTo: preferredPersonalIncome?.to ?? null,
    country: preferredCountriesforPartner
      ? { label: preferredCountriesforPartner, value: preferredCountriesforPartner }
      : null,
    state: preferredStatesforPartner
      ? { label: preferredStatesforPartner, value: preferredStatesforPartner }
      : null,
    city: preferredCitiesforPartner
      ? { label: preferredCitiesforPartner, value: preferredCitiesforPartner }
      : null,
    familyIncomeFrom: preferredFamilyIncome?.from ?? null,
    familyIncomeTo: preferredFamilyIncome?.to ?? null,
    hide: hideFilterOptions && hideFilterOptions.length > 0 ? hideFilterOptions : null,
  }

  // if (religiousPrefrences && religiousPrefrences?.length > 0) {
  if (religiousPrefrences && religiousPrefrences?.filter((item) => item !== '').length > 0) {
    matchFilterData.religion = { label: religiousPrefrences, value: religiousPrefrences }
  }

  if (matchFilterData?.familyIncomeFrom && matchFilterData?.familyIncomeTo) {
    matchFilterData.familyIncomeType = preferredFamilyIncomeType
  }

  if (matchFilterData?.personalincomeFrom && matchFilterData?.personalincomeTo) {
    matchFilterData.personalincomeType = preferredPersonalIncomeType
  }

  // const cleanedMatchFilterData = Object.fromEntries(
  //   Object.entries(matchFilterData).filter(
  //     ([, value]) =>
  //       value !== null &&
  //       value !== undefined &&
  //       value !== '' &&
  //       !(Array.isArray(value) && value.length === 0) &&
  //       !(typeof value === 'object' && Object.keys(value).length === 0),
  //   ),
  // )

  const cleanedMatchFilterData = Object.fromEntries(
    Object.entries(matchFilterData).filter(
      ([key, value]) =>
        value !== null &&
        value !== undefined &&
        value !== '' &&
        !(Array.isArray(value) && value.length === 0) &&
        // This checks arrays like 'diet' for empty 'value' fields
        !(
          Array.isArray(value) &&
          value.every((item) => typeof item === 'object' && item.value === '')
        ) &&
        !(typeof value === 'object' && Object.keys(value).length === 0),
    ),
  )
  console.log('🚀 ~ cleanedMatchFilterData:', cleanedMatchFilterData)

  const showName = window.location.pathname.includes(`/match-profiles/${id}`) ? true : false

  const menuData = [
    {
      title: 'Profile',
      userName: profileData?.data?.fullName,
      count: overallPercentage ? Math.round(overallPercentage) + '%' : '0%',
      icon: icon,
      countDetail: 'complete',
      hold: true,
      path: '/profile',
    },
    {
      title: 'Notes',
      count: '0',
      icon: Notes,
      countDetail: 'Notes',
      hold: false,
      path: '/notes',
    },
    {
      title: 'Matches',
      count:
        type === 'profile-page' ? ProfilematchProfileCount : totalMatchesCount || totalMatches || 0,

      icon: Match,
      countDetail: 'Matches',
      hold: false,
      // path: '/matches',
      path: '/match-profiles',
    },
    {
      title: 'Scheduled',
      count: '0',
      icon: Schedule,
      countDetail: 'Pending',
      hold: false,
      path: '/profile-schedule',
    },
    {
      title: 'Payments',
      count: '0',
      icon: Payment,
      countDetail: 'No payment',
      hold: false,
      path: '/payments',
    },
    {
      title: 'Timeline',
      countDetail: '0',
      icon: Timeline,
      count: 'Since May',
      hold: false,
      path: '/Timeline',
    },
  ]
  const isOnHold = menuData.some((item) => item.hold)

  const handleClick = (item) => {
    const { title } = item
    if (title === 'Matches' && basePath === '/profile') {
      dispatch(setMatchFilter(cleanedMatchFilterData))
      // navigate(`/match-profiles/${matchID}`)
      window.open(`/match-profiles/${matchID}`, '_blank')
    } else if (title === 'Scheduled' && basePath === '/profile') {
      navigate(`/profile-schedule/${id}`)
    } else if (title === 'Profile' && basePath === '/match-profiles' && id) {
      navigate(`/profile/${id}`)
    }
  }

  return (
    <>
      <MainNav smVisible={smVisible} navVisible={navVisible} className="profile__navbar">
        <ContentWrapper className="ContentWrapper">
          {menuData?.map((item, index) => {
            const activeIndex = menuData.findIndex((item) => item?.path === basePath)
            const isPreviousActive = index === activeIndex - 1
            return (
              <>
                <MainContent
                  key={index}
                  className={`main_content ${item?.path === basePath ? 'active_items' : ''} ${
                    index === data.length - 1 ? 'last' : ''
                  } ${isPreviousActive ? 'previous-active' : ''}`}
                  onClick={() => {
                    handleClick(item)
                  }}
                >
                  <ImgTag>
                    <img src={item.icon} alt="icon" />
                  </ImgTag>
                  <TextArea>
                    <TextTitle>
                      {item.userName && showName && !isLoading ? `${item.userName}` : item.title}
                      <span>
                        {item.hold
                          ? `${
                              profileStatus === 'Hold' || profileStatus === 'InActive'
                                ? '(' + profileStatus + ')'
                                : ''
                            }`
                          : ''}
                      </span>
                    </TextTitle>
                    <Count>
                      {item.count}
                      {item.title !== 'Matches'} {item.countDetail}
                    </Count>
                  </TextArea>
                </MainContent>
              </>
            )
          })}
        </ContentWrapper>
      </MainNav>
      <MobileBellowBar holdVisible={holdVisible}>
        <h1>{Math.round(overallPercentage)}% Complete</h1>
        <Box className="onHold&__date__div">
          <h2>{isOnHold ? 'ON HOLD' : ''}</h2>
          <h3>Updated: {updateProfileDate}</h3>
        </Box>
      </MobileBellowBar>
    </>
  )
}

export default ProfilesNavbar
