import React from 'react'

import { styled } from '@mui/material/styles'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import CardIcon from 'app/assets/images/match/calendar-2.svg'

const CustomDatePicker = styled(DatePicker)({
  border: '1px solid #DDDDDD',
  borderRadius: '4.8px',
  padding: '8px',
  paddingLeft: '10px !important',
  fontSize: '12px',
  width: '100%',
  height: '38px',
  color: '#5D5D5D',
  '&:focus': {
    outline: 'none',
  },
})
const CustomDatePickerWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  '&.has__error': {
    input: {
      border: '1px solid #ff4d4f',
    },
  },
  '& .react-datepicker-wrapper': {
    width: '100%',
    maxWidth: '238px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  label: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
    textAlign: 'left',
  },
  '& .react-datepicker__calendar-icon': {
    width: '20px',
    height: '20px',
    right: '10px',
    padding: '0',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  '& input::placeholder': {
    color: '#5D5D5D', 
    fontSize: '14px',
  },
  '& span.error_class': {
    color: '#ff4d4f',
    fontWeight: 400,
    fontSize: '12px',
    position: 'absolute',
    top: '100%',
    left: '0',
  },
}))

const DataPicker = ({
  label,
  className,
  field = {},
  error,
  disablePastData = false,
  onChange,
  type = '',
  ...props
}) => {
  const today = new Date()
  return (
    <>
      <CustomDatePickerWrapper className={`${className} ${error && 'has__error'}`}>
        {label && <label>{label}</label>}
        <CustomDatePicker
          selected={field?.value}
          onChange={(date) => {
            if (type === 'filter') {
              onChange(date)
            } else {
              field.onChange(date)
            }
          }}
          dateFormat="dd-MM-yyyy"
          placeholderText="DD-MM-YYYY"
          toggleCalendarOnIconClick
          showIcon
          icon={<img src={CardIcon} alt="calendar" />}
          minDate={disablePastData ? today : null}
          {...props}
        />
        {error && <span className="error_class">{error.message}</span>}
      </CustomDatePickerWrapper>
    </>
  )
}

export default DataPicker
