import { styled } from '@mui/material'

const MainContainer = styled('div')(({ theme }) => ({
  margin: '20px',

  '.add_event': {
    background: 'rgba(135, 206, 235, 1)',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 700,
    padding: '7px 20px',
  },
  '.add_event:hover': {
    background: 'rgba(135, 206, 235, 1)',
  },
  '.toolbar': {
    margin: '10px 0',
    marginBottom: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '.custom_toolbar': {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  '.custom_toolbar svg': {
    background: 'transparent',
    color: '#1A1A1A',
    cursor: 'pointer',
  },
  '.date_string': {
    color: '#1A1A1A',
    fontSize: '24px',
    fontWeight: 700,
  },
  //   main

  '.fc-theme-standard .fc-scrollgrid': {
    borderRadius: '8px',
    border: '1px solid #E8E8E8',
  },
  table: {
    'tr th': {
      textAlign: 'left',
      border: '1px solid #E8E8E8',
    },
    'tr td': {
      border: '1px solid #E8E8E8',
    },
    'tr th div a': {
      padding: '8px 0 !important',
      paddingLeft: '10px !important',
      color: '#9F9F9F',
      fontSize: '16px',
      fontWeight: 400,
      textTransform: 'uppercase',
      cursor: 'pointer',
    },
    '.fc-daygrid-day-top': {
      fontSize: '21px',
      fontWeight: 500,
      color: '#9F9F9F',
    },
    '.fc-daygrid-day-events a ': {
      width: 'fit-content',
      padding: '3px',
      fontSize: '14px',
      fontWeight: 500,
    },
  },
}))

const CalendarContainer = styled('div')(({ theme, isError }) => ({
  height: isError ? 'calc(100vh - 260px)' : 'calc(100vh - 230px)',
  overflowY: 'auto',
}))

export { MainContainer, CalendarContainer }
