import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: null,
}

const onboardingSlice = createSlice({
  name: 'onboarding-slice',
  initialState,
  reducers: {
    setonboardingData: (state, action) => {
      state.data = action.payload
    },
    resetonboardingData: (state) => {
      state.data = null
    },
  },
})

export const { setonboardingData, resetonboardingData } = onboardingSlice.actions

export default onboardingSlice.reducer
