import React, { useEffect, useRef, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
// import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction' // for clickable events
import { MainContainer, CalendarContainer } from './styles/styles'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import CreateEvent from './CreateEvent'
import moment from 'moment'
import useGetAllEvents from 'app/hooks/profileSchedule/useGetAllEvents'
import ShowEvent from './ShowEvent'

const CalendarStandard = () => {
  const calendarRef = useRef(null)
  const [currentData, setCurrentDateString] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [open, setOpen] = useState(false)
  const [openShowEvent, setopenShowEvent] = useState(false)
  const [eventID, seteventID] = useState(null)

  useEffect(() => {
    if (currentData) {
      const [monthName, year] = currentData.split(' ')
      const monthMoment = moment(`${monthName} ${year}`, 'MMMM YYYY')
      const start = monthMoment.startOf('month').format('YYYY-MM-DD')
      const end = monthMoment.endOf('month').format('YYYY-MM-DD')

      setStartDate(start)
      setEndDate(end)
    }
  }, [currentData])

  const handleDateClick = (arg) => {
    // alert(`Date clicked: ${arg.dateStr}`)
  }
  const { data: fetchedEvents, refetch, isError, error } = useGetAllEvents(startDate, endDate)

  // const events = [
  //   {
  //     title: 'Follow up for profile',
  //     date: '2024-10-01',
  //     color: '#007bff',
  //     className: 'blue-tag',
  //   },
  //   { title: 'Orange Tag', date: '2024-10-02', color: '#ff9f43', className: 'orange-tag' },
  //   { title: 'Edit file', date: '2024-10-02', color: '#ff6b6b', className: 'red-tag' },
  //   { title: 'Note taking', date: '2024-10-03', color: '#868e96', className: 'gray-tag' },
  //   { title: 'Green Tag', date: '2024-10-04', color: '#38c172', className: 'green-tag' },
  //   { title: 'Lorem', date: '2024-10-08', color: '#007bff', className: 'blue-tag' },
  // ]
  const events =
    fetchedEvents?.data?.map((event) => ({
      title: event.subject,
      date: moment(event.date).format('YYYY-MM-DD'),
      color: event.color || '#007bff',
      className: event.className || 'orange-tag',
      extendedProps: { eventId: event._id },
    })) || []

  const handlePrevClick = () => {
    const calendarApi = calendarRef.current?.getApi()
    calendarApi?.prev()
    updateDateString()
  }

  const handleNextClick = () => {
    const calendarApi = calendarRef.current?.getApi()
    calendarApi?.next()
    updateDateString()
  }

  const updateDateString = () => {
    const calendarApi = calendarRef.current?.getApi()
    const currentDate = calendarApi?.getDate()
    if (currentDate) {
      const options = { month: 'long', year: 'numeric' }
      const dateString = currentDate.toLocaleDateString('en-US', options)
      setCurrentDateString(dateString)
    }
  }

  useEffect(() => {
    updateDateString()
  }, [])

  const renderCustomToolbar = () => {
    const calendarApi = calendarRef.current?.getApi() // Get the FullCalendar API instance
    const currentDate = calendarApi?.getDate() // Get the current date
    const dateString = currentDate?.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })

    return (
      <div className="custom_toolbar">
        <NavigateBeforeIcon onClick={handlePrevClick} className="BeforeIcon" />
        <span className="date_string">{dateString}</span>
        <NavigateNextIcon onClick={handleNextClick} className="next" />
      </div>
    )
  }

  const handleClose = () => {
    setOpen(false)
  }
  const ShowEventhandleClose = () => {
    setopenShowEvent(false)
    seteventID(null)
  }

  function renderEventContent(eventInfo) {
    const { eventId } = eventInfo.event.extendedProps
    return (
      <div
        className={`event-tag ${eventInfo.event.extendedProps.className}`}
        onClick={() => {
          showEventHandler(eventId)
        }}
      >
        {eventInfo.event.title}
      </div>
    )
  }

  const showEventHandler = (id) => {
    setopenShowEvent(true)
    seteventID(id)
  }
  return (
    <>
      <MainContainer>
        <div className="toolbar">
          {renderCustomToolbar()}
          <Button startIcon={<AddIcon />} className="add_event" onClick={() => setOpen(true)}>
            ADD EVENT
          </Button>
        </div>
        {/* {isError && <div className="error-message">Error loading events: {error.message}</div>} */}
        <CalendarContainer isError>
          <FullCalendar
            className="FullCalendar"
            ref={calendarRef}
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            events={events}
            eventContent={renderEventContent}
            dateClick={handleDateClick}
            // headerToolbar={{
            //   left: '', // Only show previous and next buttons
            //   center: '', // Center the title
            //   right: '', // Add other views as needed
            // }}
            headerToolbar={false}
          />
        </CalendarContainer>
      </MainContainer>
      <CreateEvent open={open} onClose={handleClose} refetch={refetch} />
      <ShowEvent open={openShowEvent} onClose={ShowEventhandleClose} eventId={eventID} />
    </>
  )
}

export default CalendarStandard
