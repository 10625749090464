import { Avatar, Button, Dialog, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { keyframes } from '@mui/system'

// const fadeIn = keyframes`
//   0% {
//     opacity: 0;
//     transform: translateY(-10px);
//   }
//   100% {
//     opacity: 1;
//     transform: translateY(0);
//   }
// `

const NavRow = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '3px',
}))

const ContextProfile = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  padding: '10px',
}))

const ProfileWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  // alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: '10px',
  // padding: '10px',
  transition: 'background-color 0.3s, box-shadow 0.3s',
  backgroundColor: '#FFFFFF',
  boxShadow: '4px 4px 8px 0px #00000014',
  border: '1px solid #E8E8E8',
  // transform: 'translate3d(0, 0, 0)',
  // backfaceVisibility: 'hidden',
  // perspective: 1000,
  // transformStyle: 'preserve-3d',

  '&:hover': {
    boxShadow: '0px 1px 12px 0px rgba(135, 218, 252, 0.8)',
    // '& .MoreVertIcon': {
    //   visibility: 'visible',
    //   animation: `${fadeIn} 0.3s ease-in-out forwards`,
    // },
  },
}))

const ProfileImageWrapper = styled('div')(({ theme }) => ({
  //   display: 'flex',
  //   alignItems: 'center',
}))

// image section

const AvatarWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
}))
const AvatarMain = styled(Avatar)(({ theme }) => ({
  height: 70,
  width: 70,
  '& img': {
    objectPosition: 'top',
  },
}))
const AvatarDot = styled('div')(({ theme }) => ({
  width: 20,
  height: 20,
  background: '#49759C',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '-2px',
  right: '-2px',
}))
const AvatarItem = styled('div')(({ theme }) => ({
  width: 16,
  height: 16,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& img': {
    position: 'absolute',
    userSelect: 'none',
  },
}))
const ViewCount = styled('div')(({ theme }) => ({
  marginTop: '3px',
  h6: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '10px',
    letterSpacing: '0.25px',
    textAlign: 'left',
    color: '#49759C',
    textDecoration: 'underline',
  },
}))

// contant section

const ProfileContant = styled('div')(({ theme }) => ({
  // display: 'flex',
  width: '100%',
}))
const ContantHeading = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  marginBottom: '4px',
  a: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    textAlign: 'left',
    color: '#49759C',
  },
  span: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.15000000596046448px',
    textAlign: 'left',
    color: ' #9E9E9E',
  },
}))
const ContantDetails = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  // marginBottom: '10px',
  span: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.15000000596046448px',
    textAlign: 'left',
    color: ' #9E9E9E',
    textTransform: 'capitalize',
  },
  '& .MoreVertIcon': {
    cursor: 'pointer',
    marginLeft: 'auto',
    color: ' #9E9E9E',
    visibility: 'hidden',
  },
}))
const AgencyTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  marginBottom: '10px',
  padding: '0 10px',
  h2: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.25px',
    textAlign: 'left',
    color: 'rgba(100, 180, 113, 1)',
    textDecoration: 'underline',
  },
  h3: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.25px',
    textAlign: 'left',
    color: '#49759C',
    textDecoration: 'underline',
  },
}))
const User_menu = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 10px',
  width: '100%',
  position: 'relative',
  height: '30px',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}))
const Main__FilterMobile = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}))
const Content__icons = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '5px',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  '& img': {
    cursor: 'pointer',
    width: '16px',
    height: '16px',
    userSelect: 'none',
  },
  '& h6': {
    color: '#49759C',
    fontSize: '12px',
    fontWeight: 500,
  },
}))
const MainCardwrapper = styled('div')(({ theme }) => ({
  paddingBottom: '20px',
  width: '100%',
  borderRadius: '20px',
  '& .btn__apply': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '5px',
  },
}))

const ButtonsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
  // marginTop: '10px',
  '& h6': {
    color: 'rgba(73, 117, 156, 1)',
    fontSize: '16px',
    lineHeight: '14px',
    fontWeight: 500,
  },
}))

const ButtonsMain = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '5px',
  '& .css-1jxdcj3-MuiSvgIcon-root': {
    color: '#49759C',
    width: '20px',
    height: '20px',
  },
}))

const FilterWrapperMain = styled('div')(({ theme }) => ({
  padding: '0 10px',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '5px',
  marginBottom: '5px',
}))

const H6Wrapper = styled('div')({
  width: '100%',
  '& h6': {
    color: '#5D5D5D',
    fontSize: '12px',
    fontWeight: 500,
    marginBottom: '5px',
  },
})

const NavWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  height: '22px',
  '& .left': {
    display: 'flex',
    backgroundColor: '#F6ECFD',
    height: '22px',
    borderRadius: '7px 0 0 0',
    padding: '0 7px',
    alignItems: 'center',
    '& .left__content': {
      display: 'flex',
      gap: '5px',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& img': {
      width: '14px',
      height: '14px',
      userSelect: 'none',
    },
    '& span': {
      fontSize: '12px',
      fontWeight: 500,

      lineHeight: '14px',
      letterSpacing: '0.25px',
      color: '#5D5D5D',
      display: 'flex',
    },
  },
  '& .right': {
    display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    padding: '7px',
    paddingTop: '5px',
    '& .MuiSvgIcon-root': {
      color: '#9E9E9E',
      cursor: 'pointer',
      fontSize: '20px',
      '&:hover': {
        color: '#000',
        transition: 'all 0.5s ease-in-out',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '23px',
      },
    },
    '& .right__content': {
      display: 'flex',
      gap: '5px',
      alignItems: 'center',
      justifyContent: 'center',
      '& .circle__green': {
        width: '10px',
        height: '10px',
        backgroundColor: '#2DAF32',
        borderRadius: '50%',
      },
      '& .circle__yellow': {
        width: '10px',
        height: '10px',
        backgroundColor: '#F8DB44',
        borderRadius: '50%',
      },
      '& .circle__red': {
        width: '10px',
        height: '10px',
        backgroundColor: '#BD1616',
        borderRadius: '50%',
      },
    },
  },
}))

const FilterWrapper = styled('div')(({ theme, width }) => ({
  display: 'flex',
  width: width || 'calc(91% - 20px)',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  margin: '0px auto',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '12px',
  },
}))

const DetailsMatch = styled('div')(({ theme }) => ({
  backgroundColor: '#FFF8FA',
  padding: '4px 8px',
  borderRadius: '20px',
  width: '190px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '4px 4px 8px 0px #00000014',
}))

const NewMatch = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#FC9CB4',
  justifyContent: 'center',
  padding: '2px 8px',
  borderRadius: '20px',
  width: '86px',
  height: '32px',
  gap: '2px',
  '& img': {
    width: '20px',
    height: '20px',
    userSelect: 'none',
  },
  '& h6': {
    color: '#FFFFFF',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '13px',
    textAlign: 'center',
  },
}))
const OldMatch = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'transparent',
  justifyContent: 'center',
  padding: '2px 8px',
  borderRadius: '20px',
  width: '86px',
  height: '32px',
  gap: '2px',
  '& img': {
    width: '20px',
    height: '20px',
    userSelect: 'none',
  },
  '& h6': {
    color: '#49759C',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '13px',
    textAlign: 'center',
  },
}))

const WrapperProfile = styled('div')(
  ({ theme, borderInvisible, noBg, width, margin, height, heightSm }) => ({
    width: 'calc(100% - 43px)',
    margin: '30px 0 0 30px',
    height: height ? height : '100%',
    border: borderInvisible ? 'none' : '1px solid #DDDDDD',
    borderRadius: '20px',
    overflow: 'hidden',
    backgroundColor: noBg ? 'transparent' : '#FAFAFA',
    [theme.breakpoints.down('md')]: {
      width: width ? 'calc(100% - 43px)' : '100%',
      margin: margin ? '30px 0 0 30px' : '20px 0 0 20px',
      height: heightSm ? 'calc(100dvh - 75px)' : 'calc(100dvh - 100px)',
    },
  }),
)

const ContentWrapper = styled('div')(({ height, theme }) => ({
  height: 'calc(100vh - 40px)',
  overflow: 'hidden',
  padding: '10px 15px',
  [theme.breakpoints.down('md')]: {
    padding: '0',
    height: height ? height : '95%',
  },
}))

const CardWrapper = styled('div')(({ theme, height }) => ({
  gap: '24px',
  overflowY: 'auto',
  overflowX: 'hidden',
  display: 'flex',
  // height: height ? height : '85%',
  height: height ? height : 'auto',
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    height: '80%',
    margin: '0 8px',
    gap: '10px',
  },
}))

const CustomGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexBasis: '23.4%',
    maxWidth: '23.4%',
  },
  [theme.breakpoints.only('md')]: {
    flexBasis: '48%',
    maxWidth: '48%',
  },
  [theme.breakpoints.only('sm')]: {
    flexBasis: '47%',
    maxWidth: '47%',
  },
}))

const MatchCount = styled('p')(({ theme }) => ({
  color: '#5D5D5D',
  fontSize: '12px',
  fontWeight: 500,

  lineHeight: '14px',
  letterSpacing: '0.5px',
  textAlign: 'left',
}))

const ScrollWrapper = styled('div')(({ theme }) => ({
  height: 'calc(85% - 20px)',
  overflowY: 'auto',
  overflowX: 'hidden',
  padding: '10px',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
}))

const CustomButton = styled(Button)(({ theme }) => ({
  width: '182px',
  height: '40px',
  background: '#87CEEB',
}))
const FiltersButton = styled(Button)(({ theme }) => ({
  height: '40px',
  background: '#87CEEB',
  color: '#FFFFFF',
  fontSize: '14px',
  fontWeight: 500,
  '&:hover': {
    color: '#87CEEB',
    backgroundColor: '#FFFFFF',
    border: '1px solid #87CEEB',
  },
}))

const CountUser = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100px',
  gap: '10px',
  height: '40px',
  '& .divider': {
    width: '1px',
    height: '24px',
    backgroundColor: '#9E9E9E',
  },
  '& .MuiBox-root': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '5px',
  },
  '& img': {
    width: '20px',
    height: '20px',
  },
  '& h6': {
    fontSize: '12px',
    fontWeight: 500,
    '&.red': {
      color: '#FB6F92',
    },
    '&.blue': {
      color: '#49759C',
    },
  },
}))

const RowDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

const GreetDivMobile = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0 0 0 10px',
  width: '100%',
  '& h4': {
    color: '#49759C',
    fontSize: '14px',
    fontWeight: 500,
  },
  '& h5': {
    color: '#49759C',
    fontSize: '14px',
    fontWeight: 400,
  },
}))

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '16px',
    width: '100%',
    height: '100%',
    margin: '16px',
  },
}))

export {
  CustomDialog,
  RowDiv,
  GreetDivMobile,
  CustomButton,
  ScrollWrapper,
  MatchCount,
  CustomGrid,
  CardWrapper,
  ContentWrapper,
  WrapperProfile,
  NewMatch,
  ProfileImageWrapper,
  AvatarMain,
  AvatarWrapper,
  AvatarDot,
  AvatarItem,
  ProfileContant,
  ProfileWrapper,
  ViewCount,
  ContantHeading,
  ContantDetails,
  AgencyTitle,
  Main__FilterMobile,
  Content__icons,
  FiltersButton,
  User_menu,
  MainCardwrapper,
  ButtonsWrapper,
  ButtonsMain,
  FilterWrapperMain,
  H6Wrapper,
  NavRow,
  ContextProfile,
  NavWrapper,
  FilterWrapper,
  DetailsMatch,
  OldMatch,
  CountUser,
}
