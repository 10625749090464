import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  onboarding: {},
}

const leadsSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    setonboardingData: (state, action) => {
      state.onboarding = action.payload
    },
    resetonboardingData: (state, action) => {
      state.onboarding = {}
    },
  },
})

export const { setonboardingData, resetonboardingData } = leadsSlice.actions

export default leadsSlice.reducer
