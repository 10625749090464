import React from 'react'
import { CustomPaper } from '../dashboard/homeNew/tasks/styles/Tasks'
import { EventContainer } from './styles/createEventStyle'
import Add from 'app/assets/images/popUp/add.svg'
import useGetSingleEvent from 'app/hooks/profileSchedule/useGetSingleEvent'

const ShowEvent = ({ open = false, onClose = () => {}, eventId = '' }) => {
  const { data, isLoading } = useGetSingleEvent(eventId, open)
  const { type, loreum, description } = data?.data || {}
  return (
    <>
      <CustomPaper open={open} className="show_event_popup">
        <EventContainer>
          <div className="title__bar">
            <h2>Follow up for profile</h2>
            <img src={Add} onClick={onClose} alt="close" />
          </div>
          <div style={{ margin: '20px 0' }} className="show_event_container">
            {isLoading && <p>Loading...</p>}
            <ShowItem title={'Type'} details={type} />
            <ShowItem title={'Loreum'} details={loreum} />
            <ShowItem title={'Description'} details={description} />
          </div>
        </EventContainer>
      </CustomPaper>
    </>
  )
}
const ShowItem = ({ title = '', details = '' }) => {
  return (
    <>
      <div className="show_item_main">
        <h3>{title}</h3>
        <p>{details}</p>
      </div>
    </>
  )
}

export default ShowEvent
