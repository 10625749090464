import { styled } from '@mui/material/styles'
import { AppBar } from '@mui/material'
import InputBase from '@mui/material/InputBase'

const AppBarMain = styled(AppBar)(({ theme, isProfilePage }) => ({
  background: theme.palette.background.lightBlue,
  backgroundColor: theme.palette.background.lightBlue,
  boxShadow: 'none',
  width: 'calc(100% - 80px)',
  color: '#000',
  display: 'flex',
  justifyContent: 'space-between',

  zIndex: isProfilePage ? 0 : 9,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  '& .MuiToolbar-root': {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
}))

const GreetDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  '& img': {
    display: 'none',
    width: 'auto',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  '& h6': {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  '& .MuiBox-root': {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
  },
  '& span': {
    color: '#49759C',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '17.15px',
  },
  '& h4': {
    color: '#49759C',
    fontSize: '14px',
    fontWeight: '700',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  border: '1px solid #87CEEB',
  borderRadius: '100px',
  display: 'flex',
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: '467px',
  },
  [theme.breakpoints.down('sm')]: {
    marginRight: 0,
  },
}))
const SearchWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    width: '66%',
  },
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  borderLeft: '1px solid #87CEEB',
  right: 0,
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    borderLeft: 'none',
    padding: theme.spacing(0, 1),
  },
  '& svg': {
    color: '#87CEEB',
  },
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  maxWidth: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 8, 1, 3),
    color: '#8B8A8A',
    fontWeight: '400',
    fontSize: '14px',
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '100px',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'transparent',
      padding: '8px 34px 8px 8px',
    },
  },
}))

const UserWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '45px',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  '& .MuiIconButton-root': {
    padding: 0,
  },
}))

const MobileDrawer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '77px',
  '& .css-erj84g': {
    paddingLeft: '10px',
  },
  '& img': {
    width: '30px',
    height: '30px',
  },
  '& p': {
    color: '#9E9E9E',
    fontSize: '8px',
    fontWeight: '700',
    lineHeight: '9.8px',
  },
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    width: 'fit-content',
  },
}))

const SidebarWrapperMobile = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
  '& .MuiIconButton-root': {
    padding: 0,
  },
  '& img': {
    width: 'auto',
    height: 'auto',
  },
}))

const LogoDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& img': {
    width: 'auto',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    width: '15%',
  },
}))

const AdvanceSearch = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // width: '30px',
  marginLeft: '5px',
  flexDirection: 'column',
  '& img': {
    width: '24px',
    height: '24px',
  },
  '& p': {
    color: '#9E9E9E',
    fontSize: '8px',
    fontWeight: 'bold',
    lineHeight: '8px',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

export {
  LogoDiv,
  AppBarMain,
  Search,
  SearchIconWrapper,
  StyledInputBase,
  GreetDiv,
  UserWrapper,
  MobileDrawer,
  SidebarWrapperMobile,
  AdvanceSearch,
  SearchWrapper,
}
