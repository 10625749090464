import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import httpRequest from 'app/axios/index'

const Create = async (data) => {
  const { data: returnData } = await httpRequest.post('/profileSchedule', data)
  return returnData
}

const useCreateEvent = () => {
  return useMutation(Create, {
    onSuccess: (data) => {
      if (data?.success) {
        toast.success('added successfully')
      }
    },
    onError: (error) => {
      toast.error('Failed to add')
      console.error('🚀 ~ addComment ~ error:', error)
    },
  })
}

export default useCreateEvent
