import React, { Component } from 'react'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null, errorInfo: null }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render shows the fallback UI
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service here
    console.error('ErrorBoundary caught an error', error, errorInfo)
    this.setState({ error, errorInfo })
  }

  handleReload = () => {
    window.location.reload()
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={styles.container}>
          <h1 style={styles.header}>Oops! An error occurred.</h1>
          <p style={styles.message}>We're sorry for the inconvenience. Please try again later.</p>
          <details style={styles.details}>
            <summary style={styles.summary}>Click to view error details</summary>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo && this.state.errorInfo.componentStack}
          </details>
          <button onClick={this.handleReload} style={styles.button}>
            Reload Page
          </button>
        </div>
      )
    }

    return this.props.children
  }
}

const styles = {
  container: {
    padding: '20px',
    backgroundColor: '#f8d7da',
    color: '#721c24',
    border: '1px solid #f5c6cb',
    borderRadius: '5px',
    maxWidth: '600px',
    margin: '50px auto',
    textAlign: 'center',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  header: {
    fontSize: '24px',
    marginBottom: '10px',
  },
  message: {
    fontSize: '16px',
    marginBottom: '20px',
  },
  details: {
    whiteSpace: 'pre-wrap',
    marginBottom: '20px',
    backgroundColor: '#f1f1f1',
    padding: '10px',
    borderRadius: '4px',
  },
  summary: {
    cursor: 'pointer',
    color: '#0056b3',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#d9534f',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
  },
}

export default ErrorBoundary
