import { styled } from '@mui/material/styles'

const DashboardMain = styled('section')(({ theme }) => ({
  background: theme.palette.background.main,
  minHeight: '100%',
  height: '100%',
}))
const ContantWrapper = styled('div')(({ theme }) => ({
  background: theme.palette.background.main,
  display: 'flex',
}))
const ContantMain = styled('main')(({ theme, heightVh = '', customStyles = {} }) => ({
  background: 'white',
  flexGrow: 1,
  minHeight: '100%',
  height: `${heightVh ? heightVh : '100%'} `,
  display: 'flex',
  flexDirection: 'column',
  padding: '78px 16px 8px 16px',
  width: 'calc(100% - 80px)',
  ...customStyles,
  // position: 'fixed',
  // right: 0,
  // top: '64px',
  [theme.breakpoints.down('md')]: {
  width: '100%',
  },
}))

export { DashboardMain, ContantMain, ContantWrapper }
