import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Badge from '@mui/material/Badge'
import SearchIcon from '@mui/icons-material/Search'
import logo from 'app/assets/images/logo/logoMini.svg'
import AdvanceSearchIcon from 'app/assets/images/NavIcons/advanceSearch.svg'
import AccountPopover from './AccountPopover'
import {
  AppBarMain,
  MobileDrawer,
  Search,
  SearchIconWrapper,
  // StyledInputBase,
  GreetDiv,
  UserWrapper,
  LogoDiv,
  AdvanceSearch,
  SearchWrapper,
} from './styles'
import PopUp from 'app/components/shared/popUp/PopUp'
import PersonAddAltOutlinedIcon from 'app/assets/images/NavIcons/InviteProfile.svg'
import MailIcon from 'app/assets/images/NavIcons/notification.svg'
import Sidebar from './SideBar'
import { useAppSelector } from 'app/store/hook'
import Breadcrumbs from 'app/components/shared/breadcrumb/Breadcrumb'
import { Link, useLocation } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import { Hidden, useMediaQuery } from '@mui/material'
import DebouncedSearchInput from './DebouncedSearchInput'
import { useAppDispatch } from 'app/store/hook'
import { resetSearchValue, setSearchValue } from 'app/store/slices/profiles/profiles'
import { useSelector } from 'react-redux'

const getGreeting = () => {
  const hour = new Date().getHours()
  if (hour < 12) {
    return 'Good Morning'
  } else if (hour < 18) {
    return 'Good Afternoon'
  } else if (hour < 21) {
    return 'Good Evening'
  } else {
    return 'Good Night'
  }
}
export default function NavBar() {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const searchValue = useSelector((state) => state?.profile?.search)
  const profilePaths = ['/profile', '/compare']
  const isProfilePage = profilePaths.some((path) => location.pathname.startsWith(path))
  // const isProfilePage =
  //   location.pathname.startsWith('/profile') || location.pathname.startsWith('/compare')
  const userInformation = useAppSelector((state) => state.auth)
  const { userInfo } = userInformation
  const ownerName = userInfo?.agencyId[0]?.ownerName || userInfo?.matchmakerName || ''

  const [open, setOpen] = React.useState(false)
  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = () => {
    setOpen(true)
  }

  const theme = useTheme()
  const isBelowMd = useMediaQuery(theme.breakpoints.down('sm'))
  const greeting = getGreeting()

  const handleSearch = (value) => {
    if (value) {
      dispatch(setSearchValue(value))
    } else {
      dispatch(resetSearchValue())
    }
  }

  useEffect(() => {
    if (searchValue) {
      dispatch(resetSearchValue())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AppBarMain position="fixed" isProfilePage={isProfilePage}>
      <Toolbar>
        <LogoDiv className="logo_section">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </LogoDiv>
        {location.pathname === '/' ? (
          <GreetDiv className="GreetDiv">
            {/* <img src={logo} alt="logo" /> */}
            <h6>
              <Box>
                <span>{greeting}</span>
                <h4>{ownerName} !</h4>
              </Box>
            </h6>
          </GreetDiv>
        ) : (
          <Hidden mdDown>
            <Breadcrumbs />
          </Hidden>
        )}

        {location.pathname === '/profiles' && (
          <SearchWrapper>
            <Search className="search__input">
              <DebouncedSearchInput onSearch={handleSearch} />
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
            </Search>
            {/* {location.pathname === '/' ? null : (
              <AdvanceSearch>
                <img src={AdvanceSearchIcon} alt="Advance Search" />
                <p>Advanced search</p>
              </AdvanceSearch>
            )} */}
          </SearchWrapper>
        )}

        <UserWrapper className="UserWrapper">
          <IconButton
            size="large"
            aria-label="show new notifications"
            color="inherit"
            disableRipple={true}
            onClick={handleOpen}
          >
            <img src={PersonAddAltOutlinedIcon} alt="Invite Profile" />
          </IconButton>
          <PopUp open={open} handleClose={handleClose} />

          <IconButton size="large" aria-label="show new mails" color="inherit" disableRipple={true}>
            <Badge badgeContent={0} color="error">
              <img src={MailIcon} alt="Messages" />
            </Badge>
          </IconButton>

          {/* Profile */}
          <AccountPopover />
        </UserWrapper>

        {/* Mobile Menu */}
        <MobileDrawer className="MobileDrawer">
          <Sidebar />
          <p>Add Client</p>
        </MobileDrawer>
      </Toolbar>
    </AppBarMain>
  )
}
