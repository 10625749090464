import { styled } from '@mui/material/styles'

const MainNav = styled('div')(({ theme, smVisible, navVisible }) => ({
  // height: '63px',
  width: '100%',
  // display: 'flex',
  display: navVisible ? 'flex' : 'none',
  flexDirection: 'column',
  backgroundColor: '#FFFFFF',
  borderRadius: '20px 20px 0px 0px',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
  [theme.breakpoints.down('sm')]: {
    display: smVisible ? 'flex' : 'none',
    // height: '56px',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
  },
}))

const ContentWrapper = styled('ul')(({ theme }) => ({
  display: 'flex',
  // justifyContent: 'space-between',
  // padding: '0px 15px',
  width: '100%',
  position: 'relative',
  // '&::after': {
  //   content: "''",
  //   position: 'absolute',
  //   height: '100%',
  //   width: '14%',
  //   zIndex: '0',
  //   backgroundColor: '#FFE5EC',
  //   borderRadius: '20px 20px 0 0',
  //   left: '0',
  //   bottom: '0',
  //   [theme.breakpoints.down('sm')]: {
  //     height: '126%',
  //     width: '17.5%',
  //     bottom: '-6px',
  //   },
  // },
  // '& > :last-child': {
  //   paddingRight: '80px',
  //   [theme.breakpoints.down('sm')]: {
  //     paddingRight: '0',
  //   },
  // },
}))
const MainContent = styled('li')(({ theme }) => ({
  display: 'flex',
  padding: '15px 0px',
  // justifyContent: 'space-between',
  alignItems: 'center',
  gap: '7px',
  cursor: 'pointer',
  width: '100%',
  position: 'relative',
  height: '100%',
  zIndex: '1',
  '&.main_content.last': {
    marginRight: '4%',
  },
  '&:first-child': {
    paddingLeft: '15px',
    content: 'none',
  },
  '&:last-child': {
    '&::after': {
      content: 'none',
    },
  },
  '&::after': {
    content: "''",
    position: 'absolute',
    height: '80%',
    width: '2px',
    backgroundColor: '#D9D9D9',
    right: '13px',
    top: '50%',
    transform: 'translateY(-50%)',
    [theme.breakpoints.down('sm')]: {
      // height: '126%',
      // width: '17.5%',
      // bottom: '-6px',
      content: 'none',
    },
  },
  '&.active_items::after': {
    content: 'none',
  },
  '&.active_items::before': {
    content: "''",
    position: 'absolute',
    height: '100%',
    width: '93%',
    zIndex: '-1',
    backgroundColor: '#FFE5EC',
    borderRadius: '20px 20px 0 0',
    left: '-8px',
    bottom: '0',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      width: '100%',
      borderRadius: '15px 15px 0 0',
    },
  },
  '&.previous-active': {
    '&::after': {
      content: 'none',
    },
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '&:first-child': {
      paddingLeft: 0,
    },
    gap: '1px',
  },
}))

const Divider = styled('div')(({ theme }) => ({
  color: '#D9D9D9',
  paddingRight: '10px',
  fontSize: '38px',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

const ImgTag = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: '5px',
  '& img': {
    [theme.breakpoints.down('sm')]: {
      width: '20px',
      height: '20px',
    },
  },
}))

const TextArea = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '3px',
  flexDirection: 'column',
}))
const TextTitle = styled('div')(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 'bold',
  color: '#49759C',
  lineHeight: '14.7px',

  textAlign: 'left',
  marginBottom: '1px',
  '& span': {
    color: '#E1BB35',
    fontWeight: 'bold',
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
    fontWeight: 'bold',
  },
}))

const Count = styled('div')(({ theme }) => ({
  fontSize: '13px',
  fontWeight: '400',
  color: '#5D5D5D',
  lineHeight: '15.93px',

  textAlign: 'left',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

const MobileBellowBar = styled('div')(({ theme, holdVisible }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: holdVisible ? 'none' : 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    // height: '56px',
    padding: '7px',
    backgroundColor: 'transparent',
    '& h1': {
      fontSize: '10px',
      fontWeight: 'bold',
      lineHeight: '12px',
      letterSpacing: '0.5px',
      textAlign: 'left',
      color: '#49759C',
    },
    '& .MuiBox-root': {
      display: 'flex',
      gap: '5px',
      width: 'fit-content',
    },
    '& h2': {
      fontSize: '10px',
      fontWeight: 'bold',
      lineHeight: '12px',
      letterSpacing: '0.5px',
      textAlign: 'left',
      color: '#D30707',
    },
    '& h3': {
      fontSize: '10px',
      fontWeight: '400',
      lineHeight: '12px',
      letterSpacing: '0.5px',
      textAlign: 'left',
      color: '#5D5D5D',
    },
  },
}))

export {
  MainNav,
  ContentWrapper,
  MainContent,
  TextArea,
  TextTitle,
  Count,
  ImgTag,
  Divider,
  MobileBellowBar,
}
