const genderOptions = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  {
    value: 'non-binary',
    label: 'Non-Binary',
  },
]

const maritalStatusOptions = [
  { value: 'single', label: 'Single' },
  { value: 'divorced', label: 'Divorced' },
  { value: 'widowed', label: 'Widowed' },
  {
    value: 'annulled',
    label: 'Annulled',
  },
]

const lookingToOptions = [
  // { value: 'serious', label: 'Serious' },
  // { value: 'casual', label: 'Casual' },
  { value: 'married', label: 'Get married' },
  { value: 'findPartner', label: 'Find Partner' },
]

const physicalAbilitiesOptions = [
  // { value: 'no-disabilities', label: 'No Disabilities' },
  // { value: 'visual-impairment', label: 'Visual Impairment' },
  // { value: 'hearing-impairment', label: 'Hearing Impairment' },
  // { value: 'speech-impairment', label: 'Speech Impairment' },
  // { value: 'physical-disability', label: 'Physical Disability' },
  // { value: 'intellectual-disability', label: 'Intellectual Disability' },
  // { value: 'multiple-disabilities', label: 'Multiple Disabilities' },
  // { value: 'alopecia', label: 'Alopecia' },
  // { value: 'cleft-lip', label: 'Cleft Lip' },
  // { value: 'vitiligo', label: 'Vitiligo' },
  // { value: 'mobility-impairment', label: 'Mobility Impairment' },
  // { value: 'autism-spectrum-disorder', label: 'Autism Spectrum Disorder' },
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
  { value: 'Not-sure', label: 'Not Sure' },
]

const generateHeight = () => {
  const height = []

  for (let i = 4; i <= 7; i++) {
    for (let j = 0; j <= 11; j++) {
      const value = ((i * 12 + j) * 2.54).toFixed(2)
      const label = `${i}'${j}ft`
      height.push({ value, label })
    }
  }
  return height
}

const heightOptions = generateHeight()

const religionOptions = [
  { value: 'hindu', label: 'Hindu' },
  { value: 'muslim', label: 'Muslim' },
  { value: 'christian', label: 'Christian' },
  { value: 'omnist', label: 'Omnist' },
  { value: 'atheist', label: 'Atheist' },
  { value: 'sikh', label: 'Sikh' },
  { value: 'buddhist', label: 'Buddhist' },
  { value: 'jain', label: 'Jain' },
  { value: 'parsi', label: 'Parsi' },
  { value: 'jewish', label: 'Jewish' },
  { value: 'other', label: 'Other' },
]

const castOptions = [
  { value: 'brahmin', label: 'Brahmin' },
  { value: 'kshatriya', label: 'Kshatriya' },
  { value: 'vaishya', label: 'Vaishya' },
  { value: 'shudra', label: 'Shudra' },
  { value: 'other', label: 'Other' },
]

const gothraOptions = [
  { value: 'bharadwaj', label: 'Bharadwaj' },
  { value: 'kashyap', label: 'Kashyap' },
  { value: 'vashishtha', label: 'Vashishtha' },
  { value: 'koundinya', label: 'Koundinya' },
  { value: 'other', label: 'Other' },
]

const subCastOptions = [
  { value: 'brahmin', label: 'Brahmin' },
  { value: 'kshatriya', label: 'Kshatriya' },
  { value: 'vaishya', label: 'Vaishya' },
  { value: 'shudra', label: 'Shudra' },
  { value: 'other', label: 'Other' },
]

const muslimSectOptions = [
  { value: 'sunni', label: 'Sunni' },
  { value: 'shia', label: 'Shia' },
  { value: 'sufi', label: 'Sufi' },
  { value: 'ahmadiyya', label: 'Ahmadiyya' },
  { value: 'other', label: 'Other' },
]

const religiousPracticeOptions = [
  { value: 'very_practicing', label: 'Very Practicing' },
  { value: 'moderately_practicing', label: 'Moderately Practicing' },
  { value: 'somewhat_practicing', label: 'Somewhat Practicing' },
  { value: 'not_practicing', label: 'Not Practicing' },
]

const prayerHabitsOptions = [
  { value: 'five_times', label: 'Prays five times a day' },
  { value: 'occasionally', label: 'Prays occasionally' },
  { value: 'rarely', label: 'Rarely prays' },
  { value: 'does_not_pray', label: 'Does not pray' },
]

const fastingDuringRamadanOptions = [
  { value: 'fasts_every_ramadan', label: 'Fasts every Ramadan' },
  { value: 'fasts_occasionally', label: 'Fasts occasionally' },
  { value: 'does_not_fast', label: 'Does not fast' },
]

const halalDietOptions = [
  { value: 'strictly_halal', label: 'Strictly halal' },
  { value: 'prefer_halal', label: 'Prefer halal' },
  { value: 'not_particular', label: 'Not particular about halal' },
]

// Generate options for hours (00 to 23)
const hoursOptions = Array.from({ length: 12 }, (_, i) => ({
  value: (i + 1).toString().padStart(2, '0'),
  label: (i + 1).toString().padStart(2, '0'),
}))

const minutesOptions = Array.from({ length: 60 }, (_, i) => ({
  value: i.toString().padStart(2, '0'),
  label: i.toString().padStart(2, '0'),
}))

const sunSignOptions = [
  { value: 'aries', label: 'Aries' },
  { value: 'taurus', label: 'Taurus' },
  { value: 'gemini', label: 'Gemini' },
  { value: 'cancer', label: 'Cancer' },
  { value: 'leo', label: 'Leo' },
  { value: 'virgo', label: 'Virgo' },
  { value: 'libra', label: 'Libra' },
  { value: 'scorpio', label: 'Scorpio' },
  { value: 'sagittarius', label: 'Sagittarius' },
  { value: 'capricorn', label: 'Capricorn' },
  { value: 'aquarius', label: 'Aquarius' },
  { value: 'pisces', label: 'Pisces' },
]

const motherTongueOptions = [
  {
    label: 'Hindi-Delhi',
    value: 'Hindi_Delhi',
  },
  {
    label: 'Hindi-MP/CG',
    value: 'Hindi_MP_CG',
  },
  {
    label: 'Hindi-UP/UK',
    value: 'Hindi_UP_UK',
  },
  {
    label: 'Punjabi',
    value: 'Punjabi',
  },
  {
    label: 'Hindi-Bihar/Jharkhand',
    value: 'Hindi_Bihar_Jharkhand',
  },
  {
    label: 'Hindi-Rajasthan',
    value: 'Hindi_Rajasthan',
  },
  {
    label: 'Haryanvi',
    value: 'Haryanvi',
  },
  {
    label: 'Himachali',
    value: 'Himachali',
  },
  {
    label: 'Kashmiri',
    value: 'Kashmiri',
  },
  {
    label: 'Sindhi',
    value: 'Sindhi',
  },
  {
    label: 'Urdu',
    value: 'Urdu',
  },
  {
    label: 'Marathi',
    value: 'Marathi',
  },
  {
    label: 'Gujarati',
    value: 'Gujarati',
  },
  {
    label: 'Kutchi',
    value: 'Kutchi',
  },
  {
    label: 'Konkani',
    value: 'Konkani',
  },
  {
    label: 'Tamil',
    value: 'Tamil',
  },
  {
    label: 'Telugu',
    value: 'Telugu',
  },
  {
    label: 'Kannada',
    value: 'Kannada',
  },
  {
    label: 'Malayalam',
    value: 'Malayalam',
  },
  {
    label: 'Tulu',
    value: 'Tulu',
  },
  {
    label: 'Bengali',
    value: 'Bengali',
  },
  {
    label: 'Oriya',
    value: 'Oriya',
  },
  {
    label: 'Assamese',
    value: 'Assamese',
  },
  {
    label: 'Sikkim/ Nepali',
    value: 'Sikkim_Nepali',
  },
  {
    label: 'English',
    value: 'English',
  },
]
const manglikOptions = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
  { value: 'idk', label: "Don't know" },
]

const educationOptions = [
  { label: 'Postdoctoral Level', value: 'postdoctoral_level' },
  { label: 'Doctorate Level', value: 'doctorate_level' },
  { label: "Master's Level", value: 'masters_level' },
  { label: 'Postgraduate Diploma/Certificate', value: 'postgraduate_diploma' },
  { label: "Bachelor's Level", value: 'bachelors_level' },
  { label: "Associate's Level", value: 'associates_level' },
  { label: 'Some College (No Degree)', value: 'some_college' },
  { label: 'Vocational/Technical Training', value: 'vocational_technical_training' },
  { label: 'High School', value: 'high_school' },
  { label: 'Middle School', value: 'middle_school' },
  { label: 'Primary School', value: 'primary_school' },
]

const employmentOptions = [
  { label: 'Government', value: 'Government' },
  { label: 'Private Sector', value: 'Private Sector' },
  { label: 'Business/Entrepreneur', value: 'Business/Entrepreneur' },
  { label: 'Defense/Civil Services', value: 'Defense/Civil Services' },
  { label: 'Self-Employed', value: 'Self-Employed' },
  { label: 'Nonprofit/Social Services', value: 'Nonprofit/Social Services' },
  { label: 'Education', value: 'Education' },
  { label: 'Healthcare', value: 'Healthcare' },
  { label: 'Homemaker', value: 'Homemaker' },
  { label: 'Student', value: 'Student' },
  { label: 'Retired', value: 'Retired' },
  { label: 'Not Employed', value: 'Not Employed' },
]

const occupationOptions = [
  { label: 'Accountant', value: 'accountant' },
  { label: 'Actor', value: 'actor' },
  { label: 'Architect', value: 'architect' },
  { label: 'Artist', value: 'artist' },
  { label: 'Attorney', value: 'attorney' },
  { label: 'Auditor', value: 'auditor' },
  { label: 'Baker', value: 'baker' },
  { label: 'Banker', value: 'banker' },
  { label: 'Barista', value: 'barista' },
  { label: 'Bartender', value: 'bartender' },
  { label: 'Biologist', value: 'biologist' },
  { label: 'Bookkeeper', value: 'bookkeeper' },
  { label: 'Carpenter', value: 'carpenter' },
  { label: 'Cashier', value: 'cashier' },
  { label: 'Chef', value: 'chef' },
  { label: 'Chemist', value: 'chemist' },
  { label: 'Chiropractor', value: 'chiropractor' },
  { label: 'Civil Engineer', value: 'civil_engineer' },
  { label: 'Coach', value: 'coach' },
  { label: 'Computer Programmer', value: 'computer_programmer' },
  { label: 'Construction Worker', value: 'construction_worker' },
  { label: 'Consultant', value: 'consultant' },
  { label: 'Content Writer', value: 'content_writer' },
  { label: 'Copywriter', value: 'copywriter' },
  { label: 'Customer Service Representative', value: 'customer_service_representative' },
  { label: 'Data Analyst', value: 'data_analyst' },
  { label: 'Data Scientist', value: 'data_scientist' },
  { label: 'Dental Assistant', value: 'dental_assistant' },
  { label: 'Dentist', value: 'dentist' },
  { label: 'Dietitian', value: 'dietitian' },
  { label: 'Doctor', value: 'doctor' },
  { label: 'Editor', value: 'editor' },
  { label: 'Electrician', value: 'electrician' },
  { label: 'Engineer', value: 'engineer' },
  { label: 'Entrepreneur', value: 'entrepreneur' },
  { label: 'Event Planner', value: 'event_planner' },
  { label: 'Fashion Designer', value: 'fashion_designer' },
  { label: 'Family Business', value: 'family_business' },
  { label: 'Financial Analyst', value: 'financial_analyst' },
  { label: 'Firefighter', value: 'firefighter' },
  { label: 'Flight Attendant', value: 'flight_attendant' },
  { label: 'Graphic Designer', value: 'graphic_designer' },
  { label: 'Hair Stylist', value: 'hair_stylist' },
  { label: 'Health and Safety Officer', value: 'health_and_safety_officer' },
  { label: 'Hotel Manager', value: 'hotel_manager' },
  { label: 'Human Resources Manager', value: 'human_resources_manager' },
  { label: 'Information Security Analyst', value: 'information_security_analyst' },
  { label: 'Insurance Agent', value: 'insurance_agent' },
  { label: 'Interpreter', value: 'interpreter' },
  { label: 'IT Specialist', value: 'it_specialist' },
  { label: 'Janitor', value: 'janitor' },
  { label: 'Journalist', value: 'journalist' },
  { label: 'Judge', value: 'judge' },
  { label: 'Kindergarten Teacher', value: 'kindergarten_teacher' },
  { label: 'Lawyer', value: 'lawyer' },
  { label: 'Librarian', value: 'librarian' },
  { label: 'Machine Operator', value: 'machine_operator' },
  { label: 'Marketing Manager', value: 'marketing_manager' },
  { label: 'Massage Therapist', value: 'massage_therapist' },
  { label: 'Mechanic', value: 'mechanic' },
  { label: 'Medical Assistant', value: 'medical_assistant' },
  { label: 'Musician', value: 'musician' },
  { label: 'Nurse', value: 'nurse' },
  { label: 'Nutritionist', value: 'nutritionist' },
  { label: 'Occupational Therapist', value: 'occupational_therapist' },
  { label: 'Optician', value: 'optician' },
  { label: 'Painter', value: 'painter' },
  { label: 'Paralegal', value: 'paralegal' },
  { label: 'Pharmacist', value: 'pharmacist' },
  { label: 'Photographer', value: 'photographer' },
  { label: 'Physical Therapist', value: 'physical_therapist' },
  { label: 'Physician', value: 'physician' },
  { label: 'Plumber', value: 'plumber' },
  { label: 'Police Officer', value: 'police_officer' },
  { label: 'Politician', value: 'politician' },
  { label: 'Professor', value: 'professor' },
  { label: 'Project Manager', value: 'project_manager' },
  { label: 'Psychologist', value: 'psychologist' },
  { label: 'Public Relations Specialist', value: 'public_relations_specialist' },
  { label: 'Radiologic Technologist', value: 'radiologic_technologist' },
  { label: 'Real Estate Agent', value: 'real_estate_agent' },
  { label: 'Receptionist', value: 'receptionist' },
  { label: 'Recruiter', value: 'recruiter' },
  { label: 'Research Scientist', value: 'research_scientist' },
  { label: 'Restaurant Manager', value: 'restaurant_manager' },
  { label: 'Sales Manager', value: 'sales_manager' },
  { label: 'Sales Representative', value: 'sales_representative' },
  { label: 'Scientist', value: 'scientist' },
  { label: 'Security Guard', value: 'security_guard' },
  { label: 'Social Media Manager', value: 'social_media_manager' },
  { label: 'Software Developer', value: 'software_developer' },
  { label: 'Software Engineer', value: 'software_engineer' },
  { label: 'Surgeon', value: 'surgeon' },
  { label: 'Taxi Driver', value: 'taxi_driver' },
  { label: 'Teacher', value: 'teacher' },
  { label: 'Therapist', value: 'therapist' },
  { label: 'Translator', value: 'translator' },
  { label: 'Truck Driver', value: 'truck_driver' },
  { label: 'UX Designer', value: 'ux_designer' },
  { label: 'Veterinarian', value: 'veterinarian' },
  { label: 'Web Developer', value: 'web_developer' },
  { label: 'Writer', value: 'writer' },

  { label: 'Other', value: 'other' },
]

const travelRequirementsOptions = [
  { label: 'Frequent travel', value: 'Frequent travel' },
  { label: 'Occasional travel', value: 'Occasional travel' },
  { label: 'Minimal travel', value: 'Minimal travel' },
  { label: 'No travel', value: 'noTravel' },
]
const currencysymbolsOptions = [
  { label: '$', value: 'doller' },
  { label: '₹', value: 'indian' },
]
const incomeOptions = [
  { label: 'Below 50K', value: 'Below 50K' },
  { label: '50,000 to 75,000', value: '50,000 to 75,000' },
  { label: '75,000 to 100,000', value: '75,000 to 100,000' },
  { label: '100,000 to 150,000', value: '100,000 to 150,000' },
  { label: '150,000 to 200,000', value: '150,000 to 200,000' },
  { label: '200,000 to 300,000', value: '200,000 to 300,000' },
  { label: '300,000 to 500,000', value: '300,000 to 500,000' },
  { label: 'Over 500,000', value: 'Over 500,000' },
  { label: 'Prefer not to say', value: 'Prefer not to say' },
]

const AnnualIncomeOptions = [
  {
    label: 'Under 1,00,000',
    value: '0',
  },
  {
    label: '1,00,000',
    value: '100000',
  },
  {
    label: '2,00,000',
    value: '200000',
  },
  {
    label: '3,00,000',
    value: '300000',
  },
  {
    label: '4,00,000',
    value: '400000',
  },
  {
    label: '5,00,000',
    value: '500000',
  },
  {
    label: '6,00,000',
    value: '600000',
  },
  {
    label: '8,00,000',
    value: '800000',
  },
  {
    label: '10,00,000',
    value: '1000000',
  },
  {
    label: '12,00,000',
    value: '1200000',
  },
  {
    label: '15,00,000',
    value: '1500000',
  },
  {
    label: '20,00,000',
    value: '2000000',
  },
  {
    label: '25,00,000',
    value: '2500000',
  },
  {
    label: '30,00,000',
    value: '3000000',
  },
  {
    label: '40,00,000',
    value: '4000000',
  },
  {
    label: '50,00,000',
    value: '5000000',
  },
  {
    label: '75,00,000',
    value: '7500000',
  },
  {
    label: '1,00,00,000',
    value: '10000000',
  },
  {
    label: '1,50,00,000',
    value: '15000000',
  },
  {
    label: '2,00,00,000+',
    value: '20000000',
  },
  {
    label: 'Prefer not to say',
    value: 'prefer_not_to_say',
  },
]

const FamilyValuesOptions = [
  {
    label: 'Traditional',
    value: 'traditional',
  },
  {
    label: 'Moderate',
    value: 'moderate',
  },
  {
    label: 'Liberal',
    value: 'liberal',
  },
  {
    label: 'Orthodox',
    value: 'orthodox',
  },
  {
    label: 'Progressive',
    value: 'progressive',
  },
  {
    label: 'Conservative',
    value: 'conservative',
  },
  {
    label: 'Other',
    value: 'other',
  },
]

const FamilyStatusOptions = [
  {
    label: 'Affluent',
    value: 'affluent',
  },
  {
    label: 'Upper Middle Class',
    value: 'upper_middle_class',
  },
  {
    label: 'Middle Class',
    value: 'middle_class',
  },
  {
    label: 'Lower Middle Class',
    value: 'lower_middle_class',
  },
  {
    label: 'Working Class',
    value: 'working_class',
  },
]

const FamilyTypeOptions = [
  {
    label: 'Joint Family',
    value: 'joint_family',
  },
  {
    label: 'Nuclear Family',
    value: 'nuclear_family',
  },
  {
    label: 'Extended Family',
    value: 'extended_family',
  },
  {
    label: 'Single Parent Family',
    value: 'single_parent_family',
  },
]

const FamilyIndianIncomeOptions = [
  {
    label: 'Under 5,00,000',
    value: '500000',
  },
  {
    label: '5,00,000 to 10,00,000',
    value: '500000-1000000',
  },
  {
    label: '10,00,000 to 25,00,000',
    value: '1000000-2500000',
  },
  {
    label: '25,00,000 to 50,00,000',
    value: '2500000-5000000',
  },
  {
    label: '50,00,000 to 75,00,000',
    value: '5000000-7500000',
  },
  {
    label: '75,00,000 to 1,00,00,000',
    value: '7500000-10000000',
  },
  {
    label: '1,00,00,000 to 2,50,00,000',
    value: '10000000-25000000',
  },
  {
    label: '2,50,00,000 to 5,00,00,000',
    value: '25000000-50000000',
  },
  {
    label: '5,00,00,000 to 10,00,00,000',
    value: '50000000-100000000',
  },
  {
    label: '10,00,00,000 to 25,00,00,000',
    value: '100000000-250000000',
  },
  {
    label: '25,00,00,000 to 50,00,00,000',
    value: '250000000-500000000',
  },
  {
    label: '50,00,00,000 to 75,00,00,000',
    value: '500000000-750000000',
  },
  {
    label: '75,00,00,000 to 100,00,00,000',
    value: '750000000-1000000000',
  },
  {
    label: '100,00,00,000 to 250,00,00,000',
    value: '1000000000-2500000000',
  },
  {
    label: '250,00,00,000 to 500,00,00,000',
    value: '2500000000-5000000000',
  },
  {
    label: '500,00,00,000 to 750,00,00,000',
    value: '5000000000-7500000000',
  },
  {
    label: '750,00,00,000 to 1000,00,00,000',
    value: '7500000000-10000000000',
  },
  {
    label: 'Above 1000,00,00,000',
    value: '10000000000+',
  },
  {
    label: 'Prefer not to say',
    value: 'prefer_not_to_say',
  },
]
const FamilyNonIndianIncomeOptions = [
  {
    label: 'Under 25,000',
    value: '25000',
  },
  {
    label: '25,000 to 50,000',
    value: '25000-50000',
  },
  {
    label: '50,000 to 75,000',
    value: '50000-75000',
  },
  {
    label: '75,000 to 100,000',
    value: '75000-100000',
  },
  {
    label: '100,000 to 150,000',
    value: '100000-150000',
  },
  {
    label: '150,000 to 200,000',
    value: '150000-200000',
  },
  {
    label: '200,000 to 300,000',
    value: '200000-300000',
  },
  {
    label: '300,000 to 500,000',
    value: '300000-500000',
  },
  {
    label: '500,000 to 1,000,000',
    value: '500000-1000000',
  },
  {
    label: '1,000,000 to 2,000,000',
    value: '1000000-2000000',
  },
  {
    label: '2,000,000 to 5,000,000',
    value: '2000000-5000000',
  },
  {
    label: '5,000,000 to 10,000,000',
    value: '5000000-10000000',
  },
  {
    label: '10,000,000 to 50,000,000',
    value: '10000000-50000000',
  },
  {
    label: '50,000,000 to 100,000,000',
    value: '50000000-100000000',
  },
  {
    label: 'Above 100,000,000',
    value: '100000000+',
  },
  {
    label: 'Prefer not to say',
    value: 'prefer_not_to_say',
  },
]

const FamilyWeddingBudgetOptions = [
  {
    label: 'Under 100,000',
    value: 'Under 100000',
  },
  {
    label: '100,000 to 500,000',
    value: '100000 to 500000',
  },
  {
    label: '500,000 to 1,000,000',
    value: '500000 to 1000000',
  },
  {
    label: '1,000,000 to 2,500,000',
    value: '1000000 to 2500000',
  },
  {
    label: '2,500,000 to 5,000,000',
    value: '2500000 to 5000000',
  },
  {
    label: '5,000,000 to 10,000,000',
    value: '5000000 to 10000000',
  },
  {
    label: '10,000,000 to 50,000,000',
    value: '10000000 to 50000000',
  },
  {
    label: 'Above 50,000,000',
    value: 'Above 50000000',
  },
  {
    label: 'Prefer not to say',
    value: 'Prefer not to say',
  },
]

const generateCountingOptions = (count) => {
  return Array.from({ length: count }, (_, index) => ({
    label: (index + 1).toString(),
    value: (index + 1).toString(),
  }))
}

const presenceChildrenOptions = generateCountingOptions(10)

const partnerPreferences = {
  maritalStatusPreferences: {
    label: 'Marital Status Preferences',
    options: [
      { value: 'single', label: 'Single' },
      { value: 'divorced', label: 'Divorced' },
      { value: 'widowed', label: 'Widowed' },
    ],
  },

  occupation: {
    label: 'Occupation',
    options: [
      { value: 'engineer', label: 'Engineer' },
      { value: 'doctor', label: 'Doctor' },
      { value: 'teacher', label: 'Teacher' },
      { value: 'business', label: 'Business' },
      { value: 'other', label: 'Other' },
    ],
  },
  lifestylePreferences: {
    label: 'Lifestyle Preferences',
    options: [
      { value: 'vegetarian', label: 'Vegetarian' },
      { value: 'nonVegetarian', label: 'Non-Vegetarian' },
      { value: 'smoker', label: 'Smoker' },
      { value: 'nonSmoker', label: 'Non-Smoker' },
      { value: 'drinker', label: 'Drinker' },
      { value: 'nonDrinker', label: 'Non-Drinker' },
    ],
  },
  religiousPreferences: {
    label: 'Religious Preferences',
    options: [
      { value: 'hindu', label: 'Hindu' },
      { value: 'muslim', label: 'Muslim' },
      { value: 'christian', label: 'Christian' },
      { value: 'sikh', label: 'Sikh' },
      { value: 'jain', label: 'Jain' },
      { value: 'buddhist', label: 'Buddhist' },
      { value: 'other', label: 'Other' },
    ],
  },
  educationLevel: {
    label: 'Education Level',
    options: [
      { value: 'highSchool', label: 'High School' },
      { value: 'bachelors', label: 'Bachelors' },
      { value: 'masters', label: 'Masters' },
      { value: 'phd', label: 'PhD' },
    ],
  },
  willingnessToRelocate: {
    label: 'Willingness to Relocate',
    options: [
      { value: 'yes', label: 'Yes' },
      { value: 'no', label: 'No' },
    ],
  },
  preferredCountriesForPartner: {
    label: 'Preferred Countries for Partner',
    options: [
      { value: 'usa', label: 'USA' },
      { value: 'canada', label: 'Canada' },
      { value: 'uk', label: 'UK' },
      { value: 'australia', label: 'Australia' },
      { value: 'india', label: 'India' },
    ],
  },
  preferredStatesCitiesForPartner: {
    label: 'Preferred States/Cities for Partner',
    options: [
      { value: 'california', label: 'California' },
      { value: 'newYork', label: 'New York' },
      { value: 'texas', label: 'Texas' },
      { value: 'mumbai', label: 'Mumbai' },
      { value: 'delhi', label: 'Delhi' },
    ],
  },
  interestInJointFamilySetup: {
    label: 'Interest in Joint Family Set-Up',
    options: [
      { value: 'yes', label: 'Yes' },
      { value: 'no', label: 'No' },
    ],
  },
  willingnessToMarryOutsideCasteCommunity: {
    label: 'Willingness to Marry Outside Caste/Community',
    options: [
      { value: 'yes', label: 'Yes' },
      { value: 'no', label: 'No' },
    ],
  },

  preferHomemaker: {
    label: 'Prefer Homemaker',
    options: [
      { value: 'yes', label: 'Yes' },
      { value: 'no', label: 'No' },
    ],
  },
}

const additionalInformation = {
  aboutMe: {
    label: 'About Me',
    type: 'text',
  },
  linkedin: {
    label: 'LinkedIn',
    type: 'url',
  },
  instagram: {
    label: 'Instagram',
    type: 'url',
  },
  description: {
    label: 'Description',
    type: 'text',
  },
  additionalMobileNo: {
    label: 'Additional Mobile No',
    type: 'tel',
  },
  additionalQuestions: {
    label: 'Additional Questions',
    type: 'text',
  },
  uploadDocument: {
    label: 'Upload Document',
    type: 'file',
  },
  dragDropPhoto: {
    label: 'Drag & Drop Photo',
    type: 'file',
  },
}

const ultraRichPreferences = {
  financialTransparency: {
    label: 'Financial Transparency',
    options: [
      { value: 'fullDisclosure', label: 'Open to full financial disclosure' },
      { value: 'partialDisclosure', label: 'Prefer partial disclosure' },
      { value: 'private', label: 'Financial details remain private' },
    ],
  },
  partnerFinancialStatus: {
    label: "Expectations of a Partner's Financial Status",
    options: [
      { value: 'similarStatus', label: 'Similar financial status required' },
      { value: 'notPriority', label: 'Financial status is not a priority' },
      { value: 'financiallyIndependent', label: 'Prefer a partner who is financially independent' },
    ],
  },
  lifestylePreferences: {
    label: 'Lifestyle Preferences',
    options: [
      { value: 'luxurious', label: 'Luxurious' },
      { value: 'moderateLuxury', label: 'Moderate luxury' },
      { value: 'simplicity', label: 'Simplicity preferred' },
    ],
  },
  privacyConcerns: {
    label: 'Privacy Concerns',
    options: [
      { value: 'extremelyPrivate', label: 'Extremely private' },
      { value: 'moderatelyPrivate', label: 'Moderately private' },
      { value: 'publicAppearances', label: 'Open to public appearances' },
    ],
  },
  philanthropicInterests: {
    label: 'Philanthropic Interests',
    options: [
      { value: 'activelyInvolved', label: 'Actively involved in philanthropy' },
      { value: 'occasionallyInvolved', label: 'Occasionally involved' },
      { value: 'notInvolvedInterested', label: 'Not involved but interested' },
      { value: 'notInterested', label: 'Not interested in philanthropy' },
    ],
  },
}

const hobbies = [
  { value: 'reading', label: 'Reading' },
  { value: 'writing', label: 'Writing' },
  { value: 'cooking', label: 'Cooking' },
  {
    value: 'traveling',
    label: 'Traveling',
  },
  {
    value: 'gardening',
    label: 'Gardening',
  },
  { value: 'photography', label: 'Photography' },
  {
    value: 'drawing-painting',
    label: 'Drawing and Painting',
  },
  { value: 'music', label: 'Music' },
  { value: 'dancing', label: 'Dancing' },
  {
    value: 'sports-fitness',
    label: 'Sports and Fitness',
  },
  {
    value: 'crafting',
    label: 'Crafting',
  },
  { value: 'collecting', label: 'Collecting' },
  {
    value: 'volunteering',
    label: 'Volunteering',
  },
  {
    value: 'yoga-meditation',
    label: 'Yoga and Meditation',
  },
  { value: 'watching-movies', label: 'Watching Movies' },
  { value: 'gaming', label: 'Gaming' },
  {
    value: 'technology-enthusiast',
    label: 'Technology Enthusiast',
  },
  { value: 'animal-care', label: 'Animal Care' },
  {
    value: 'diy-projects',
    label: 'DIY Projects',
  },
  {
    value: 'social-networking',
    label: 'Social Networking',
  },
]

const dietHabits = [
  {
    label: 'Vegetarian',
    value: 'Vegetarian',
  },
  {
    label: 'Non Vegetarian',
    value: 'Non_Vegetarian',
  },
  {
    label: 'Jain',
    value: 'Jain',
  },
  {
    label: 'Eggetarian',
    value: 'Eggetarian',
  },
]
const smokinAndDrinkingOption = [
  { value: 'no', label: 'No' },
  { value: 'ocassionally', label: 'Ocassionally' },
  { value: 'socially', label: 'Socially' },
]

const eatingHabits = [
  { value: 'vegetarian', label: 'Vegetarian' },
  {
    value: 'non-vegetarian',
    label: 'Non-Vegetarian',
  },
  { value: 'vegan', label: 'Vegan' },
  { value: 'eggetarian', label: 'Eggetarian' },
  {
    value: 'jain',
    label: 'Jain',
  },
]

const drinkingHabits = [
  { value: 'non-drinker', label: 'Non-Drinker' },
  {
    value: 'social-drinker',
    label: 'Social Drinker',
  },
  { value: 'regular-drinker', label: 'Regular Drinker' },
]

const smokingHabits = [
  { value: 'non-smoker', label: 'Non-Smoker' },
  { value: 'occasional-smoker', label: 'Occasional Smoker' },
  { value: 'regular-smoker', label: 'Regular Smoker' },
]

const countryofResidenceOptions = [
  { value: 'india', label: 'India' },
  { value: 'usa', label: 'USA' },
  { value: 'uk', label: 'UK' },
  { value: 'canada', label: 'Canada' },
  { value: 'australia', label: 'Australia' },
  { value: 'uae', label: 'UAE' },
  { value: 'newZealand', label: 'New Zealand' },
  { value: 'singapore', label: 'Singapore' },
  { value: 'germany', label: 'Germany' },
  { value: 'france', label: 'France' },
  { value: 'switzerland', label: 'Switzerland' },
  { value: 'netherlands', label: 'Netherlands' },
  { value: 'southAfrica', label: 'South Africa' },
  { value: 'ireland', label: 'Ireland' },
  { value: 'other', label: 'Other' },
]

const additionalInvestments = [
  { value: 'stocks', label: 'Stocks' },
  { value: 'bonds', label: 'Bonds' },
  { value: 'mutualFunds', label: 'Mutual Funds' },
  { value: 'realEstate', label: 'Real Estate' },
  { value: 'gold', label: 'Gold' },
  { value: 'other', label: 'Other' },
]

const cityOfResidenceOptions = [
  { value: 'mumbai', label: 'Mumbai' },
  { value: 'delhi', label: 'Delhi' },
  { value: 'bangalore', label: 'Bangalore' },
  { value: 'hyderabad', label: 'Hyderabad' },
  { value: 'pune', label: 'Pune' },
  { value: 'chennai', label: 'Chennai' },
  { value: 'kolkata', label: 'Kolkata' },
  { value: 'ahmedabad', label: 'Ahmedabad' },
  { value: 'jaipur', label: 'Jaipur' },
  { value: 'surat', label: 'Surat' },
  { value: 'lucknow', label: 'Lucknow' },
  { value: 'kanpur', label: 'Kanpur' },
  { value: 'nagpur', label: 'Nagpur' },
  { value: 'patna', label: 'Patna' },
  { value: 'indore', label: 'Indore' },
  { value: 'thane', label: 'Thane' },
  { value: 'bhopal', label: 'Bhopal' },
  { value: 'visakhapatnam', label: 'Visakhapatnam' },
  { value: 'vadodara', label: 'Vadodara' },
  { value: 'firozabad', label: 'Firozabad' },
  { value: 'ludhiana', label: 'Ludhiana' },
  { value: 'rajkot', label: 'Rajkot' },
  { value: 'agra', label: 'Agra' },
  { value: 'nashik', label: 'Nashik' },
  { value: 'faridabad', label: 'Faridabad' },
  { value: 'meerut', label: 'Meerut' },
]

const stateOptions = [
  { value: 'andamanAndNicobar', label: 'Andaman and Nicobar' },
  { value: 'andhraPradesh', label: 'Andhra Pradesh' },
  { value: 'arunachalPradesh', label: 'Arunachal Pradesh' },
  { value: 'assam', label: 'Assam' },
  { value: 'bihar', label: 'Bihar' },
  { value: 'chandigarh', label: 'Chandigarh' },
  { value: 'chhattisgarh', label: 'Chhattisgarh' },
  { value: 'dadraAndNagarHaveli', label: 'Dadra and Nagar Haveli' },
  { value: 'damanAndDiu', label: 'Daman and Diu' },
  { value: 'delhi', label: 'Delhi' },
  { value: 'goa', label: 'Goa' },
  { value: 'gujarat', label: 'Gujarat' },
  { value: 'haryana', label: 'Haryana' },
  { value: 'himachalPradesh', label: 'Himachal Pradesh' },
  { value: 'jammuAndKashmir', label: 'Jammu and Kashmir' },
  { value: 'jharkhand', label: 'Jharkhand' },
  { value: 'karnataka', label: 'Karnataka' },
  { value: 'kerala', label: 'Kerala' },
  { value: 'lakshadweep', label: 'Lakshadweep' },
  { value: 'madhyaPradesh', label: 'Madhya Pradesh' },
  { value: 'maharashtra', label: 'Maharashtra' },
  { value: 'manipur', label: 'Manipur' },
  { value: 'meghalaya', label: 'Meghalaya' },
  { value: 'mizoram', label: 'Mizoram' },
  { value: 'nagaland', label: 'Nagaland' },
  { value: 'orissa', label: 'Orissa' },
  { value: 'pondicherry', label: 'Pondicherry' },
  { value: 'punjab', label: 'Punjab' },
  { value: 'rajasthan', label: 'Rajasthan' },
]

const ancestralOrigin = [
  { value: 'andamanAndNicobar', label: 'Andaman and Nicobar' },
  { value: 'andhraPradesh', label: 'Andhra Pradesh' },
  { value: 'arunachalPradesh', label: 'Arunachal Pradesh' },
  { value: 'assam', label: 'Assam' },
  { value: 'bihar', label: 'Bihar' },
  { value: 'chandigarh', label: 'Chandigarh' },
  { value: 'chhattisgarh', label: 'Chhattisgarh' },
  { value: 'dadraAndNagarHaveli', label: 'Dadra and Nagar Haveli' },
  { value: 'damanAndDiu', label: 'Daman and Diu' },
  { value: 'delhi', label: 'Delhi' },
  { value: 'goa', label: 'Goa' },
  { value: 'gujarat', label: 'Gujarat' },
  { value: 'haryana', label: 'Haryana' },
  { value: 'himachalPradesh', label: 'Himachal Pradesh' },
  { value: 'jammuAndKashmir', label: 'Jammu and Kashmir' },
  { value: 'jharkhand', label: 'Jharkhand' },
  { value: 'karnataka', label: 'Karnataka' },
  { value: 'kerala', label: 'Kerala' },
  { value: 'lakshadweep', label: 'Lakshadweep' },
  { value: 'madhyaPradesh', label: 'Madhya Pradesh' },
  { value: 'maharashtra', label: 'Maharashtra' },
  { value: 'manipur', label: 'Manipur' },
  { value: 'meghalaya', label: 'Meghalaya' },
  { value: 'mizoram', label: 'Mizoram' },
  { value: 'nagaland', label: 'Nagaland' },
  { value: 'orissa', label: 'Orissa' },
  { value: 'pondicherry', label: 'Pondicherry' },
  { value: 'punjab', label: 'Punjab' },
  { value: 'rajasthan', label: 'Rajasthan' },
]

const fatherOccupation = [
  { value: 'business', label: 'Business' },
  { value: 'service', label: 'Service' },
  { value: 'retired', label: 'Retired' },
  { value: 'notWorking', label: 'Not Working' },
  { value: 'demise', label: 'Demise' },
  { value: 'other', label: 'Other' },
]
const motherOccupation = [
  { value: 'business', label: 'Business' },
  { value: 'service', label: 'Service' },
  { value: 'retired', label: 'Retired' },
  { value: 'notWorking', label: 'Not Working' },
  { value: 'demise', label: 'Demise' },
  { value: 'housewife', label: 'Housewife' },
  { value: 'other', label: 'Other' },
]

const workoutHabits = [
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'never', label: 'Never' },
]

const cookingKnowledge = [
  { value: 'expert', label: 'Expert' },
  { value: 'good', label: 'Good' },
  { value: 'average', label: 'Average' },
  { value: 'poor', label: 'Poor' },
  { value: 'never', label: 'Never' },
]

const travelExperience = [
  { value: 'expert', label: 'Expert' },
  { value: 'good', label: 'Good' },
  { value: 'average', label: 'Average' },
  { value: 'poor', label: 'Poor' },
  { value: 'never', label: 'Never' },
]

const socialBehaviour = [
  { value: 'introvert', label: 'Introvert' },
  { value: 'extrovert', label: 'Extrovert' },
  { value: 'ambivert', label: 'Ambivert' },
]

const partnerProfessionalStatusPrefrence = [
  { value: 'business', label: 'Business' },
  { value: 'service', label: 'Service' },
  { value: 'retired', label: 'Retired' },
  { value: 'notWorking', label: 'Not Working' },
  { value: 'other', label: 'Other' },
]

const muslimCasteOptions = [
  { value: 'syed', label: 'Syed' },
  { value: 'sheikh', label: 'Sheikh' },
  { value: 'pathan', label: 'Pathan' },
  { value: 'mughal', label: 'Mughal' },
  { value: 'qureshi', label: 'Qureshi' },
  { value: 'ansari', label: 'Ansari' },
  { value: 'mansoori', label: 'Mansoori' },
  { value: 'arain', label: 'Arain' },
  { value: 'rajput', label: 'Rajput' },
  { value: 'gujjar', label: 'Gujjar' },
  { value: 'kashmiri', label: 'Kashmiri' },
]
const hideFilterOptions = [
  { value: 'blacklisted', label: 'Blacklisted' },
  { value: 'collaborator', label: 'Collaborator' },
  { value: 'inactive', label: 'Inactive Profile' },
  { value: 'hold', label: 'Hold' },
  { value: 'isActiveMM', label: 'Inactive MM' },
]
const ProfilehideFilterOptions = [
  { value: 'inactive', label: 'Inactive Profile' },
  { value: 'hold', label: 'Hold' },
  { value: 'isActiveMM', label: 'Inactive MM' },
]

// Hindu select options
const hinduCastesoptions = [
  { value: '96K Kokanastha', label: '96K Kokanastha' },
  { value: 'Adi Andhra', label: 'Adi Andhra' },
  { value: 'Adi Dharmi', label: 'Adi Dharmi' },
  { value: 'Adi Dravida', label: 'Adi Dravida' },
  { value: 'Adi Karnataka', label: 'Adi Karnataka' },
  { value: 'Agamudayar', label: 'Agamudayar' },
  { value: 'Aggarwal', label: 'Aggarwal' },
  { value: 'Agnikula Kshatriya', label: 'Agnikula Kshatriya' },
  { value: 'Agri', label: 'Agri' },
  { value: 'Ahir', label: 'Ahir' },
  { value: 'Ahom', label: 'Ahom' },
  { value: 'Ambalavasi', label: 'Ambalavasi' },
  { value: 'Arcot', label: 'Arcot' },
  { value: 'Arekatica', label: 'Arekatica' },
  { value: 'Arora', label: 'Arora' },
  { value: 'Arunthathiyar', label: 'Arunthathiyar' },
  { value: 'Arya Vysya', label: 'Arya Vysya' },
  { value: 'Aryasamaj', label: 'Aryasamaj' },
  { value: 'Ayyaraka', label: 'Ayyaraka' },
  { value: 'Badaga', label: 'Badaga' },
  { value: 'Baghel/Pal/Gaderiya', label: 'Baghel/Pal/Gaderiya' },
  { value: 'Bahi', label: 'Bahi' },
  { value: 'Baidya', label: 'Baidya' },
  { value: 'Baishnab', label: 'Baishnab' },
  { value: 'Baishya', label: 'Baishya' },
  { value: 'Bajantri', label: 'Bajantri' },
  { value: 'Balija', label: 'Balija' },
  { value: 'Balija - Naidu', label: 'Balija - Naidu' },
  { value: 'Banayat Oriya', label: 'Banayat Oriya' },
  { value: 'Banik', label: 'Banik' },
  { value: 'Bania', label: 'Baniya' },
  { value: 'Barai', label: 'Barai' },
  { value: 'Bari', label: 'Bari' },
  { value: 'Barnwal', label: 'Barnwal' },
  { value: 'Barujibi', label: 'Barujibi' },
  { value: 'Bengali', label: 'Bengali' },
  { value: 'Besta', label: 'Besta' },
  { value: 'Bhandari', label: 'Bhandari' },
  { value: 'Bhatia', label: 'Bhatia' },
  { value: 'Bhatraju', label: 'Bhatraju' },
  { value: 'Bhavsar', label: 'Bhavsar' },
  { value: 'Bhovi', label: 'Bhovi' },
  { value: 'Billava', label: 'Billava' },
  { value: 'Boya/Nayak/Naik', label: 'Boya/Nayak/Naik' },
  { value: 'Boyer', label: 'Boyer' },
  { value: 'Brahmbatt', label: 'Brahmbatt' },
  { value: 'Brahmin', label: 'Brahmin' },
  { value: 'Brahmin - Anavil', label: 'Brahmin - Anavil' },
  { value: 'Brahmin - Audichya', label: 'Brahmin - Audichya' },
  { value: 'Brahmin - Barendra', label: 'Brahmin - Barendra' },
  { value: 'Brahmin - Bengali', label: 'Brahmin - Bengali' },
  { value: 'Brahmin - Bhatt', label: 'Brahmin - Bhatt' },
  { value: 'Brahmin - Bhumihar', label: 'Brahmin - Bhumihar' },
  { value: 'Brahmin - Brahmbhatt', label: 'Brahmin - Brahmbhatt' },
  { value: 'Brahmin - Dadhich/Dadheech', label: 'Brahmin - Dadhich/Dadheech' },
  { value: 'Brahmin - Daivadnya', label: 'Brahmin - Daivadnya' },
  { value: 'Brahmin - Danua', label: 'Brahmin - Danua' },
  { value: 'Brahmin - Deshastha', label: 'Brahmin - Deshastha' },
  { value: 'Brahmin - Dhiman', label: 'Brahmin - Dhiman' },
  { value: 'Brahmin - Dravida', label: 'Brahmin - Dravida' },
  { value: 'Brahmin - Embrandiri', label: 'Brahmin - Embrandiri' },
  { value: 'Brahmin - Garhwali', label: 'Brahmin - Garhwali' },
  { value: 'Brahmin - Goswami', label: 'Brahmin - Goswami' },
  { value: 'Brahmin - Gour', label: 'Brahmin - Gour' },
  { value: 'Brahmin - Gowd Saraswat', label: 'Brahmin - Gowd Saraswat' },
  { value: 'Brahmin - Gujar Gour', label: 'Brahmin - Gujar Gour' },
  { value: 'Brahmin - Gurukkal', label: 'Brahmin - Gurukkal' },
  { value: 'Brahmin - Halua', label: 'Brahmin - Halua' },
  { value: 'Brahmin - Havyaka', label: 'Brahmin - Havyaka' },
  { value: 'Brahmin - Himachali', label: 'Brahmin - Himachali' },
  { value: 'Brahmin - Hoysala', label: 'Brahmin - Hoysala' },
  { value: 'Brahmin - Iyengar', label: 'Brahmin - Iyengar' },
  { value: 'Brahmin - Iyer', label: 'Brahmin - Iyer' },
  { value: 'Brahmin - Jangid', label: 'Brahmin - Jangid' },
  { value: 'Brahmin - Jhadua', label: 'Brahmin - Jhadua' },
  { value: 'Brahmin - Jhijhotiya', label: 'Brahmin - Jhijhotiya' },
  { value: 'Brahmin - Kannada Madhva', label: 'Brahmin - Kannada Madhva' },
  { value: 'Brahmin - Kanyakubja', label: 'Brahmin - Kanyakubja' },
  { value: 'Brahmin - Karhade', label: 'Brahmin - Karhade' },
  { value: 'Brahmin - Kashmiri Pandit', label: 'Brahmin - Kashmiri Pandit' },
  { value: 'Brahmin - Kokanastha', label: 'Brahmin - Kokanastha' },
  { value: 'Brahmin - Kota', label: 'Brahmin - Kota' },
  { value: 'Brahmin - Kulin', label: 'Brahmin - Kulin' },
  { value: 'Brahmin - Kumaoni', label: 'Brahmin - Kumaoni' },
  { value: 'Brahmin - Madhwa', label: 'Brahmin - Madhwa' },
  { value: 'Brahmin - Maharashtrian', label: 'Brahmin - Maharashtrian' },
  { value: 'Brahmin - Maithili', label: 'Brahmin - Maithili' },
  { value: 'Brahmin - Modh', label: 'Brahmin - Modh' },
  { value: 'Brahmin - Mohyal', label: 'Brahmin - Mohyal' },
  { value: 'Brahmin - Nagar', label: 'Brahmin - Nagar' },
  { value: 'Brahmin - Namboodiri', label: 'Brahmin - Namboodiri' },
  { value: 'Brahmin - Niyogi', label: 'Brahmin - Niyogi' },
  { value: 'Brahmin - Niyogi Nandavariki', label: 'Brahmin - Niyogi Nandavariki' },
  { value: 'Brahmin - Other', label: 'Brahmin - Other' },
  { value: 'Brahmin - Paliwal', label: 'Brahmin - Paliwal' },
  { value: 'Brahmin - Panda', label: 'Brahmin - Panda' },
  { value: 'Brahmin - Pareek', label: 'Brahmin - Pareek' },
  { value: 'Brahmin - Pushkarna', label: 'Brahmin - Pushkarna' },
  { value: 'Brahmin - Rarhi', label: 'Brahmin - Rarhi' },
  { value: 'Brahmin - Rigvedi', label: 'Brahmin - Rigvedi' },
  { value: 'Brahmin - Rudraj', label: 'Brahmin - Rudraj' },
  { value: 'Brahmin - Sakaldwipi', label: 'Brahmin - Sakaldwipi' },
  { value: 'Brahmin - Sanadya', label: 'Brahmin - Sanadya' },
  { value: 'Brahmin - Saraswat', label: 'Brahmin - Saraswat' },
  { value: 'Brahmin - Saryuparin', label: 'Brahmin - Saryuparin' },
  { value: 'Brahmin - Shivhalli', label: 'Brahmin - Shivhalli' },
  { value: 'Brahmin - Shrimali', label: 'Brahmin - Shrimali' },
  { value: 'Brahmin - Smartha', label: 'Brahmin - Smartha' },
  { value: 'Brahmin - Sri Vaishnava', label: 'Brahmin - Sri Vaishnava' },
  { value: 'Brahmin - Stanika', label: 'Brahmin - Stanika' },
  { value: 'Brahmin - Tapodhan', label: 'Brahmin - Tapodhan' },
  { value: 'Brahmin - Tyagi', label: 'Brahmin - Tyagi' },
  { value: 'Brahmin - Velanadu', label: 'Brahmin - Velanadu' },
  { value: 'Brahmin - Viswabrahmin', label: 'Brahmin - Viswabrahmin' },
  { value: 'Brahmin - Vyas', label: 'Brahmin - Vyas' },
  { value: 'Brahmo', label: 'Brahmo' },
  { value: 'Buddar', label: 'Buddar' },
  { value: 'Bunt (Shetty)', label: 'Bunt (Shetty)' },
  { value: 'CKP', label: 'CKP' },
  { value: 'Chalawadi Holeya', label: 'Chalawadi Holeya' },
  { value: 'Chambhar', label: 'Chambhar' },
  { value: 'Chandravanshi Kahar', label: 'Chandravanshi Kahar' },
  { value: 'Chasa', label: 'Chasa' },
  { value: 'Chattada Sri Vaishnava', label: 'Chattada Sri Vaishnava' },
  { value: 'Chaudary', label: 'Chaudary' },
  { value: 'Chaurasia', label: 'Chaurasia' },
  { value: 'Chekkala - Nair', label: 'Chekkala - Nair' },
  { value: 'Chennadasar', label: 'Chennadasar' },
  { value: 'Cheramar', label: 'Cheramar' },
  { value: 'Chettiar', label: 'Chettiar' },
  { value: 'Chhetri', label: 'Chhetri' },
  { value: 'Chippolu/Mera', label: 'Chippolu/Mera' },
  { value: 'Coorgi', label: 'Coorgi' },
  { value: 'Devadiga', label: 'Devadiga' },
  { value: 'Devanga', label: 'Devanga' },
  { value: 'Devar/Thevar/Mukkulathor', label: 'Devar/Thevar/Mukkulathor' },
  { value: 'Devendra Kula Vellalar', label: 'Devendra Kula Vellalar' },
  { value: 'Dhangar', label: 'Dhangar' },
  { value: 'Dheevara', label: 'Dheevara' },
  { value: 'Dhiman', label: 'Dhiman' },
  { value: 'Dhoba', label: 'Dhoba' },
  { value: 'Dhobi', label: 'Dhobi' },
  { value: 'Digambar', label: 'Digambar' },
  { value: 'Dommala', label: 'Dommala' },
  { value: 'Dusadh', label: 'Dusadh' },
  { value: 'Ediga', label: 'Ediga' },
  { value: 'Ezhava', label: 'Ezhava' },
  { value: 'Ezhuthachan', label: 'Ezhuthachan' },
  { value: 'Gabit', label: 'Gabit' },
  { value: 'Ganakar', label: 'Ganakar' },
  { value: 'Gandla', label: 'Gandla' },
  { value: 'Ganiga', label: 'Ganiga' },
  { value: 'Garhwali', label: 'Garhwali' },
  { value: 'Gatti', label: 'Gatti' },
  { value: 'Gavali', label: 'Gavali' },
  { value: 'Gavara', label: 'Gavara' },
  { value: 'Ghumar', label: 'Ghumar' },
  { value: 'Goala', label: 'Goala' },
  { value: 'Goan', label: 'Goan' },
  { value: 'Goswami', label: 'Goswami' },
  { value: 'Goud', label: 'Goud' },
  { value: 'Gounder', label: 'Gounder' },
  { value: 'Gowda', label: 'Gowda' },
  { value: 'Gramani', label: 'Gramani' },
  { value: 'Gudia', label: 'Gudia' },
  { value: 'Gujarati', label: 'Gujarati' },
  { value: 'Gujjar', label: 'Gujjar' },
  { value: 'Gupta', label: 'Gupta' },
  { value: 'Guptan', label: 'Guptan' },
  { value: 'Gurjar', label: 'Gurjar' },
  { value: 'Halwai', label: 'Halwai' },
  { value: 'Hegde', label: 'Hegde' },
  { value: 'Helava', label: 'Helava' },
  { value: 'Hugar (Jeer)', label: 'Hugar (Jeer)' },
  { value: 'Intercaste', label: 'Intercaste' },
  { value: 'Jaalari', label: 'Jaalari' },
  { value: 'Jaiswal', label: 'Jaiswal' },
  { value: 'Jandra', label: 'Jandra' },
  { value: 'Jangam', label: 'Jangam' },
  { value: 'Jat', label: 'Jat' },
  { value: 'Jatav', label: 'Jatav' },
  { value: 'Jetty Malla', label: 'Jetty Malla' },
  { value: 'Kachara', label: 'Kachara' },
  { value: 'Kaibarta', label: 'Kaibarta' },
  { value: 'Kakkalan', label: 'Kakkalan' },
  { value: 'Kalar', label: 'Kalar' },
  { value: 'Kalinga', label: 'Kalinga' },
  { value: 'Kalinga Vysya', label: 'Kalinga Vysya' },
  { value: 'Kalita', label: 'Kalita' },
  { value: 'Kalwar', label: 'Kalwar' },
  { value: 'Kamboj', label: 'Kamboj' },
  { value: 'Kamma', label: 'Kamma' },
  { value: 'Kamma Naidu', label: 'Kamma Naidu' },
  { value: 'Kammala', label: 'Kammala' },
  { value: 'Kaniyan', label: 'Kaniyan' },
  { value: 'Kannada Mogaveera', label: 'Kannada Mogaveera' },
  { value: 'Kansari', label: 'Kansari' },
  { value: 'Kanu', label: 'Kanu' },
  { value: 'Kapu', label: 'Kapu' },
  { value: 'Kapu Naidu', label: 'Kapu Naidu' },
  { value: 'Karana', label: 'Karana' },
  { value: 'Karmakar', label: 'Karmakar' },
  { value: 'Kartha', label: 'Kartha' },
  { value: 'Karuneegar', label: 'Karuneegar' },
  { value: 'Kasar', label: 'Kasar' },
  { value: 'Kashyap', label: 'Kashyap' },
  { value: 'Kavuthiyya/Ezhavathy', label: 'Kavuthiyya/Ezhavathy' },
  { value: 'Kayastha', label: 'Kayastha' },
  { value: 'Khandayat', label: 'Khandayat' },
  { value: 'Khandelwal', label: 'Khandelwal' },
  { value: 'Kharwar', label: 'Kharwar' },
  { value: 'Khatik', label: 'Khatik' },
  { value: 'Khatri', label: 'Khatri' },
  { value: 'Kirar', label: 'Kirar' },
  { value: 'Koli', label: 'Koli' },
  { value: 'Koli Patel', label: 'Koli Patel' },
  { value: 'Kongu Vellala Gounder', label: 'Kongu Vellala Gounder' },
  { value: 'Konkani', label: 'Konkani' },
  { value: 'Korama', label: 'Korama' },
  { value: 'Kori', label: 'Kori' },
  { value: 'Koshti', label: 'Koshti' },
  { value: 'Krishnavaka', label: 'Krishnavaka' },
  { value: 'Kshatriya', label: 'Kshatriya' },
  { value: 'Kshatriya - Bhavasar', label: 'Kshatriya - Bhavasar' },
  { value: 'Kshatriya/Raju/Varma', label: 'Kshatriya/Raju/Varma' },
  { value: 'Kudumbi', label: 'Kudumbi' },
  { value: 'Kulal', label: 'Kulal' },
  { value: 'Kulalar', label: 'Kulalar' },
  { value: 'Kulita', label: 'Kulita' },
  { value: 'Kumawat', label: 'Kumawat' },
  { value: 'Kumbara', label: 'Kumbara' },
  { value: 'Kumbhakar/Kumbhar', label: 'Kumbhakar/Kumbhar' },
  { value: 'Kumhar', label: 'Kumhar' },
  { value: 'Kummari', label: 'Kummari' },
  { value: 'Kunbi', label: 'Kunbi' },
  { value: 'Kurava', label: 'Kurava' },
  { value: 'Kuravan', label: 'Kuravan' },
  { value: 'Kurmi', label: 'Kurmi' },
  { value: 'Kurmi Kshatriya', label: 'Kurmi Kshatriya' },
  { value: 'Kuruba', label: 'Kuruba' },
  { value: 'Kuruhina Shetty', label: 'Kuruhina Shetty' },
  { value: 'Kurumbar', label: 'Kurumbar' },
  { value: 'Kurup', label: 'Kurup' },
  { value: 'Kushwaha', label: 'Kushwaha' },
  { value: 'Kutchi', label: 'Kutchi' },
  { value: 'Lambadi/Banjara', label: 'Lambadi/Banjara' },
  { value: 'Lambani', label: 'Lambani' },
  { value: 'Leva Patil', label: 'Leva Patil' },
  { value: 'Lingayath', label: 'Lingayath' },
  { value: 'Lohana', label: 'Lohana' },
  { value: 'Lohar', label: 'Lohar' },
  { value: 'Loniya/Lonia/Lunia', label: 'Loniya/Lonia/Lunia' },
  { value: 'Lubana', label: 'Lubana' },
  { value: 'Madhesiya', label: 'Madhesiya' },
  { value: 'Madiga', label: 'Madiga' },
  { value: 'Mahajan', label: 'Mahajan' },
  { value: 'Mahar', label: 'Mahar' },
  { value: 'Maharashtrian', label: 'Maharashtrian' },
  { value: 'Mahendra', label: 'Mahendra' },
  { value: 'Maheshwari', label: 'Maheshwari' },
  { value: 'Mahindra', label: 'Mahindra' },
  { value: 'Mahishya', label: 'Mahishya' },
  { value: 'Majabi', label: 'Majabi' },
  { value: 'Mala', label: 'Mala' },
  { value: 'Malayalee Variar', label: 'Malayalee Variar' },
  { value: 'Mali', label: 'Mali' },
  { value: 'Mallah', label: 'Mallah' },
  { value: 'Mangalorean', label: 'Mangalorean' },
  { value: 'Maniyani', label: 'Maniyani' },
  { value: 'Mannadiar', label: 'Mannadiar' },
  { value: 'Mannan', label: 'Mannan' },
  { value: 'Mapila', label: 'Mapila' },
  { value: 'Marar', label: 'Marar' },
  { value: 'Maratha', label: 'Maratha' },
  { value: 'Maratha - Gomantak', label: 'Maratha - Gomantak' },
  { value: 'Maruthuvar', label: 'Maruthuvar' },
  { value: 'Marvar', label: 'Marvar' },
  { value: 'Marwari', label: 'Marwari' },
  { value: 'Matang', label: 'Matang' },
  { value: 'Maurya', label: 'Maurya' },
  { value: 'Meda', label: 'Meda' },
  { value: 'Medara', label: 'Medara' },
  { value: 'Meena', label: 'Meena' },
  { value: 'Meenavar', label: 'Meenavar' },
  { value: 'Meghwal', label: 'Meghwal' },
  { value: 'Mehra', label: 'Mehra' },
  { value: 'Menon', label: 'Menon' },
  { value: 'Meru Darji', label: 'Meru Darji' },
  { value: 'Modak', label: 'Modak' },
  { value: 'Mogaveera', label: 'Mogaveera' },
  { value: 'Monchi', label: 'Monchi' },
  { value: 'Mudaliar', label: 'Mudaliar' },
  { value: 'Mudaliar - Arcot', label: 'Mudaliar - Arcot' },
  { value: 'Mudaliar - Saiva', label: 'Mudaliar - Saiva' },
  { value: 'Mudaliar - Senguntha', label: 'Mudaliar - Senguntha' },
  { value: 'Mudiraj', label: 'Mudiraj' },
  { value: 'Mukkulathor', label: 'Mukkulathor' },
  { value: 'Muthuraja', label: 'Muthuraja' },
  { value: 'Nadavaru', label: 'Nadavaru' },
  { value: 'Nadar', label: 'Nadar' },
  { value: 'Naidu', label: 'Naidu' },
  { value: 'Nair', label: 'Nair' },
  { value: 'Nair - Vaniya', label: 'Nair - Vaniya' },
  { value: 'Naique', label: 'Naique' },
  { value: 'Namosudra', label: 'Namosudra' },
  { value: 'Napit', label: 'Napit' },
  { value: 'Nayaka', label: 'Nayaka' },
  { value: 'Nepali', label: 'Nepali' },
  { value: 'Nydu', label: 'Nydu' },
  { value: 'Ojha', label: 'Ojha' },
  { value: 'Orathanadu Vellalar', label: 'Orathanadu Vellalar' },
  { value: 'Otari', label: 'Otari' },
  { value: 'Other', label: 'Other' },
  { value: 'Padmasali', label: 'Padmasali' },
  { value: 'Pallan', label: 'Pallan' },
  { value: 'Palta', label: 'Palta' },
  { value: 'Panan', label: 'Panan' },
  { value: 'Panchal', label: 'Panchal' },
  { value: 'Panicker', label: 'Panicker' },
  { value: 'Panta Kapu', label: 'Panta Kapu' },
  { value: 'Paravan', label: 'Paravan' },
  { value: 'Parit', label: 'Parit' },
  { value: 'Parkava Kulama', label: 'Parkava Kulama' },
  { value: 'Pasi', label: 'Pasi' },
  { value: 'Paswan', label: 'Paswan' },
  { value: 'Patel', label: 'Patel' },
  { value: 'Patnaick', label: 'Patnaick' },
  { value: 'Patra', label: 'Patra' },
  { value: 'Pattusali', label: 'Pattusali' },
  { value: 'Perika', label: 'Perika' },
  { value: 'Pisharodi', label: 'Pisharodi' },
  { value: 'Poddar', label: 'Poddar' },
  { value: 'Poosala', label: 'Poosala' },
  { value: 'Prajapati', label: 'Prajapati' },
  { value: 'Pulaya', label: 'Pulaya' },
  { value: 'Rajaka', label: 'Rajaka' },
  { value: 'Rajastani', label: 'Rajastani' },
  { value: 'Rajbhar', label: 'Rajbhar' },
  { value: 'Rajput', label: 'Rajput' },
  { value: 'Ramdasia', label: 'Ramdasia' },
  { value: 'Ramgariah', label: 'Ramgariah' },
  { value: 'Ramoshi', label: 'Ramoshi' },
  { value: 'Raval', label: 'Raval' },
  { value: 'Reddiar', label: 'Reddiar' },
  { value: 'Reddy', label: 'Reddy' },
  { value: 'Rohidas', label: 'Rohidas' },
  { value: 'Roy', label: 'Roy' },
  { value: 'Sadgope', label: 'Sadgope' },
  { value: 'Saha', label: 'Saha' },
  { value: 'Sahu', label: 'Sahu' },
  { value: 'Saiva Velalar', label: 'Saiva Velalar' },
  { value: 'Sakaldwipi', label: 'Sakaldwipi' },
  { value: 'Saliya', label: 'Saliya' },
  { value: 'Saliya Weaver', label: 'Saliya Weaver' },
  { value: 'Saliya Waiver', label: 'Saliya Waiver' },
  { value: 'Samagar', label: 'Samagar' },
  { value: 'Sambava', label: 'Sambava' },
  { value: 'Sathwara', label: 'Sathwara' },
  { value: 'SC', label: 'SC' },
  { value: 'SEO', label: 'SEO' },
  { value: 'Shilpkar', label: 'Shilpkar' },
  { value: 'Sindhi', label: 'Sindhi' },
  { value: 'Sindhi - Baibhand', label: 'Sindhi - Baibhand' },
  { value: 'Sindhi - Bhanusali', label: 'Sindhi - Bhanusali' },
  { value: 'Sindhi - Chhapru', label: 'Sindhi - Chhapru' },
  { value: 'Sindhi - Dar', label: 'Sindhi - Dar' },
  { value: 'Sindhi - Larkana', label: 'Sindhi - Larkana' },
  { value: 'Sindhi - Larai', label: 'Sindhi - Larai' },
  { value: 'Sindhi - Sahiti', label: 'Sindhi - Sahiti' },
  { value: 'Sindhi - Sakkhar', label: 'Sindhi - Sakkhar' },
  { value: 'Sindhi - Sehwani', label: 'Sindhi - Sehwani' },
  { value: 'Sindhi - Shikarpuri', label: 'Sindhi - Shikarpuri' },
  { value: 'Soi', label: 'Soi' },
  { value: 'Sonar', label: 'Sonar' },
  { value: 'Sourashtra', label: 'Sourashtra' },
  { value: 'Sourashtri', label: 'Sourashtri' },
  { value: 'Srisayana', label: 'Srisayana' },
  { value: 'ST', label: 'ST' },
  { value: 'Sutar', label: 'Sutar' },
  { value: 'Tamboli', label: 'Tamboli' },
  { value: 'Tanti', label: 'Tanti' },
  { value: 'Tantuway', label: 'Tantuway' },
  { value: 'Telaga', label: 'Telaga' },
  { value: 'Telugu', label: 'Telugu' },
  { value: 'Thandan', label: 'Thandan' },
  { value: 'Thigala', label: 'Thigala' },
  { value: 'Thiyya', label: 'Thiyya' },
  { value: 'Tirbanda', label: 'Tirbanda' },
  { value: 'Togataveera', label: 'Togataveera' },
  { value: 'Trivandrum', label: 'Trivandrum' },
  { value: 'Udayars', label: 'Udayars' },
  { value: 'Vaal Nattu Vellalar', label: 'Vaal Nattu Vellalar' },
  { value: 'Vaish', label: 'Vaish' },
  { value: 'Vaishnav', label: 'Vaishnav' },
  { value: 'Vaishya', label: 'Vaishya' },
  { value: 'Vanniyar', label: 'Vanniyar' },
  { value: 'Varma', label: 'Varma' },
  { value: 'Vellalar', label: 'Vellalar' },
  { value: 'Vellama', label: 'Vellama' },
  { value: 'Vellalar - Pudukkottai', label: 'Vellalar - Pudukkottai' },
  { value: 'Vellalar - Pattamkatti', label: 'Vellalar - Pattamkatti' },
  { value: 'Vellalar - Veera', label: 'Vellalar - Veera' },
  { value: 'Vellalar - Karkarthar', label: 'Vellalar - Karkarthar' },
  { value: 'Vellalar - Pillai', label: 'Vellalar - Pillai' },
  { value: 'Vellalar - Saiva', label: 'Vellalar - Saiva' },
  { value: 'Vellalar - Tanjore', label: 'Vellalar - Tanjore' },
  { value: 'Vellalar - Vellai', label: 'Vellalar - Vellai' },
  { value: 'Vellalar - Vetrilaikarar', label: 'Vellalar - Vetrilaikarar' },
  { value: 'Vellalar Gounder', label: 'Vellalar Gounder' },
  { value: 'Vellalar Pattam', label: 'Vellalar Pattam' },
  { value: 'Veluthadathu Nair', label: 'Veluthadathu Nair' },
  { value: 'Venugopal', label: 'Venugopal' },
  { value: 'Veshya', label: 'Veshya' },
  { value: 'Viswabrahmin', label: 'Viswabrahmin' },
  { value: 'Vishwakarma', label: 'Vishwakarma' },
  { value: 'Vokkaliga', label: 'Vokkaliga' },
  { value: 'Wala', label: 'Wala' },
  { value: 'Weaver', label: 'Weaver' },
  { value: 'Yadav', label: 'Yadav' },
  { value: 'Yadava', label: 'Yadava' },
]

const hinduGothraOptions = [
  { value: 'anantha', label: 'Anantha' },
  { value: 'angirasa', label: 'Angirasa' },
  { value: 'atri', label: 'Atri' },
  { value: 'bali', label: 'Bali' },
  { value: 'bharadwaj', label: 'Bharadwaj' },
  { value: 'bhargava', label: 'Bhargava' },
  { value: 'bhrigu', label: 'Bhrigu' },
  { value: 'chandilya', label: 'Chandilya' },
  { value: 'dhananjaya', label: 'Dhananjaya' },
  { value: 'garg', label: 'Garg' },
  { value: 'gautam', label: 'Gautam' },
  { value: 'goutamasa', label: 'Goutamasa' },
  { value: 'haritasya', label: 'Haritasya' },
  { value: 'jamadagni', label: 'Jamadagni' },
  { value: 'jamadagnya', label: 'Jamadagnya' },
  { value: 'kashyap', label: 'Kashyap' },
  { value: 'kaushal', label: 'Kaushal' },
  { value: 'kaushika', label: 'Kaushika' },
  { value: 'koushika_viswamitra', label: 'Koushika Viswamitra' },
  { value: 'krishnatrey', label: 'Krishnatrey' },
  { value: 'kutsa', label: 'Kutsa' },
  { value: 'moudgalya', label: 'Moudgalya' },
  { value: 'moudgilya', label: 'Moudgilya' },
  { value: 'naidhruva-kashyapa', label: 'Naidhruva-Kashyapa' },
  { value: 'parashar', label: 'Parashar' },
  { value: 'raghuvanshi', label: 'Raghuvanshi' },
  { value: 'sankash', label: 'Sankash' },
  { value: 'sankrithi', label: 'Sankrithi' },
  { value: 'shandilya', label: 'Shandilya' },
  { value: 'shiva', label: 'Shiva' },
  { value: 'shravan', label: 'Shravan' },
  { value: 'srivatsa', label: 'Srivatsa' },
  { value: 'suryadhwaja', label: 'Suryadhwaja' },
  { value: 'vashishtha', label: 'Vashishtha' },
  { value: 'vasudeva', label: 'Vasudeva' },
  { value: 'vishnu', label: 'Vishnu' },
  { value: 'vishwamitra', label: 'Vishwamitra' },
]

// Christian select options

const churchAttendanceOptions = [
  { value: 'regular', label: 'Regularly attends church' },
  { value: 'occasional', label: 'Occasionally attends church' },
  { value: 'rare', label: 'Rarely attends church' },
  { value: 'none', label: 'Does not attend' },
]
const bibleStudyOptions = [
  { value: 'regular', label: 'Regular participant' },
  { value: 'occasional', label: 'Occasionally participates' },
  { value: 'rare', label: 'Rarely participates' },
  { value: 'none', label: 'Does not participate' },
]
const missionaryWorkOptions = [
  { value: 'actively', label: 'Actively involved' },
  { value: 'occasionally', label: 'Occasionally involved' },
  { value: 'interested', label: 'Not involved, but interested' },
  { value: 'not-interested', label: 'Not interested' },
]
const familyPlanningOptions = [
  { value: 'many', label: 'Plans for many children' },
  { value: 'few', label: 'Plans for a few children' },
  { value: 'undecided', label: 'Undecided about children' },
  { value: 'no-children', label: 'Prefers not to have children' },
]
const faithImportanceOptions = [
  { value: 'central', label: 'Central to my life' },
  { value: 'important', label: 'Important but not central' },
  { value: 'somewhat', label: 'Somewhat important' },
  { value: 'not-important', label: 'Not important' },
]
// Jewish select options

const jewishDenominationOptions = [
  { value: 'orthodox', label: 'Orthodox' },
  { value: 'conservative', label: 'Conservative' },
  { value: 'reform', label: 'Reform' },
  { value: 'reconstructionist', label: 'Reconstructionist' },
  { value: 'secular', label: 'Secular' },
  { value: 'other', label: 'Other' },
]
const kosherObservanceOptions = [
  { value: 'strict', label: 'Strictly keeps kosher' },
  { value: 'home-only', label: 'Keeps kosher at home only' },
  { value: 'not-keep', label: 'Does not keep kosher' },
]
const sabbathObservanceOptions = [
  { value: 'strict', label: 'Strictly observes Shabbat and all festivals' },
  { value: 'major-only', label: 'Observes Shabbat and major festivals only' },
  { value: 'not-strict', label: 'Does not observe Shabbat strictly' },
]
const hebrewFluencyOptions = [
  { value: 'fluent', label: 'Fluent' },
  { value: 'basic', label: 'Basic' },
  { value: 'none', label: 'None' },
]
const synagogueAttendanceOptions = [
  { value: 'regular', label: 'Regular attendee' },
  { value: 'high-holidays', label: 'Attends on high holidays only' },
  { value: 'seldom', label: 'Seldom attends' },
  { value: 'not-attend', label: 'Does not attend' },
]

const TimeValues = {
  hours: Array.from({ length: 24 }, (_, i) => ({
    value: i + 1 < 10 ? `0${i + 1}` : `${i + 1}`,
    label: i + 1 < 10 ? `0${i + 1}` : `${i + 1}`,
  })),
  minutes: Array.from({ length: 60 }, (_, i) => ({
    value: i + 1 < 10 ? `0${i + 1}` : `${i + 1}`,
    label: i + 1 < 10 ? `0${i + 1}` : `${i + 1}`,
  })),
}

const weightOptions = Array.from({ length: 121 }, (v, i) => ({
  value: 30 + i,
  label: (30 + i).toString(),
}))

const profileManagedByOptions = [
  { value: 'family', label: 'Family' },
  { value: 'self', label: 'Self' },
  { value: 'sibling', label: 'Sibling' },
  { value: 'friend', label: 'Friend' },
]

const languageSpeakOptions = [
  { value: 'hindi', label: 'Hindi' },
  { value: 'bengali', label: 'Bengali' },
  { value: 'marathi', label: 'Marathi' },
  { value: 'telugu', label: 'Telugu' },
  { value: 'tamil', label: 'Tamil' },
  { value: 'gujarati', label: 'Gujarati' },
  { value: 'urdu', label: 'Urdu' },
  { value: 'kannada', label: 'Kannada' },
  { value: 'malayalam', label: 'Malayalam' },
  { value: 'punjabi', label: 'Punjabi' },
  { value: 'sindhi', label: 'Sindhi' },
  { value: 'marwari', label: 'Marwari' },
  { value: 'english', label: 'English' },
  { value: 'mandarin_chinese', label: 'Mandarin Chinese' },
  { value: 'spanish', label: 'Spanish' },
  { value: 'french', label: 'French' },
  { value: 'arabic', label: 'Arabic' },
  { value: 'portuguese', label: 'Portuguese' },
  { value: 'russian', label: 'Russian' },
  { value: 'japanese', label: 'Japanese' },
]

const prefAgeOptions = Array.from({ length: 83 }, (v, i) => ({
  value: i + 18,
  label: i + 18,
}))

const heightRangeOptions = generateHeight()

const prefIncomeFromOptions = [
  {
    label: 'Under 1,00,000',
    value: '0',
  },
  {
    label: '1,00,000',
    value: '100000',
  },
  {
    label: '2,00,000',
    value: '200000',
  },
  {
    label: '3,00,000',
    value: '300000',
  },
  {
    label: '4,00,000',
    value: '400000',
  },
  {
    label: '5,00,000',
    value: '500000',
  },
  {
    label: '6,00,000',
    value: '600000',
  },
  {
    label: '8,00,000',
    value: '800000',
  },
  {
    label: '10,00,000',
    value: '1000000',
  },
  {
    label: '12,00,000',
    value: '1200000',
  },
  {
    label: '15,00,000',
    value: '1500000',
  },
  {
    label: '20,00,000',
    value: '2000000',
  },
  {
    label: '25,00,000',
    value: '2500000',
  },
  {
    label: '30,00,000',
    value: '3000000',
  },
  {
    label: '40,00,000',
    value: '4000000',
  },
  {
    label: '50,00,000',
    value: '5000000',
  },
  {
    label: '75,00,000',
    value: '7500000',
  },
  {
    label: '1,00,00,000',
    value: '10000000',
  },
  {
    label: '1,50,00,000',
    value: '15000000',
  },
  {
    label: 'Above 2,00,00,000',
    value: '200000001',
  },
  // {
  //   label: 'Prefer not to say',
  //   value: 'Prefer not to say',
  // },
]

const prefIncomeToOptions = [
  {
    label: 'Under 1,00,000',
    value: '0',
  },
  {
    label: '1,00,000',
    value: '100000',
  },
  {
    label: '2,00,000',
    value: '200000',
  },
  {
    label: '3,00,000',
    value: '300000',
  },
  {
    label: '4,00,000',
    value: '400000',
  },
  {
    label: '5,00,000',
    value: '500000',
  },
  {
    label: '6,00,000',
    value: '600000',
  },
  {
    label: '8,00,000',
    value: '800000',
  },
  {
    label: '10,00,000',
    value: '1000000',
  },
  {
    label: '12,00,000',
    value: '1200000',
  },
  {
    label: '15,00,000',
    value: '1500000',
  },
  {
    label: '20,00,000',
    value: '2000000',
  },
  {
    label: '25,00,000',
    value: '2500000',
  },
  {
    label: '30,00,000',
    value: '3000000',
  },
  {
    label: '40,00,000',
    value: '4000000',
  },
  {
    label: '50,00,000',
    value: '5000000',
  },
  {
    label: '75,00,000',
    value: '7500000',
  },
  {
    label: '1,00,00,000',
    value: '10000000',
  },
  {
    label: '1,50,00,000',
    value: '15000000',
  },
  {
    label: 'Above 2,00,00,000',
    value: '200000001',
  },
  {
    label: 'Prefer not to say',
    value: 'Prefer not to say',
  },
]

const generateHeightOptions = []

for (let feet = 4; feet <= 9; feet++) {
  for (let inches = 0; inches < 12; inches++) {
    const formattedHeight = `${feet}'${inches}`
    generateHeightOptions.push({
      value: `${feet}.${inches}`,
      label: formattedHeight,
    })
  }
}

function optionsForCommission() {
  const options = []
  for (let i = 1; i <= 50; i++) {
    options.push({ label: `${i}%`, value: i.toString() })
  }
  return options
}
function workExperienceOptions() {
  const options = []
  for (let i = 1; i <= 50; i++) {
    options.push({ label: `${i}`, value: i.toString() })
  }
  return options
}

const additionalQuestions = [
  {
    label: 'How do you like to spend your weekends?',
    value: 'how-do-you-like-to-spend-your-weekends',
  },
  {
    label: 'Are you a morning person or a night owl?',
    value: 'are-you-a-morning-person-or-a-night-owl',
  },
  { label: 'What are most of your vacations like?', value: 'what-are-most-of-your-vacations-like' },
  { label: 'Do you want children?', value: 'do-you-want-children' },
  {
    label: 'Are you open to long-distance relationships?',
    value: 'are-you-open-to-long-distance-relationships',
  },
  {
    label: 'What’s your stance on work-life balance?',
    value: 'whats-your-stance-on-work-life-balance',
  },
  { label: 'How do you feel about pets?', value: 'how-do-you-feel-about-pets' },
  { label: 'Describe your sense of humor.', value: 'describe-your-sense-of-humor' },
  {
    label: 'Do you enjoy group activities or prefer one-on-one time?',
    value: 'do-you-enjoy-group-activities-or-prefer-one-on-one-time',
  },
  { label: 'How often do you exercise?', value: 'how-often-do-you-exercise' },
  {
    label: 'What’s your favorite type of workout or physical activity?',
    value: 'whats-your-favorite-type-of-workout-or-physical-activity',
  },
  {
    label: 'Are you open to dating someone from a different culture?',
    value: 'are-you-open-to-dating-someone-from-a-different-culture',
  },
  {
    label: 'How do you feel about living with pets?',
    value: 'how-do-you-feel-about-living-with-pets',
  },
]
const preferencesQuestions = [
  {
    label:
      'How important is physical appearance to you in a partner? (Very important, Somewhat important, Not important)',
    value: 'how-important-is-physical-appearance-to-you-in-a-partner',
  },
  {
    label:
      'What type of personality do you prefer in a partner? (Introverted, Extroverted, Ambiverted)',
    value: 'what-type-of-personality-do-you-prefer-in-a-partner',
  },
  {
    label: 'Do you prefer a partner who is more spontaneous or more structured?',
    value: 'do-you-prefer-a-partner-who-is-more-spontaneous-or-more-structured',
  },
  {
    label: "Do you have any preferences regarding your partner's height, build, or fitness level?",
    value: 'do-you-have-any-preferences-regarding-your-partners-height-build-or-fitness-level',
  },
  {
    label:
      'How important is it for your partner to share your religious or spiritual beliefs? (Very, Somewhat, Not important)',
    value: 'how-important-is-it-for-your-partner-to-share-your-religious-or-spiritual-beliefs',
  },
  {
    label: 'Do you value traditional gender roles in relationships?',
    value: 'do-you-value-traditional-gender-roles-in-relationships',
  },
  {
    label: 'How do you prefer your partner to communicate? (Direct, Subtle, Balanced)',
    value: 'how-do-you-prefer-your-partner-to-communicate',
  },
  {
    label:
      'Would you prefer your partner to be more outgoing or more reserved in social situations?',
    value:
      'would-you-prefer-your-partner-to-be-more-outgoing-or-more-reserved-in-social-situations',
  },
  {
    label: 'Are you open to meeting someone from a different cultural background? (Yes/No/Depends)',
    value: 'are-you-open-to-meeting-someone-from-a-different-cultural-background',
  },
  {
    label:
      'How important is it for your partner to be career-oriented? (Very, Somewhat, Not important)',
    value: 'how-important-is-it-for-your-partner-to-be-career-oriented',
  },
  {
    label: 'Would you prefer your partner to have a similar level of education?',
    value: 'would-you-prefer-your-partner-to-have-a-similar-level-of-education',
  },
  {
    label: 'Are you open to long-distance relationships?',
    value: 'are-you-open-to-long-distance-relationships',
  },
  {
    label: 'How soon do you expect to live together if the relationship progresses?',
    value: 'how-soon-do-you-expect-to-live-together-if-the-relationship-progresses',
  },
  {
    label: 'Do you want to live in the city, suburbs, or countryside with your partner?',
    value: 'do-you-want-to-live-in-the-city-suburbs-or-countryside-with-your-partner',
  },
  {
    label: 'Are you okay with somebody with kids from a previous marriage?',
    value: 'are-you-okay-with-somebody-with-kids-from-a-previous-marriage',
  },
]
const educationFields = [
  { label: 'Accounting', value: 'accounting' },
  { label: 'Aerospace Engineering', value: 'aerospace_engineering' },
  { label: 'Agriculture', value: 'agriculture' },
  { label: 'Anthropology', value: 'anthropology' },
  { label: 'Architecture', value: 'architecture' },
  { label: 'Art', value: 'art' },
  { label: 'Artificial Intelligence', value: 'artificial_intelligence' },
  { label: 'Biomedical Engineering', value: 'biomedical_engineering' },
  { label: 'Biology', value: 'biology' },
  { label: 'Business Administration', value: 'business_administration' },
  { label: 'Chemical Engineering', value: 'chemical_engineering' },
  { label: 'Chemistry', value: 'chemistry' },
  { label: 'Civil Engineering', value: 'civil_engineering' },
  { label: 'Computer Science', value: 'computer_science' },
  { label: 'Culinary Arts', value: 'culinary_arts' },
  { label: 'Cybersecurity', value: 'cybersecurity' },
  { label: 'Data Science', value: 'data_science' },
  { label: 'Economics', value: 'economics' },
  { label: 'Education', value: 'education' },
  { label: 'Electrical Engineering', value: 'electrical_engineering' },
  { label: 'Environmental Science', value: 'environmental_science' },
  { label: 'Film Studies', value: 'film_studies' },
  { label: 'Fine Arts', value: 'fine_arts' },
  { label: 'Finance', value: 'finance' },
  { label: 'Graphic Design', value: 'graphic_design' },
  { label: 'History', value: 'history' },
  { label: 'Hospitality Management', value: 'hospitality_management' },
  { label: 'Human Resources', value: 'human_resources' },
  { label: 'Information Technology', value: 'information_technology' },
  { label: 'International Relations', value: 'international_relations' },
  { label: 'Journalism', value: 'journalism' },
  { label: 'Law', value: 'law' },
  { label: 'Linguistics', value: 'linguistics' },
  { label: 'Marketing', value: 'marketing' },
  { label: 'Mass Communication', value: 'mass_communication' },
  { label: 'Mathematics', value: 'mathematics' },
  { label: 'Mechanical Engineering', value: 'mechanical_engineering' },
  { label: 'Medicine', value: 'medicine' },
  { label: 'Nursing', value: 'nursing' },
  { label: 'Performing Arts', value: 'performing_arts' },
  { label: 'Pharmacy', value: 'pharmacy' },
  { label: 'Philosophy', value: 'philosophy' },
  { label: 'Physics', value: 'physics' },
  { label: 'Political Science', value: 'political_science' },
  { label: 'Psychology', value: 'psychology' },
  { label: 'Public Health', value: 'public_health' },
  { label: 'Social Work', value: 'social_work' },
  { label: 'Sociology', value: 'sociology' },
  { label: 'Tourism Management', value: 'tourism_management' },
  { label: 'Veterinary Science', value: 'veterinary_science' },
]
const residencyOptions = [
  { label: 'Citizen', value: 'citizen' },
  { label: 'Dependent Resident', value: 'dependent_resident' },
  { label: 'Diplomatic Resident', value: 'diplomatic_resident' },
  { label: 'Permanent Resident', value: 'permanent_resident' },
  { label: 'Retired Resident', value: 'retired_resident' },
  { label: 'Student Resident', value: 'student_resident' },
  { label: 'Temporary Resident', value: 'temporary_resident' },
  { label: 'Visitor/Tourist', value: 'visitor_tourist' },
  { label: 'Work Permit Holder', value: 'work_permit_holder' },
]

export {
  residencyOptions,
  educationFields,
  workExperienceOptions,
  preferencesQuestions,
  additionalQuestions,
  optionsForCommission,
  generateHeightOptions,
  prefIncomeToOptions,
  prefIncomeFromOptions,
  heightRangeOptions,
  prefAgeOptions,
  languageSpeakOptions,
  profileManagedByOptions,
  weightOptions,
  TimeValues,
  partnerProfessionalStatusPrefrence,
  socialBehaviour,
  travelExperience,
  cookingKnowledge,
  workoutHabits,
  motherOccupation,
  fatherOccupation,
  ancestralOrigin,
  stateOptions,
  cityOfResidenceOptions,
  additionalInvestments,
  genderOptions,
  maritalStatusOptions,
  lookingToOptions,
  physicalAbilitiesOptions,
  heightOptions,
  religionOptions,
  minutesOptions,
  hoursOptions,
  sunSignOptions,
  motherTongueOptions,
  manglikOptions,
  educationOptions,
  employmentOptions,
  // ageRange,
  occupationOptions,
  travelRequirementsOptions,
  currencysymbolsOptions,
  AnnualIncomeOptions,
  FamilyValuesOptions,
  FamilyStatusOptions,
  FamilyTypeOptions,
  FamilyIndianIncomeOptions,
  FamilyNonIndianIncomeOptions,
  FamilyWeddingBudgetOptions,
  presenceChildrenOptions,
  partnerPreferences,
  ultraRichPreferences,
  additionalInformation,
  muslimSectOptions,
  halalDietOptions,
  fastingDuringRamadanOptions,
  prayerHabitsOptions,
  religiousPracticeOptions,
  incomeOptions,
  dietHabits,
  smokinAndDrinkingOption,
  eatingHabits,
  drinkingHabits,
  smokingHabits,
  hobbies,
  castOptions,
  subCastOptions,
  gothraOptions,
  countryofResidenceOptions,
  muslimCasteOptions,
  hinduCastesoptions,
  hinduGothraOptions,
  churchAttendanceOptions,
  bibleStudyOptions,
  missionaryWorkOptions,
  familyPlanningOptions,
  faithImportanceOptions,
  jewishDenominationOptions,
  kosherObservanceOptions,
  sabbathObservanceOptions,
  hebrewFluencyOptions,
  synagogueAttendanceOptions,
  hideFilterOptions,
  ProfilehideFilterOptions,
}
