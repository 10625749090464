import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'

const SideBarWrapper = styled('div')(({ theme }) => ({
  background: theme.palette.background.lightBlue,
  minWidth: '80px',
  width: '80px',
  flexShrink: 0,
}))
const SideBarMain = styled('aside')(({ theme }) => ({
  background: theme.palette.background.lightBlue,
  minHeight: '100%',
  // height: '100%',
  minWidth: '80px',
  width: '80px',
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  overflowY: 'hidden',
  paddingBottom: '10px',
}))
const ListMain = styled('li')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '20px',
  position: 'relative',
  '& a': {
    color: '#9E9E9E',
    fontSize: '10px',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '7px',
  },
  '&.active_item:after': {
    content: "''",
    position: 'absolute',
    top: '0',
    height: '100%',
    width: '3px',
    right: '-12px',
    background: '#62B7DA',
    borderRadius: '5px',
  },
}))
const LogoMain = styled(Link)(({ theme }) => ({
  minHeight: '64px',
}))

export { SideBarMain, SideBarWrapper, ListMain, LogoMain }
