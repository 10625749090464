import React, { memo, useMemo } from 'react'
import Select from 'react-select'
import { styled } from '@mui/material/styles'

const MainSelect = styled('div')(({ theme, wd }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.3rem',
  width: wd ? wd : '40%',
  position: 'relative',
  '&.has__error': {
    '& .onboard_select__control': {
      border: '1px solid #ff4d4f',
    },
  },
  [theme.breakpoints.down('md')]: {
    width: '90%',
  },
  '& label': {
    color: 'rgba(73, 117, 156, 1)',
    fontSize: '14px',
    fontWeight: 500,
    span: {
      color: '#DF0000',
    },
  },
  '& .error': {
    color: '#ff4d4f',
    fontWeight: 400,
    fontSize: '12px',
    position: 'absolute',
    top: '100%',
    left: 0,
  },
}))

const StyledSelect = styled(Select)(({ theme, mw }) => ({
  maxWidth: mw ? mw : '238px',
  '& .css-1c7kgnv-control': {
    height: '34px !important',
  },
}))

const customStyles = {
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  control: (provided, state) => ({
    ...provided,
    border: '1px solid #F1F1F1',
    color: '#9E9E9E',
    backgroundColor: '#F1F1F1',
    borderRadius: '8px',
    boxShadow: 'none',
    height: '34px',
    paddingTop: '0px',
    fontSize: '14px',
    fontWeight: 400,
    padding: '0 10px',
    '&:hover': {
      border: '1px solid #F1F1F1',
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: '#F1F1F1',
    boxShadow: 'none',
    borderRadius: '5px',
    marginTop: '2px',
    color: '#9E9E9E',
    overflowY: 'hidden',
    zIndex: 999,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '150px',
    overflowY: 'auto',
    zIndex: 9999,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#F1F1F1' : '#F1F1F1',
    color: state.isSelected ? '#9E9E9E' : '#000',
    fontSize: '14px',
    fontWeight: 400,
    '&:hover': {
      backgroundColor: '#F1F1F1',
      color: '#9E9E9E',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'gray',
    fontSize: '14px',
    fontWeight: 400,
  }),
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: 'nowrap',
    overflowX: 'auto',
    scrollbarWidth: 'none',
  }),
  multiValue: (provided) => ({
    ...provided,
    minWidth: '70px',
    display: 'flex',
    justifyContent: 'space-between',
  }),
}

const OnboadingSelect = ({
  field,
  form,
  options,
  label,
  required = false,
  placeholder = 'Select',
  setValue,
  error,
  value,
  clearErrors,
  setgetSelectedValue = () => {},
  isMulti = false,
  openUpwards = false,
  ...props
}) => {
  const isOnboardingPage = window.location.pathname === '/onboarding'

  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      setValue(field.name, null)
      setgetSelectedValue(null)
    } else if (Array.isArray(selectedOption)) {
      setValue(
        field.name,
        selectedOption.map((item) => item.value),
      )
      setgetSelectedValue(selectedOption.map((item) => item.value))
    } else {
      setValue(field.name, selectedOption.value)
      setgetSelectedValue(selectedOption.value)
    }
    clearErrors(field.name)
  }

  const selectedValue = useMemo(() => {
    return isMulti
      ? options?.filter((option) => field.value?.includes(option.value))
      : options?.find((option) => option.value === field.value) || null
  }, [isMulti, options, field.value])

  const isClearable = !isMulti && isOnboardingPage && !required

  return (
    <MainSelect className={`main_select ${error && 'has__error'}`}>
      {label && (
        <label>
          {label}
          {required && <span>*</span>}
        </label>
      )}
      <StyledSelect
        value={selectedValue}
        placeholder={placeholder || 'Select'}
        options={options}
        onChange={handleSelectChange}
        noOptionsMessage={() => <span style={{ color: '#9E9E9E' }}>No data found</span>}
        styles={customStyles}
        isMulti={isMulti}
        className="onboard_select_container"
        classNamePrefix="onboard_select"
        closeMenuOnSelect={isMulti ? false : true}
        {...(openUpwards ? { menuPlacement: 'top' } : {})}
        {...(isClearable ? { isClearable: true } : {})}
        {...props}
      />
      {error && <span className="error">{error.message}</span>}
    </MainSelect>
  )
}

export default memo(OnboadingSelect)
