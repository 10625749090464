import React from 'react'
import List from '@mui/material/List'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Matches from 'app/assets/images/sideBar/matches.svg'
import Contacts from 'app/assets/images/sideBar/contacts.svg'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { SideBarWrapper, SideBarMain, ListMain, LogoMain } from './style'
import logo from 'app/assets/images/logo/logoMini.svg'
import HomeIcon from 'app/assets/images/sideBar/home.svg'
import Agenscies from 'app/assets/images/sideBar/agencies.svg'
import Tasks from 'app/assets/images/sideBar/tasks.svg'
// import FollowUps from 'app/assets/images/sideBar/followups.svg'
import { Link, useLocation, useParams } from 'react-router-dom'

function CalendarIcon() {
  const currentDate = new Date()
  const monthName = currentDate.toLocaleString('default', { month: 'short' })
  const dayOfMonth = currentDate.getDate()

  return (
    <ListItemIcon
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '0',
      }}
    >
      <ListItemText
        sx={{
          backgroundColor: '#FB6F92',
          borderRadius: '10px 10px 0 0',

          textAlign: 'center',
          color: '#FFFFFF',
          fontSize: '14px',
          fontStyle: 'italic',
          marginBottom: '0',
        }}
        primary={`${monthName.toUpperCase()}`}
      />
      <ListItemText
        sx={{
          backgroundColor: '#FFFFFF',
          borderRadius: '0 0 10px 10px',

          textAlign: 'center',
          color: '#FB6F92',
          fontSize: '14px',
          fontStyle: 'italic',
          marginTop: '0',
        }}
        primary={`${dayOfMonth}`}
      />
    </ListItemIcon>
  )
}

export default function SideBar() {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const location = useLocation()
  const { id } = useParams()

  const listData = [
    { name: 'Home', icon: HomeIcon, link: '/' },
    // { name: 'Contacts', icon: Contacts, link: '/contact' },
    { name: 'Profiles', icon: Contacts, link: '/profiles', subLinks: [`/profile/${id}`] },
    { name: 'Matches', icon: Matches, link: '/matches', subLinks: [`/profile/${id}`] },
    { name: 'Agencies', icon: Agenscies, link: '/agencies', subLinks: [`/matchmaker/${id}`] },
    { name: 'Leads', icon: Tasks, link: '/leads' },
    // { name: 'Follow Ups', icon: FollowUps, link: '/follow-up' },
  ]

  if (isSmallScreen) {
    return null // Return null to hide the sidebar on small screens
  }

  return (
    <SideBarWrapper>
      <SideBarMain>
        <LogoMain to="/">
          <img src={logo} alt="logo" />
        </LogoMain>
        <List>
          {listData.map((item, index) => {
            const isActive =
              location.pathname === item.link
              // ||  (item.subLinks && item.subLinks.includes(location.pathname))
            return (
              <ListMain key={index} className={isActive ? 'active_item' : ''}>
                <Link to={item.link} sx={{ textDecoration: 'none' }}>
                  <img
                    style={{
                      filter: isActive
                        ? 'brightness(0) saturate(100%) invert(82%) sepia(3%) saturate(6633%) hue-rotate(169deg) brightness(101%) contrast(84%)'
                        : '#9E9E9E',
                    }}
                    src={item.icon}
                    alt={item.name}
                  />
                  <span
                    style={{
                      color: isActive ? '#87CEEB' : '#9E9E9E',
                    }}
                  >
                    {item.name}
                  </span>
                </Link>
              </ListMain>
            )
          })}
        </List>
        <CalendarIcon />
      </SideBarMain>
    </SideBarWrapper>
  )
}
