import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userInfo: {},
  phoneNumber: '',
  sessionId: '',
}

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.userInfo = action.payload
    },

    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload
    },
    setSessionId: (state, action) => {
      state.sessionId = action.payload
    },

    clearStore: (state) => {
      state.userInfo = {}
      state.isAuthenticated = false
      localStorage.clear()
    },
    resetAuthInfo: (state) => {
      state.phoneNumber = ''
      state.sessionId = ''
    },
  },
})

export const { setUser, clearStore, setPhoneNumber, resetAuthInfo, setSessionId } =
  loginSlice.actions

export default loginSlice.reducer
