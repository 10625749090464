import React, { memo } from 'react'
import { ButtonSection, CustomButton } from 'app/components/match/table/Style'
import Add from 'app/assets/images/popUp/add.svg'
import { Controller, useForm } from 'react-hook-form'
import InputText from 'app/components/shared/inputs/onBoarding/InputText'
import OnboadingSelect from 'app/components/shared/inputs/onBoarding/InputSelect'
import { InputItem, InputMain, InputsContainer } from 'app/components/leads/styles/leadsForm'
import DataPicker from 'app/components/shared/inputs/DataPicker'
import { CustomPaper } from '../dashboard/homeNew/tasks/styles/Tasks'
import { EventContainer } from './styles/createEventStyle'
import useCreateEvent from 'app/hooks/profileSchedule/useCreateEvent'
import { useParams } from 'react-router-dom'

const initialValues = {
  type: '',
  loreum: '',
  subject: '',
  description: '',
}

const CreateEvent = ({ open = false, onClose = () => {}, refetch = () => {} }) => {
  const { id } = useParams()
  const { mutate: addEvent, isLoading: addLoading } = useCreateEvent()
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: initialValues,
  })

  const onSubmit = async (values) => {
    const { date } = values
    const body = {
      date: new Date(date).toISOString(),
      profileId: id,
      ...values,
    }
    addEvent(body, {
      onSuccess: (data) => {
        reset()
        onClose()
        refetch()
      },
    })
  }

  return (
    <CustomPaper open={open}>
      <EventContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="title__bar">
            <h2>Create Event</h2>
            <img src={Add} onClick={onClose} alt="close" />
          </div>
          <div style={{ margin: '20px 0' }}>
            <InputsContainer>
              <InputItem>
                <InputMain>
                  <Controller
                    name="type"
                    control={control}
                    rules={{ required: 'Type is required' }}
                    render={({ field, formState }) => (
                      <OnboadingSelect
                        label="Type"
                        value={field.value}
                        field={field}
                        form={{ errors, setValue: control.setValue }}
                        options={[
                          { label: 'test', value: 'test' },
                          { label: 'test', value: 'test' },
                        ]}
                        // placeholder="Soumya Shrivastva"
                        setValue={setValue}
                        clearErrors={clearErrors}
                        error={errors.type}
                      />
                    )}
                  />
                </InputMain>
              </InputItem>
            </InputsContainer>
            <InputsContainer>
              <InputItem>
                <InputMain>
                  <Controller
                    name="loreum"
                    control={control}
                    // rules={{ required: 'Assign To is required' }}
                    render={({ field, formState }) => (
                      <OnboadingSelect
                        label="Loreum"
                        value={field.value}
                        field={field}
                        form={{ errors, setValue: control.setValue }}
                        options={[
                          { label: 'test', value: 'test' },
                          { label: 'test', value: 'test' },
                        ]}
                        // placeholder="Soumya Shrivastva"
                        setValue={setValue}
                        clearErrors={clearErrors}
                        error={errors.Loreum}
                      />
                    )}
                  />
                </InputMain>
              </InputItem>
            </InputsContainer>
            <InputsContainer>
              <InputItem>
                <InputMain>
                  <Controller
                    name="date"
                    control={control}
                    rules={{ required: 'Date is required' }}
                    render={({ field, formState }) => (
                      <DataPicker
                        label="Due Date"
                        field={field}
                        className={'datePicker__fix'}
                        error={errors.date}
                        disablePastData={true}
                      />
                    )}
                  />
                </InputMain>
              </InputItem>
            </InputsContainer>

            <InputsContainer>
              <InputItem>
                <InputMain>
                  <Controller
                    name="subject"
                    rules={{ required: 'Title is required' }}
                    control={control}
                    render={({ field, formState }) => (
                      <InputText
                        label="Subject"
                        field={field}
                        form={formState}
                        placeholder="Subject"
                        error={errors.title}
                      />
                    )}
                  />
                </InputMain>
              </InputItem>
            </InputsContainer>
            <InputsContainer>
              <InputItem>
                <InputMain>
                  <Controller
                    name="description"
                    control={control}
                    // rules={{ required: 'Details is required' }}
                    render={({ field, formState }) => (
                      <InputText
                        label="Description"
                        field={field}
                        form={formState}
                        placeholder="Type here..."
                        type="textarea"
                        error={errors.detail}
                      />
                    )}
                  />
                </InputMain>
              </InputItem>
            </InputsContainer>
            {/* Row 2 */}
          </div>
          <ButtonSection>
            <CustomButton type="submit" blue={true} disabled={addLoading}>
              {'CREATE'}
            </CustomButton>
          </ButtonSection>
        </form>
      </EventContainer>
    </CustomPaper>
  )
}

export default memo(CreateEvent)
