const ChatPopupDummyData = {
  personalDetails: {
    name: '[Your Full Name]',
    gender: 'Male',
    height: '[Your Height]',
    dateOfBirth: '[DD-MonthName - YYYY]',
    timeOfBirth: '[12 hrs format AM/PM]',
    placeOfBirth: '',
    religionCaste: '[Your Religion/Caste]',
    languagesKnown: '[Languages You Speak]',
    location: '',
  },
  educationAndCareer: {
    highestQualification: '[Your Degree]',
    institution: '[Name of the Institution]',
    occupation: '[Your Job Title]',
    companyName: '[Your Employer]',
    annualIncome: '[Your Income]',
  },
  lifestyle: {
    diet: '[Vegetarian/Non-Vegetarian/Vegan]',
    smoking: '[Yes/No]',
    drinking: '[Yes/No]',
    hobbies: '[Your Hobbies/Interests]',
    interests: '[Your Interests]',
  },
  familyDetails: {
    familyIncome: '',
    familyBudget: '',
    fathersName: "[Father's Name]",
    fathersOccupation: "[Father's Job]",
    mothersName: "[Mother's Name]",
    mothersOccupation: "[Mother's Job]",
    siblings: '[Number of Brothers/Sisters and their Occupations]',
    familyType: '[Nuclear/Joint]',
    familyValues: '[Traditional/Moderate/Liberal]',
    residence: '',
  },
  additionalInformation: {
    aboutMe:
      "[A brief paragraph about yourself, your personality, and what you're looking for in a partner]",
    photo: '[Attach a recent photograph]',
  },
}

export default ChatPopupDummyData
